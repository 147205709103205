import request from "../../utils/request.js";

// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中

const state = {
  getCurrentGeneration: {},
  getPowerStationInfo: {},
  getPowerStationBasicInfo: {},
  getPowerStationElectricInfo: {},
  getAlternateData: {},
  getInverterStatus: {},
  getStraitAlternate: {},
  getStraitData: {},
  getInverterCurr: {},
  getGenOverview: {},
  getGenList: {},
  getPowerTable: {},
  getPowerStatus: {},
  getGeneratorSetting: {},
  putGenerator: {},
  getDayMonthYear: {},
  getRealpowerWeather: {},
  getInvertersByCustomer: {},
  getInverterInfoAndAmount: {},
  getInverterBasicInfo: {},
  getInverterRealtime: {},
  getInverterTrendData: {},
  getCurrFlow: {},
  getBatteryTable: {},
  getInverterTable: {},
  getBatteryByCustomer: {},
};
const mutations = {
  GetCurrentGeneration(state, getCurrentGeneration) {
    state.getCurrentGeneration = getCurrentGeneration;
  },
  GetPowerStationInfo(state, getPowerStationInfo) {
    state.getPowerStationInfo = getPowerStationInfo;
  },
  GetPowerStationBasicInfo(state, getPowerStationBasicInfo) {
    state.getPowerStationBasicInfo = getPowerStationBasicInfo;
  },
  GetPowerStationElectricInfo(state, getPowerStationElectricInfo) {
    state.getPowerStationElectricInfo = getPowerStationElectricInfo;
  },
  GetAlternateData(state, getAlternateData) {
    state.getAlternateData = getAlternateData;
  },
  GetInverterStatus(state, getInverterStatus) {
    state.getInverterStatus = getInverterStatus;
  },
  GetStraitAlternate(state, getStraitAlternate) {
    state.getStraitAlternate = getStraitAlternate;
  },
  GetStraitData(state, getStraitData) {
    state.getStraitData = getStraitData;
  },
  GetInverterCurr(state, getInverterCurr) {
    state.getInverterCurr = getInverterCurr;
  },
  GetGenOverview(state, getGenOverview) {
    state.getGenOverview = getGenOverview;
  },
  GetGenList(state, getGenList) {
    state.getGenList = getGenList;
  },
  GetPowerTable(state, getPowerTable) {
    state.getPowerTable = getPowerTable;
  },
  GetPowerStatus(state, getPowerStatus) {
    state.getPowerStatus = getPowerStatus;
  },
  GETGENERATORSETTING(state, getGeneratorSetting) {
    state.getGeneratorSetting = getGeneratorSetting;
  },
  PUTGENERATOR(state, putGenerator) {
    state.putGenerator = putGenerator;
  },
  GetDayMonthYear(state, getDayMonthYear) {
    state.getDayMonthYear = getDayMonthYear;
  },
  GetRealpowerWeather(state, getRealpowerWeather) {
    state.getRealpowerWeather = getRealpowerWeather;
  },
  GetInvertersByCustomer(state, getInvertersByCustomer) {
    state.getInvertersByCustomer = getInvertersByCustomer;
  },
  GetInverterInfoAndAmount(state, getInverterInfoAndAmount) {
    state.getInverterInfoAndAmount = getInverterInfoAndAmount;
  },
  GetInverterBasicInfo(state, getInverterBasicInfo) {
    state.getInverterBasicInfo = getInverterBasicInfo;
  },
  GetInverterRealtime(state, getInverterRealtime) {
    state.getInverterRealtime = getInverterRealtime;
  },
  GetInverterTrendData(state, getInverterTrendData) {
    state.getInverterTrendData = getInverterTrendData;
  },
  GetCurrFlow(state, getCurrFlow) {
    state.getCurrFlow = getCurrFlow;
  },
  GetBatteryTable(state, getBatteryTable) {
    state.getBatteryTable = getBatteryTable;
  },
  GetInverterTable(state, getInverterTable) {
    state.getInverterTable = getInverterTable;
  },
  GetBatteryByCustomer(state, getBatteryByCustomer) {
    state.getBatteryByCustomer = getBatteryByCustomer;
  },
};
const actions = {
  // 发电概览-年发电量，线形图和柱状图的数据来源。
  async getCurrentGeneration({ commit }, customerId) {
    const getCurrentGeneration = await request.get(`/gen-management/curryearbar/${customerId}`);
    commit("GetCurrentGeneration", getCurrentGeneration);
  },

  // 发电概览-发电信息、收益信息、社会效益，OK
  async getDayMonthYear({ commit }, customerId) {
    const getDayMonthYear = await request.get(`/gen-management/overview/daymonthyear/${customerId}`);
    commit("GetDayMonthYear", getDayMonthYear);
  },

  // 发电概览-顶部三个数据，OK
  async getGenOverview({ commit }, customerId) {
    const getGenOverview = await request.get(`/gen-management/overview/${customerId}`);
    commit("GetGenOverview", getGenOverview);
  },

  // 发电概览：列表
  async getGenList({ commit }, val) {
    const getGenList = await request.get(
      `/gen-management/powerstation/${val.customerId}/${val.pageNum}/${val.pageSize}`
    );
    commit("GetGenList", getGenList);
  },

  // 发电监测：电站状态（发电信息、收益信息）
  async getPowerStationInfo({ commit }, val) {
    const getPowerStationInfo = await request.get(`/gen-management/infoandamount/${val}`);
    commit("GetPowerStationInfo", getPowerStationInfo);
  },

  // 发电监测：电站状态（基本信息）
  async getPowerStationBasicInfo({ commit }, val) {
    const getPowerStationBasicInfo = await request.get(`/gen-management/baseinfo/${val}`);
    commit("GetPowerStationBasicInfo", getPowerStationBasicInfo);
  },

  // 发电监测：电站状态（上网电量、购买电量）
  async getPowerStationElectricInfo({ commit }, val) {
    const getPowerStationElectricInfo = await request.get(`/gen-management/infoandamount2/${val}`);
    commit("GetPowerStationElectricInfo", getPowerStationElectricInfo);
  },

  // 发电监测：交流侧历史数据
  async getAlternateData({ commit }, val) {
    const getAlternateData = await request.get(
      `/gen-management/inverter/jiaoliu/${val.inverterId}/${val.type}/${val.date}`
    );
    commit("GetAlternateData", getAlternateData);
  },

  // 发电监测：逆变器状态（功率及发电量趋势）
  async getInverterStatus({ commit }, val) {
    const getInverterStatus = await request.get(`/gen-management/inverter/status/${val}`);
    commit("GetInverterStatus", getInverterStatus);
  },

  // 发电监测：直流侧、交流测
  async getStraitAlternate({ commit }, val) {
    const getStraitAlternate = await request.get(`/gen-management/inverter/zhijiaoliu/${val}`);
    commit("GetStraitAlternate", getStraitAlternate);
  },

  // 发电监测：直流侧历史数据
  async getStraitData({ commit }, val) {
    const getStraitData = await request.get(
      `/gen-management/inverter/zhiliu/${val.inverterId}/${val.type}/${val.date}`
    );
    commit("GetStraitData", getStraitData);
  },

  // 发电监测：逆变器状态（实时发电功率）
  async getInverterCurr({ commit }, val) {
    const getInverterCurr = await request.get(`/gen-management/inverter/${val}`);
    commit("GetInverterCurr", getInverterCurr);
  },

  // 报表统计：电站报表
  async getPowerTable({ commit }, val) {
    const getPowerTable = await request.get(
      `/gen-management/report/station/${val.customerId}/${val.tjmethod}/${val.datetype}/${val.date}/${val.pageNum}/${val.pageSize}?stationIds=${val.stationIds}`
    );
    commit("GetPowerTable", getPowerTable);
  },

  // 报表统计：储能报表
  async getBatteryTable({ commit }, val) {
    const getBatteryTable = await request.get(
      `/gen-management/report/battery/${val.customerId}/${val.datetype}/${val.date}/${val.pageNum}/${val.pageSize}?batteryIds=${val.batteryIds}`
    );
    commit("GetBatteryTable", getBatteryTable);
  },

  // 报表统计：逆变器报表
  async getInverterTable({ commit }, val) {
    const getInverterTable = await request.get(
      `/gen-management/report/inverter/${val.customerId}/${val.datetype}/${val.date}/${val.pageNum}/${val.pageSize}?inverterIds=${val.inverterIds}`
    );
    commit("GetInverterTable", getInverterTable);
  },

  // 报表统计：根据客户id获取储能列表
  async getBatteryByCustomer({ commit }, customerId) {
    const getBatteryByCustomer = await request.get(`/generator/battery/bycustomer/${customerId}`);
    commit("GetBatteryByCustomer", getBatteryByCustomer);
  },

  // 发电监测：电站状态（功率及发电量趋势）
  async getPowerStatus({ commit }, val) {
    const getPowerStatus = await request.get(
      `/gen-management/trend/station/${val.stationId}/${val.dateType}/${val.date}`
    );
    commit("GetPowerStatus", getPowerStatus);
  },

  // 发电管理设置里面获取数据
  async getGeneratorSetting({ commit }, val) {
    const getGeneratorSetting = await request.get(`/generalsetting/${val.generator}/${val.customerId}`);
    commit("GETGENERATORSETTING", getGeneratorSetting);
  },

  // 发电管理设置里面保存数据
  async putGenerator({ commit }, val) {
    // console.log(data)
    const putGenerator = await request.put(`/generalsetting/${val.id}`, val.data);
    commit("PUTGENERATOR", putGenerator);
  },

  // 发电管理-电站状态-实时发电功率、气象信息
  async getRealpowerWeather({ commit }, val) {
    const getRealpowerWeather = await request.get(`/gen-management/realtime/station/${val}`);
    commit("GetRealpowerWeather", getRealpowerWeather);
  },

  // 根据客户id获取逆变器列表
  async getInvertersByCustomer({ commit }, val) {
    const getInvertersByCustomer = await request.get(`/generator/bycustomer/${val}`);
    commit("GetInvertersByCustomer", getInvertersByCustomer);
  },

  // 发电监测：逆变器状态（发电信息、收益信息）
  async getInverterInfoAndAmount({ commit }, inverterId) {
    const getInverterInfoAndAmount = await request.get(`/gen-management/infoandamount/inverter/${inverterId}`);
    commit("GetInverterInfoAndAmount", getInverterInfoAndAmount);
  },

  // 发电监测：逆变器状态（基本信息）
  async getInverterBasicInfo({ commit }, inverterId) {
    const getInverterBasicInfo = await request.get(`/gen-management/baseinfo/inverter/${inverterId}`);
    commit("GetInverterBasicInfo", getInverterBasicInfo);
  },

  // 发电监测：逆变器状态（实时发电功率、直流侧、交流测）
  async getInverterRealtime({ commit }, inverterId) {
    const getInverterRealtime = await request.get(`/gen-management/realtime/inverter/${inverterId}`);
    commit("GetInverterRealtime", getInverterRealtime);
  },

  // 发电监测：逆变器状态（功率及发电量趋势）
  async getInverterTrendData({ commit }, val) {
    const getInverterTrendData = await request.get(
      `/gen-management/trend/inverter/${val.inverterId}/${val.datetype}/${val.date}`
    );
    commit("GetInverterTrendData", getInverterTrendData);
  },

  async getCurrFlow({ commit }, val) {
    const getCurrFlow = await request.get(`/gen-management/overview/currflow/${val.customerId}/${val.stationId}`);
    commit("GetCurrFlow", getCurrFlow);
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
