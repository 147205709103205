<template>
  <div class="DialogChangePhoto">
    <el-dialog
      @close="closeDialog('ruleForm')"
      :visible.sync="dialogFormVisible"
      width="20%"
      :close-on-click-modal="false"
    >
      <div slot="title">
        <div class="equip_title">更换头像</div>
      </div>
      <el-form :model="form" class="elForm" >
          <el-form-item label="上传图片" prop="remark" style="width: 100%;"></el-form-item>
            <el-upload
              :file-list="fileList"
              :headers="myToken"
              :action="baseURL+'file/upload'"
              :on-success="upLoadSuccess"
              :on-error="upLoadError"
              :on-exceed="handelExceed"
              :accept="accept"
              :before-upload="beforeAvatarUpload"
              :disabled="false"
              :auto-upload="true"
              list-type="picture-card"
              :limit="1"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                <span class="el-upload-list__item-actions">
                    <!-- 预览大图 -->
                    <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                       <i>预览</i>
                    </span>
                    <!-- 删除图片 -->
                    <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                       <i>删除</i>
                    </span>
                  </span>
                </div>
            </el-upload>

      </el-form>

      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle()">取 消</button>
        <button class="btn_save" @click="postDialogData()">保 存</button>
      </div>
 
    </el-dialog>
    <el-dialog :visible.sync="dialogForPictureVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
import config from '@/config/index.js'
import { mapState } from "vuex";
import { tableBg } from "@/mixin/tablebg";
import {getCookie} from "@/utils/util.js"


export default {
  name: "DialogChangePhoto",
  mixins: [tableBg],
  components:{},
  data() {
    return {
      // 图片列表
      fileList:[],
      // 图片名称列表
      fileNameList:[],
      // 是否展示预览大图
      dialogForPictureVisible:false,
      // 是否禁用图片的添加
      disabled: false,
      // 图片的双向绑定
      dialogImageUrl: '',
      // 是否从告警页面过来
      alarm:false,
      // baseURL
      baseURL:'',
      // 类型列表 
      typeData:[],
      // 请求类型（编辑=2，登记=1）
      getType: 0,
      // 展示判断
      dialogFormVisible: false,
  
      // 表单数据存放处
      form: {userId:"",img:""},
      tableList: [],

      //令牌
      myToken:{Authorization: "没有拿到token"},
      accept:"image/png,image/jpg,image/jpeg"
      
    
    };
  },

  methods: {
    // dialog关闭的回调
    closeDialog() {
      this.formInit();
      
    },

    // 取消弹框
    handleCancle() {
      this.dialogFormVisible = false;
      this.formInit();
    },

    // from归零
    formInit() {
      this.fileList=[]
      this.form = {userId:"",img:""};
      },
   
    //父组件点击触发
    show() {
      this.dialogFormVisible = true
      this.getType = 1
      this.form.userId = getCookie('id')
    },


    // 提交发送请求（一级dialog点击《保存》）
    postDialogData() {
        this.form.img = this.fileNameList[0]
        this.$store.dispatch('putSystemUserEditPhoto',this.form).then(()=>{
          if(this.ifSuccessEditPhoto === 0){  
            this.$message.success("更换头像成功！")
            this.dialogFormVisible = false;
          }else{
            this.$message.error(this.ifSuccessEditPhotoError)
          }
        })
    },

   

    // 删除图片
    handleRemove() {
      this.fileList=[]
      
    },
    // 预览图片
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogForPictureVisible = true;
    },

    // 上传图片成功
    upLoadSuccess(val){
      this.$message.success("上传图片成功！")
      this.fileNameList.push(val.data)
    },

    // 文件传输失败
    upLoadError(val){
      this.$message.error(val.message)
    },

    // 超出一张图片时的钩子
    handelExceed(){
      this.$message.error("只能上传一张图片！")
    },
    beforeAvatarUpload(file) {
      const isImg = this.accept.split(',').includes(file.type);
      if (!isImg) {
        this.$message.error('上传头像图片只能是 JPG/PNG 格式!');
      }
      return isImg;
    }

  },
  //处理state数据
  computed: {
    ...mapState({
      // 新增
      ifSuccessEditPhoto: state => {return state.system.ifSuccessEditPhoto.errorCode;},
      ifSuccessEditPhotoError:state => {return state.system.ifSuccessEditPhoto.message;},

    })
  },

  mounted(){
    this.baseURL = config.baseURL
    this.myToken={Authorization: "Bearer "+getCookie("token")}
  },

  



  
};




</script>

<style lang="less" scoped>
.DialogChangePhoto{
  overflow: hidden;
  .el-dialog__body{
    height: 60rem;
  }
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2d79be;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
// 树所在的区域###################
.treePlace{
  height: 55rem;
  width: 26rem;
  position: absolute;
  top: 1rem;
  left: 80rem;
  overflow: hidden;
  box-shadow: 1rem 0.5rem 2rem #071828;
  border-radius: 0.4rem;
  transition: height 0.3s;  
  z-index: 20000;
  
  
  // border: #071828 0.2rem solid;
}
.treePlaceGoAway{
  // opacity: 0;
  display: none;
  // position:relative;
  // top: -54rem;
  // left: 80rem;
  height: 0;
  width: 26rem;
  overflow: hidden;
  // display: none;
  transition: height 0.3s;  
}

.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}
.btnForMeters{
  height: 3.2rem;
  width: 100%;
  background-color: #0b3d6b;
  border: #004579 0.1rem solid;
  cursor: pointer;
  border-radius: 0.4rem;
  color: #abb7c2;
}

</style>




<style lang="less">
.DialogChangePhoto{
  .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }

  .el-table::before {
      opacity: 0;
  }
.el-textarea__inner {
  color: #c0c4cc;
  background-color: #0b3d6b;
  border: 0.1rem solid #004579;
}
.el-textarea__inner:hover {
    border-color: #0b3d6b;
}

.el-textarea__inner {
    color: #fff;
    background-color: #0b3d6b;
    border: 1px solid #0b3d6b;

}

.el-upload--picture-card {
    background-color: #0b3d6a;
    border: 2px dashed #115D96;
    
}
  
}

</style>



