//获取cookie
export function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg))
    return (arr[2]);
  else
    return null;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie(c_name, value, expiredays) {
  var exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
};

//删除cookie
export function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
};

// 格式化日期
const formatTime = (oldDate, format = 'yyyy-MM-dd HH:mm:ss') => {
  const date = new Date(oldDate)
  const config = {
    yyyy: date.getFullYear(),
    M: date.getMonth() + 1,
    MM: formatNum(date.getMonth() + 1),
    W: date.getDay(),
    WW: formatNum(date.getDay()),
    d: date.getDate(),
    dd: formatNum(date.getDate()),
    H: date.getHours(),
    HH: formatNum(date.getHours()),
    h: date.getHours() > 12 ? (date.getHours() - 12) : date.getHours(),
    hh: formatNum(date.getHours()) > 12 ? (formatNum(date.getHours()) - 12) : formatNum(date.getHours()),
    m: date.getMinutes(),
    mm: formatNum(date.getMinutes()),
    s: date.getSeconds(),
    ss: formatNum(date.getSeconds()),
    A: date.getHours() < 12 ? 'AM' : 'PM',
    a: date.getHours() < 12 ? 'am' : 'pm'
  }
  const formatConfigs = format.match(/[a-zA-Z]+/g)
  formatConfigs.forEach(item => {
    format = format.replace(item, config[item])
  })
  return format
}

function formatNum(num) {
  return num < 10 ? `0${num}` : num
}
export {
  formatTime
}

// 格式化form的数据
export function mapObjToFormData(obj) {
  const formData = new FormData();
  Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty(key)) {
      if (obj[key]) { //属性值不为空
        if (obj[key].constructor === Array) { //属性值是数组
          if (obj[key][0]) {
            if (obj[key][0] instanceof Object) {
              formData.append(key, JSON.stringify(obj[key]));
            } else if (obj[key][0].name) {
              obj[key].forEach((item) => formData.append(key, item));
            } else {
              obj[key].forEach((item, index) => formData.append(key + `[${index}]`, item));
            }
          } else {
            formData.append(key, "");
          }
        } else if (obj[key] instanceof Object) { //属性值是对象
          formData.append(key, JSON.stringify(obj[key]));
        } else { //其他类型的属性值
          formData.append(key, obj[key]);
        }
      } else { //属性值为空
        if (obj[key] === 0) { //如果是数字0
          formData.append(key, 0);
        } else { //其他：null、undefined、空串
          formData.append(key, "");
        }
      }
    }
  });
  return formData;
}