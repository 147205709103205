import request from "../../utils/request.js";
import config from "../../config/index.js";
// import requestPut from "../../utils/requestPut.js";

// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中

const state = {
  getGeneralSetting: {},
  putGeneral: {},
  queryEnergyToday: {},
  getBuildById: {},
  queryEnergyRank: {},
  querySumMouth: {},
  queryTrendMouth: {},
  getBuildTime: {},
  getBuildRank: {},
  getBuildCard: {},
  queryEnergyTrend: {},
  queryBuildingquota: {},
  querySumOverview: {},
  querySumOverviewLocation: {},
  getLocaltionId: {},
  getEquipmentBuilding: {},
  getChildrenLocation: {},
  getBarWorkorRest: {},
  getEnerWorkorRest: {},
  getEnerRank: {},
  getEnerRankByLocation: {},
  getEnerPower: {},
  getFloatId: {},
  getPowerMapFloor: {},
  getTodayCal: {},
  getAnanlysis: {},
  getAnalysisTrend: {},
  getAnalysisWorkOrRest: {},
  getAnalysisWeek: {},
  getBuildingIds: {},
  getEnergyReportByRegion: {},
  getEnergySubitemTree: {},
  getEnergyDistributionTree: {},
  getEnergyReportByPowertype: {},
  getEnergyReportByMeter: {},
  timeAnalysisDate: "",
  compareWorkDate: "",
  compareTabIndex: "",
  compareTrendDate: "",
  ifExport: "",
};
const mutations = {
  QUERYENERGYTODAY(state, queryEnergyToday) {
    state.queryEnergyToday = queryEnergyToday;
  },
  QUERYENERGYTREND(state, queryEnergyTrend) {
    state.queryEnergyTrend = queryEnergyTrend;
  },
  GETBUILDBYID(state, getBuildById) {
    state.getBuildById = getBuildById;
  },
  QUERYENERGYRANK(state, queryEnergyRank) {
    state.queryEnergyRank = queryEnergyRank;
  },
  QUERYBUILDINGQUOTA(state, queryBuildingquota) {
    state.queryBuildingquota = queryBuildingquota;
  },
  GETGENERALSETTING(state, getGeneralSetting) {
    state.getGeneralSetting = getGeneralSetting;
  },
  QUERYSUMMOUTH(state, querySumMouth) {
    state.querySumMouth = querySumMouth;
  },
  QUERYTRENDMOUTH(state, queryTrendMouth) {
    state.queryTrendMouth = queryTrendMouth;
  },
  GETBUILDTIME(state, getBuildTime) {
    state.getBuildTime = getBuildTime;
  },
  GETBUILDRANK(state, getBuildRank) {
    state.getBuildRank = getBuildRank;
  },
  GETBUILDCARD(state, getBuildCard) {
    state.getBuildCard = getBuildCard;
  },
  GETFOURTYPE(state, getFourType) {
    state.getFourType = getFourType;
  },
  QUERYSUMOVERVIEW(state, querySumOverview) {
    state.querySumOverview = querySumOverview;
  },
  QUERYSUMOVERVIEWLOCATION(state, querySumOverviewLocation) {
    state.querySumOverviewLocation = querySumOverviewLocation;
  },
  PUTGENERAL(state, putGeneral) {
    state.putGeneral = putGeneral;
  },
  GETLOCALTIONID(state, getLocaltionId) {
    state.getLocaltionId = getLocaltionId;
  },
  GETEQUIPMENTBUILDING(state, getEquipmentBuilding) {
    state.getEquipmentBuilding = getEquipmentBuilding;
  },
  GETCHILDRENLOCATION(state, getChildrenLocation) {
    state.getChildrenLocation = getChildrenLocation;
  },
  GETBARWORKORREST(state, getBarWorkorRest) {
    state.getBarWorkorRest = getBarWorkorRest;
  },
  GETENERWORKORREST(state, getEnerWorkorRest) {
    state.getEnerWorkorRest = getEnerWorkorRest;
  },
  GETENERRANK(state, getEnerRank) {
    state.getEnerRank = getEnerRank;
  },
  GETENERRANKBYLOCATION(state, getEnerRankByLocation) {
    state.getEnerRankByLocation = getEnerRankByLocation;
  },
  GETENERPOWER(state, getEnerPower) {
    state.getEnerPower = getEnerPower;
  },
  GETFLOATID(state, getFloatId) {
    state.getFloatId = getFloatId;
  },
  GETPOWERMAPFLOOR(state, getPowerMapFloor) {
    state.getPowerMapFloor = getPowerMapFloor;
  },
  GETYODAYCAL(state, getTodayCal) {
    state.getTodayCal = getTodayCal;
  },
  GetAnanlysis(state, getAnalysis) {
    state.getAnalysis = getAnalysis;
  },
  GetAnalysisTrend(state, getAnalysisTrend) {
    state.getAnalysisTrend = getAnalysisTrend;
  },
  GetAnalysisWorkOrRest(state, getAnalysisWorkOrRest) {
    state.getAnalysisWorkOrRest = getAnalysisWorkOrRest;
  },
  GetAnalysisWeek(state, getAnalysisWeek) {
    state.getAnalysisWeek = getAnalysisWeek;
  },
  GetBuildingIds(state, getBuildingIds) {
    state.getBuildingIds = getBuildingIds;
  },
  GetEnergyReportByRegion(state, getEnergyReportByRegion) {
    state.getEnergyReportByRegion = getEnergyReportByRegion;
  },
  GetEnergySubitemTree(state, getEnergySubitemTree) {
    state.getEnergySubitemTree = getEnergySubitemTree;
  },
  GetEnergyDistributionTree(state, getEnergyDistributionTree) {
    state.getEnergyDistributionTree = getEnergyDistributionTree;
  },
  GetEnergyReportByMeter(state, getEnergyReportByMeter) {
    state.getEnergyReportByMeter = getEnergyReportByMeter;
  },
  GetEnergyReportByPowertype(state, getEnergyReportByPowertype) {
    state.getEnergyReportByPowertype = getEnergyReportByPowertype;
  },
  TimeAnalysisDate(state, timeAnalysisDate) {
    state.timeAnalysisDate = timeAnalysisDate;
  },
  CompareWorkDate(state, compareWorkDate) {
    state.compareWorkDate = compareWorkDate;
  },
  CompareTabIndex(state, compareTabIndex) {
    state.compareTabIndex = compareTabIndex;
  },
  CompareTrendDate(state, compareTrendDate) {
    state.compareTrendDate = compareTrendDate;
  },
  ExportConsumption(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportDistribution(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportRegion(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportDailyElec(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportAnalysisByTime(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportAnalysisTrend(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportAnalysisWorkAndRest(state, ifExport) {
    state.ifExport = ifExport;
  },
  ExportInterval(state, ifExport) {
    state.ifExport = ifExport;
  },
};
const actions = {
  // 能耗概况的今日能耗，OK
  async queryEnergyToday({ commit }, val) {
    const queryEnergyToday = await request.get(`/energy/today/${val}`);
    commit("QUERYENERGYTODAY", queryEnergyToday);
  },

  // 能耗概括的今日能源趋势图数据，OK
  async queryEnergyTrend({ commit }, val) {
    const queryEnergyTrend = await request.get(`/energy/today/trend/${val}`);
    commit("QUERYENERGYTREND", queryEnergyTrend);
  },

  // 能耗概况的今日能耗排名里面根据用户id获取大楼id，OK
  async getBuildById({ commit }, val) {
    const getBuildById = await request.get(`/location/building/${val}`);
    commit("GETBUILDBYID", getBuildById);
  },
  // 能耗概况的今日能耗排名，OK
  async queryEnergyRank({ commit }, val) {
    const queryEnergyRank = await request.get(`/energy/today/rank/${val}`);
    commit("QUERYENERGYRANK", queryEnergyRank);
  },
  // 能耗概况的今日能耗数据，可以获取到楼栋id和楼栋name，楼栋id供getBuildTime这个接口使用
  async querySumOverview({ commit }, val) {
    const querySumOverview = await request.get(`/energy/sum/overview/${val}`);
    commit("QUERYSUMOVERVIEW", querySumOverview);
  },
  // 能耗概况的今日能耗数据，可以获取到楼栋id和楼栋name，楼栋id供getBuildTime这个接口使用
  async querySumOverviewLocation({ commit }, val) {
    const querySumOverviewLocation = await request.get(`/energy/sum/overview/location/${val}`);
    commit("QUERYSUMOVERVIEWLOCATION", querySumOverviewLocation);
  },
  // 能耗概况的办公楼能耗指标，OK
  async queryBuildingquota({ commit }, val) {
    const queryBuildingquota = await request.get(`/energy/buildingquota/${val}`);
    commit("QUERYBUILDINGQUOTA", queryBuildingquota);
  },
  // 能耗统计的区域用电  本月区域总用电，OK
  async querySumMouth({ commit }, val) {
    const querySumMouth = await request.get(`/energy/sum/month/${val}`);
    commit("QUERYSUMMOUTH", querySumMouth);
  },
  // 能耗统计的区域用电  本月区域用电趋势，OK
  async queryTrendMouth({ commit }, val) {
    const queryTrendMouth = await request.get(`/energy/sum/month/trend/${val}`);
    commit("QUERYTRENDMOUTH", queryTrendMouth);
  },
  // 能耗统计的区域用电  办公楼1能耗概览
  async getBuildTime({ commit }, val) {
    const getBuildTime = await request.get(`/energy/building/single/overview/${val.id}/${val.timetype}`);
    commit("GETBUILDTIME", getBuildTime);
  },
  // 能耗统计的区域用电  办公楼1能耗排名
  async getBuildRank({ commit }, val) {
    const getBuildRank = await request.get(`/energy/building/single/rank/${val.id}/${val.timetype}`);
    commit("GETBUILDRANK", getBuildRank);
  },
  // 能耗统计的分项用电  分项用电4个卡片，OK
  async getBuildCard({ commit }, val) {
    const getBuildCard = await request.get(`/energy/fourtype/card/${val}`);
    commit("GETBUILDCARD", getBuildCard);
  },
  // 能耗统计的分项用电  左边echarts，OK
  async getFourType({ commit }, val) {
    const getFourType = await request.get(`/energy/fourtype/trend/${val}`);
    commit("GETFOURTYPE", getFourType);
  },
  // 能耗设置里面获取数据，OK
  async getGeneralSetting({ commit }, val) {
    const getGeneralSetting = await request.get(`/generalsetting/${val.generator}/${val.customerId}`);
    commit("GETGENERALSETTING", getGeneralSetting);
  },
  // 能耗设置里面保存数据
  async putGeneral({ commit }, val) {
    // console.log(data)
    const putGeneral = await request.put(`/generalsetting/${val.id}`, val.data);
    commit("PUTGENERAL", putGeneral);
  },
  // 二级树获取id
  async getLocaltionId({ commit }, val) {
    const getLocaltionId = await request.get(`/location/${val}`);
    commit("GETLOCALTIONID", getLocaltionId);
  },
  // 时段分析左侧二级树的数据，OK
  async getEquipmentBuilding({ commit }, val) {
    const getEquipmentBuilding = await request.get(`/tree/location/${val}`);
    commit("GETEQUIPMENTBUILDING", getEquipmentBuilding);
  },
  // 时段分析左侧二级树的数据，OK
  async getChildrenLocation({ commit }, val) {
    const getChildrenLocation = await request.get(`/tree/locationAllChildByCustomId/${val}`);
    commit("GETCHILDRENLOCATION", getChildrenLocation);
  },
  // 时段分析模块里面的分时统计，OK
  async getBarWorkorRest({ commit }, val) {
    const getBarWorkorRest = await request.get(`/energy/graph/bar/work-rest/${val.id}/${val.month}`);
    commit("GETBARWORKORREST", getBarWorkorRest);
  },
  // 分时统计的echarts
  async getEnerWorkorRest({ commit }, val) {
    const getEnerWorkorRest = await request.get(`/energy/graph/bar/work-rest/${val.id}/${val.year}/${val.type}`);
    commit("GETENERWORKORREST", getEnerWorkorRest);
  },
  // 排名分析
  async getEnerRank({ commit }, val) {
    const getEnerRank = await request.get(`/energy/month/rank/${val}`);
    commit("GETENERRANK", getEnerRank);
  },
  async getEnerRankByLocation({ commit }, val) {
    const getEnerRankByLocation = await request.get(`/energy/month/rankByLocation/${val}`);
    commit("GETENERRANKBYLOCATION", getEnerRankByLocation);
  },
  // 能耗地图第一页数据，OK
  async getEnerPower({ commit }, val) {
    const getEnerPower = await request.get(`/energy/powermap/${val}`);
    commit("GETENERPOWER", getEnerPower);
  },
  // 根据客户id获取大楼和楼层，OK
  async getFloatId({ commit }, val) {
    const getFloatId = await request.get(`/location/floors/${val}`);
    commit("GETFLOATID", getFloatId);
  },
  // 能耗地图根据楼层id获取对应的数据，OK
  async getPowerMapFloor({ commit }, val) {
    const getPowerMapFloor = await request.get(`/energy/powermap/floor/${val}`);
    commit("GETPOWERMAPFLOOR", getPowerMapFloor);
  },
  // 能耗概览的能源日历数据，OK
  async getTodayCal({ commit }, val) {
    const getTodayCal = await request.get(`/energy/today/calendar/${val.id}/${val.month}`);
    commit("GETYODAYCAL", getTodayCal);
  },
  // 对比分析：分时统计的数据
  async getAnalysis({ commit }, val) {
    const getAnalysis = await request.get(
      `/energy/graph/analyze/bytime/${val.locationId}/${val.type}?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`
    );
    commit("GetAnanlysis", getAnalysis);
  },
  // 对比分析：能耗趋势分析
  async getAnalysisTrend({ commit }, val) {
    const getAnalysisTrend = await request.get(
      `/energy/graph/analyze/trend/${val.locationId}/${val.type}?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`
    );
    commit("GetAnalysisTrend", getAnalysisTrend);
  },
  // 对比分析：工作/非工作能耗分析
  async getAnalysisWorkOrRest({ commit }, val) {
    const getAnalysisWorkOrRest = await request.get(`/energy/graph/analyze/workandrest/${val.locationId}/${val.month}`);
    commit("GetAnalysisWorkOrRest", getAnalysisWorkOrRest);
  },
  // 对比分析：周末周中能耗分析
  async getAnalysisWeek({ commit }, val) {
    const getAnalysisWeek = await request.get(
      `/energy/graph/analyze/workdayweekend/${val.locationId}/${val.year}/${val.type}`
    );
    commit("GetAnalysisWeek", getAnalysisWeek);
  },
  // 统计报表：区域用能表格数据
  async getEnergyReportByRegion({ commit }, val) {
    const getEnergyReportByRegion = await request.post(`/energy/report/byregion`, val);
    commit("GetEnergyReportByRegion", getEnergyReportByRegion);
  },
  // 统计报表：分项用能表格数据
  async getEnergyReportByPowertype({ commit }, val) {
    const getEnergyReportByPowertype = await request.post(`/energy/report/bypowertype`, val);
    commit("GetEnergyReportByPowertype", getEnergyReportByPowertype);
  },
  // 统计报表：配电用能表格数据
  async getEnergyReportByMeter({ commit }, val) {
    // const getEnergyReportByMeter = await request.get(
    //   `/energy/report/bymeter/${val.customerId}/${val.type}/${val.datetype}/${val.date}?nodeIds=${val.nodeIds}`
    // );
    const getEnergyReportByMeter = await request.get(
      `/energy/report/bypedian/${val.customerId}/${val.type}/${val.datetype}/${val.date}?floorIds=${val.floorIds}`
    );
    commit("GetEnergyReportByMeter", getEnergyReportByMeter);
  },
  // 统计报表：分项用能左侧树
  async getEnergySubitemTree({ commit }, customerId) {
    const getEnergySubitemTree = await request.get(`/tree/buildingfourtype/${customerId}`);
    commit("GetEnergySubitemTree", getEnergySubitemTree);
  },
  // 统计报表：配电用能左侧树
  async getEnergyDistributionTree({ commit }, customerId) {
    // const getEnergyDistributionTree = await request.get(`/tree/place-device-circuit/${customerId}`);
    const getEnergyDistributionTree = await request.get(`/tree/peidian/${customerId}`);
    commit("GetEnergyDistributionTree", getEnergyDistributionTree);
  },
  compareWorkDate({ commit }, compareWorkDate) {
    commit("CompareWorkDate", compareWorkDate);
  },
  timeAnalysisDate({ commit }, timeAnalysisDate) {
    commit("TimeAnalysisDate", timeAnalysisDate);
  },
  // 导出分项用能excel
  async exportConsumption({ commit }, val) {
    commit("ExportConsumption", 1);
    window.location.href =
      config.baseURL +
      `energy/consumption/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?nodeIds=${val.nodeIds}`;
  },
  // 导出配电用能excel
  async exportDistribution({ commit }, val) {
    commit("ExportDistribution", 1);
    window.location.href =
      config.baseURL +
      `energy/report/bymeter/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?nodeIds=${val.nodeIds}`;
  },
  // 导出区域用能excel
  async exportRegion({ commit }, val) {
    commit("ExportRegion", 1);
    window.location.href =
      config.baseURL +
      `energy/report/region/${val.customerId}/${val.type}/${val.datetype}/${val.date}/export/?floorIds=${val.floorIds}`;
  },
  // 导出本月每日用电excel
  async exportDailyElec({ commit }, val) {
    commit("ExportDailyElec", 1);
    window.location.href = config.baseURL + `energy/export?customerId=${val.customerId}&month=${val.month}`;
  },
  // 导出对比分析-分时统计excel
  async exportAnalysisByTime({ commit }, val) {
    commit("ExportAnalysisByTime", 1);
    window.location.href =
      config.baseURL +
      `energy/graph/analyze/byTime/${val.locationIds}/${val.type}/export/?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`;
  },
  // 导出对比分析-能耗趋势分析excel
  async exportAnalysisTrend({ commit }, val) {
    commit("ExportAnalysisTrend", 1);
    window.location.href =
      config.baseURL +
      `energy/graph/analyze/trend/${val.locationId}/${val.type}/export/?dateStart=${val.dateStart}&dateEnd=${val.dateEnd}`;
  },
  // 导出对比分析-工作/非工作能耗分析excel
  async exportAnalysisWorkAndRest({ commit }, val) {
    commit("ExportAnalysisWorkAndRest", 1);
    window.location.href = config.baseURL + `energy/graph/analyze/workandrest/${val.locationId}/${val.month}/export/`;
  },
  // 导出分时统计excel
  async exportInterval({ commit }, val) {
    commit("ExportInterval", 1);
    window.location.href = config.baseURL + `energy/time/interval/export?locationId=${val.locationId}&month=${val.month}`;
  },
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
