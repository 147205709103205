<template>
    <div class="DrawerForMessage">
        <el-drawer :visible.sync="drawer" :with-header="false" direction=rtl size=29.58% @closed=drawerClosed>

            <div class="topPlace">
              <div @click="read1" :class="tab1">严重告警({{this.seriousCount}})</div>
              <div @click="read2" :class="tab2">紧急告警({{this.urgentCount}})</div>
              <div @click="read3" :class="tab3">其他告警({{this.otherCount}})</div>
              <div @click="read4" :class="tab4">运维检修</div>
              <div @click="read5" :class="tab5">全部通知</div>
            </div>

            <div @click="iHaveRead(item,index)" v-for="(item,index) in allMessage" :key="index" class="card">
                <div :class="item.isRead === 0 ? 'lightRed' : 'lightGrey'"></div>
                <div class="content">
                    <div class="word1" :title="item.stageDetails">{{item.stageDetails}}</div>
                    <div class="word2" :title="item.content">{{item.content}}</div>
                    <div class="word2">{{item.createTime}}</div>
                </div>
            </div>

            <img v-show="ifNoData" class="imgNoData" src="@/assets/login/noData.png" alt="">

            <!-- 分页器部分 -->
            <div class="pagination">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next" :total="customerTotalNum"></el-pagination>
            </div>

        </el-drawer>

    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name: "DrawerForMessage",
    data () {
        return {
            // 展示的页面是哪一页(true是未读页面，false是全部页面)
            ifPageLeft: true,
            // 是否展示抽屉
            drawer: false,
            // 分页器数据,当前页面
            currentPage: 1,
            // 页条数
            pageSize: 7,
            // 总页数
            customerTotalNum: 0,
            // 消息类型 0 紧急告警 1 严重告警 2 其他告警 3 巡检   全部 不传
            msgType: 0,
            // 标题的样式
            tab1: "tabGreen",
            tab2: "tabGrey",
            tab3: "tabGrey",
            tab4: "tabGrey",
            tab5: "tabGrey",
            // 未读消息,已读消息
            allMessage: [],
            // 消息提示
            stageDetails: "",
            // 展示页面
            showPage: "serious",
            // 是否没有数据
            ifNoData: false,
            // 未读消息数量
            seriousCount: 0,
            urgentCount: 0,
            otherCount: 0,
            isRead: 0,
        }
    },
    computed: {
        ...mapState({
            messageData: state => { return state.login.messageData.data.records; },
            messageTotal: state => { return state.login.messageData.data.total; },
            unreadMessageGroupCount: state => { return state.login.unreadMessageGroupCount.data; },

        })
    },
    methods: {
        // 父元素要求展示抽屉
        show () {
            this.allMessage = []
            this.ifNoData = false
            this.drawer = true
            this.showPage = "serious"
            this.seriousCount = this.$parent.groupCount.criticalAlertCount ? this.$parent.groupCount.criticalAlertCount : "-";
            this.urgentCount = this.$parent.groupCount.emergencyAlertCount ? this.$parent.groupCount.emergencyAlertCount : "-";
            this.otherCount = this.$parent.groupCount.otherAlertCount ? this.$parent.groupCount.emergencyAlertCount : "-";
            this.refreshThePageRead();
            this.getGroupCount()
        },
        //发送请求#####分页器改变条数/改变页面
        handleSizeChange (val) {
            this.pageSize = val
            if (this.showPage === "serious" || this.showPage === "urgent" || this.showPage === "other" || this.showPage === "overhaul") { this.refreshThePageRead() }
            else { this.refreshThePage(); }
        },
        handleCurrentChange (val) {
            this.currentPage = val
            if (this.showPage === "serious" || this.showPage === "urgent" || this.showPage === "other" || this.showPage === "overhaul") { this.refreshThePageRead() }
            else { this.refreshThePage(); }
        },

        // 刷新页面(全部)
        refreshThePage () {
            this.$store.dispatch('getMessageData', { pageNum: this.currentPage, pageSize: this.pageSize}).then(() => {
                if (this.messageTotal === 0) {
                    this.customerTotalNum = this.messageTotal
                    this.ifNoData = true
                }
                else {
                    this.allMessage = this.messageData
                    this.customerTotalNum = this.messageTotal
                }
            })
        },
        // 刷新页面(已读)
        refreshThePageRead () {
            this.$store.dispatch('getMessageDataRead', { pageNum: this.currentPage, pageSize: this.pageSize, isRead: '0', msgType: this.msgType }).then(() => {
                if (this.messageTotal === 0) {
                    this.customerTotalNum = this.messageTotal
                    this.ifNoData = true
                }
                else {
                    console.log(this.messageData)
                    this.allMessage = this.messageData
                    this.customerTotalNum = this.messageTotal
                    const allMyCard = document.getElementsByClassName('cardMove')
                    allMyCard[0].className = 'card'
                }

            })
        },
        getGroupCount() {
          this.$store.dispatch('getUnreadMessageGroupCount').then(() => {
            console.info(this.unreadMessageGroupCount)
            if (this.unreadMessageGroupCount) {
              this.seriousCount = this.unreadMessageGroupCount.criticalAlertCount
              this.urgentCount = this.unreadMessageGroupCount.emergencyAlertCount
              this.otherCount = this.unreadMessageGroupCount.otherAlertCount
            }
          })
        },



        // 切换到严重告警
        read1 () {
            this.ifPageLeft = true;
            this.allMessage = []
            this.ifNoData = false
            this.tab1 = 'tabGreen';
            this.tab2 = 'tabGrey';
            this.tab3 = 'tabGrey';
            this.tab4 = 'tabGrey';
            this.tab5 = 'tabGrey';
            this.showPage = "serious";
            this.msgType = 1
            this.currentPage = 1
            this.refreshThePageRead()

        },
        // 切换到紧急告警
        read2 () {
            this.ifPageLeft = false;
            this.allMessage = []
            this.ifNoData = false
            this.tab1 = 'tabGrey';
            this.tab2 = 'tabGreen';
            this.tab3 = 'tabGrey';
            this.tab4 = 'tabGrey';
            this.tab5 = 'tabGrey';
            this.showPage = "urgent";
            this.msgType = 0
            this.currentPage = 1
            this.refreshThePageRead()
        },
        // 切换到其他告警
        read3 () {
          this.ifPageLeft = false;
          this.allMessage = []
          this.ifNoData = false
          this.tab1 = 'tabGrey';
          this.tab2 = 'tabGrey';
          this.tab3 = 'tabGreen';
          this.tab4 = 'tabGrey';
          this.tab5 = 'tabGrey';
          this.showPage = "other";
          this.msgType = 2
          this.currentPage = 1
          this.refreshThePageRead()
        },
        // 切换到运维检修
        read4 () {
          this.ifPageLeft = false;
          this.allMessage = []
          this.ifNoData = false
          this.tab1 = 'tabGrey';
          this.tab2 = 'tabGrey';
          this.tab3 = 'tabGrey';
          this.tab4 = 'tabGreen';
          this.tab5 = 'tabGrey';
          this.showPage = "overhaul";
          this.msgType = 3
          this.currentPage = 1
          this.refreshThePageRead()
        },
        // 切换到全部消息界面
        read5 () {
          this.ifPageLeft = false;
          this.allMessage = []
          this.ifNoData = false
          this.tab1 = 'tabGrey';
          this.tab2 = 'tabGrey';
          this.tab3 = 'tabGrey';
          this.tab4 = 'tabGrey';
          this.tab5 = 'tabGreen';
          this.showPage = "all";
          this.msgType = null
          this.currentPage = 1
          this.refreshThePage()
        },


        // 全部数据中点击已读(这里不要任何提示)
        iHaveRead (item, index) {
            if (this.ifPageLeft == true) {
                const allMyCard = document.getElementsByClassName('card')
                allMyCard[index].className = 'cardMove'
                setTimeout(() => {
                    this.$store.dispatch('getIHaveReadTheMessage', item.id).then(() => {
                        // 未读页面刷新
                        if (this.showPage === "serious") { this.refreshThePageRead() }
                        // 已读页面变灰
                        else { this.allMessage[index].isRead = 1 }

                    })
                }, 500)
            } else {
                this.$store.dispatch('getIHaveReadTheMessage', item.id).then(() => {
                    // 未读页面刷新
                    if (this.showPage === "serious") { this.refreshThePageRead() }
                    // 全部页面变灰
                    else { this.allMessage[index].isRead = 1 }
                    const allMyCard = document.getElementsByClassName('cardMove')
                    allMyCard[0].className = 'card'
                })
            }
            let path = "";
            let query = {};
            let menuId = '';
            if (item.remindType == 0 || item.remindType == 3) {
                path = '/repair/orderManagement'
                menuId = 25
            } else if (item.remindType == 1 || item.remindType == 2) {
                path = '/repair/patrolTask'
                menuId = 24
            } else if (item.remindType == 4) {
                if (item.warningType == 1) {// 配电监控
                    menuId = 9
                    query.id = item.skip;
                    const type = item.skip.substr(-1)
                    if (type == 'A') {
                        path = '/sysMap10kv'
                    } else if (type == 'B') {
                        path = '/sysMap400v'
                    } else if (type == 'C') {
                        path = '/sysMapdCscreen'
                    } else if (type == 'D') {
                        path = '/monitoring/transformer'
                    }
                } else if (item.warningType == 2) {
                    path = '/elecGenerate'
                    menuId = 11
                } else if (item.warningType == 3) {
                    path = '/elecManagement'
                    menuId = 12
                } else if (item.warningType == 4) {
                    path = '/fireControl'
                    menuId = 10
                } else if (item.warningType == 5) {
                    path = '/elecCharge'
                    menuId = 13
                }
            }
            if (path) {
                this.$bus.$emit('goToTheMenu', menuId)
                this.$router.push({
                    path,
                    query
                })
                setTimeout(() => {
                    this.$parent.getCount();
                    this.$bus.$emit('changeNode', item.skip.substring(0, item.skip.length -2))
                }, 550)
            }
            this.drawer = false;

        },

        // 抽屉关闭后的回调
        drawerClosed () {
            this.allMessage = []
            this.ifNoData = false
            this.tab1 = 'tabGreen';
            this.tab2 = 'tabGrey';
            this.tab3 = 'tabGrey';
            this.tab4 = 'tabGrey';
            this.tab5 = 'tabGrey';
            this.showPage = "serious"
            this.currentPage = 1
            this.msgType = 1
            this.allMessage = []
            // 刷新父页面的未读消息数
            this.$parent.getGroupCount()
            // 刷新父页面的未读消息总数
            this.$parent.getCount()
        }
    },
    destroyed() {
      this.drawerClosed()
    },

}
</script>

<style lang="less" scoped>
.DrawerForMessage {
    background-color: #0c375d;
    .topPlace {
        height: 5.5rem;
        width: 50.4rem;
        margin: 0 3.2rem 1.3rem 3.2rem;
        border-bottom: 0.2rem solid #0b3e6b;
        .tabGreen {
            height: 2.2rem;
            margin-top: 2.5rem;
            padding-bottom: 0.7rem;
            border-bottom: 0.2rem solid #03d9bc;
            display: inline-block;
            margin-right: 2.4rem;
            font-size: 1.4rem;
            color: #03d9bc;
            line-height: 2.2rem;
            cursor: pointer;
        }
        .tabGrey {
            height: 2.2rem;
            margin-top: 2.5rem;
            margin-bottom: 0.9rem;
            display: inline-block;
            font-size: 1.4rem;
            color: rgba(255, 255, 255, 0.85);
            cursor: pointer;
            line-height: 2.2rem;
            margin-right: 2.4rem;
        }

        .tab3 {
            height: 2.2rem;
            margin-top: 2.5rem;
            margin-bottom: 0.9rem;
            // display: inline-block;
            font-size: 1.4rem;
            color: #37a5dd;
            line-height: 2.2rem;
            float: right;
            cursor: pointer;
            font-weight: 900;
        }
    }

    .card {
        display: flex;
        align-items: center;
        width: 50.4rem;
        height: 8.5rem;
        padding: 0 2.4rem;
        margin: 2rem 3.2rem 0 3.2rem;
        background-color: #0a4070;
        border-radius: 0.4rem;
        cursor: pointer;
        &:hover {
            background-color: #084c8b;
        }

        .lightRed {
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-color: #ff6565;
        }
        .lightGrey {
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-color: #6082a2;
        }

        .content {
            margin-left: 1.6rem;
        }

        .word1 {
            margin-bottom: 0.5rem;
            height: 2rem;
            font-size: 1.4rem;
            line-height: 2rem;
            color: rgba(255, 255, 255, 0.85);
            font-weight: 100;
            width: 43rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .word2 {
            height: 2rem;
            font-size: 1.2rem;
            line-height: 2rem;
            color: rgba(255, 255, 255, 0.45);
            font-weight: 100;
            width: 43rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .pagination {
        margin-top: 3rem;
        float: right;
        position: absolute;
        top: 82.5rem;
        right: 2rem;
    }

    .imgNoData {
        position: absolute;
        top: 42rem;
        right: 24.4rem;
    }
}

.cardMove {
    width: 50.4rem;
    height: 8.5rem;
    margin: 2rem 3.2rem 0 70rem;
    background-color: #0a4070;
    border-radius: 0.4rem;
    transition: all 0.5s;
    cursor: pointer;
    position: relative;
    top: 0;
    left: 0;
    &:hover {
        background-color: #084c8b;
    }
    .lightRed {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        position: absolute;
        top: 2.8rem;
        left: 1.9rem;
        background-color: #ff6565;
    }
    .lightGrey {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        position: absolute;
        top: 2.8rem;
        left: 1.9rem;
        background-color: #6082a2;
    }

    .word1 {
        height: 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: rgba(255, 255, 255, 0.85);
        position: absolute;
        top: 2.3rem;
        left: 4.2rem;
        font-weight: 100;
    }

    .word2 {
        height: 2rem;
        font-size: 1.4rem;
        line-height: 2rem;
        color: rgba(255, 255, 255, 0.45);
        position: absolute;
        top: 4.9rem;
        left: 4.2rem;
        font-weight: 100;
    }
}
</style>