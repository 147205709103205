import Vue from "vue";
import VueRouter from "vue-router";
import { getCookie } from "../utils/util.js";
// import util from "../utils/util"
// 解决重复push同一个路由报错的问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

// 引入路由组件
// 设备管理（1）############################################################################################
const Equipment = (r) => require.ensure([], () => r(require("@/pages/Equipment")), "chunkname1");
const EquipDistribution = (r) =>
    require.ensure([], () => r(require("@/pages/Equipment/EquipDistribution")), "chunkname1");
const EquipGenerate = (r) => require.ensure([], () => r(require("@/pages/Equipment/EquipGenerate")), "chunkname1");
const EquipMeters = (r) => require.ensure([], () => r(require("@/pages/Equipment/EquipMeters")), "chunkname1");
const powerManagement = (r) => require.ensure([], () => r(require("@/pages/Equipment/powerManagement")), "chunkname1");
// 系统管理（1）############################################################################################
const System = (r) => require.ensure([], () => r(require("@/pages/System")), "chunkname1");
const User = (r) => require.ensure([], () => r(require("@/pages/System/components/User")), "chunkname1");
const Department = (r) => require.ensure([], () => r(require("@/pages/System/components/Department")), "chunkname1");
const Character = (r) => require.ensure([], () => r(require("@/pages/System/components/Character")), "chunkname1");
const Dictionary = (r) => require.ensure([], () => r(require("@/pages/System/components/Dictionary")), "chunkname1");
const Position = (r) => require.ensure([], () => r(require("@/pages/System/components/Position")), "chunkname1");
const Communication = (r) =>
    require.ensure([], () => r(require("@/pages/System/components/Communication")), "chunkname1");
const powerPosition = (r) =>
    require.ensure([], () => r(require("@/pages/System/components/powerPosition")), "chunkname1");
const log = (r) =>
    require.ensure([], () => r(require("@/pages/System/components/log")), "chunkname1");
// 告警管理(2)
const Alarm = (r) => require.ensure([], () => r(require("@/pages/System/components/Alarm")), "chunkname1");
const AlarmManagement = (r) =>
    require.ensure([], () => r(require("@/pages/System/components/AlarmManagement")), "chunkname1");
const AlarmSetting = (r) =>
    require.ensure([], () => r(require("@/pages/System/components/AlarmSetting")), "chunkname1");
const AlarmGroup = (r) => require.ensure([], () => r(require("@/pages/System/components/AlarmGroup")), "chunkname1");

// 客户管理（1）############################################################################################
const Customer = (r) => require.ensure([], () => r(require("@/pages/Customer")), "chunkname1");
// 系统绘制（1）############################################################################################
const Mapping = (r) => require.ensure([], () => r(require("@/pages/Mapping")), "chunkname1");
const DiagramEdit = (r) => require.ensure([], () => r(require("@/pages/Mapping/components/DiagramEdit")), "chunkname1");
const main = (r) => require.ensure([], () => r(require("@/pages/Mapping/components/main1")), "chunkname1");
const group = (r) => require.ensure([], () => r(require("@/pages/Mapping/components/group1")), "chunkname1");
const component = (r) => require.ensure([], () => r(require("@/pages/Mapping/components/component1")), "chunkname1");
const templates = (r) => require.ensure([], () => r(require("@/pages/Mapping/components/templates1")), "chunkname1");
//const component = (r) => require.ensure([], () => r(require("@/pages/Mapping/DiagramItem")), "chunkname1");
//const group = (r) => require.ensure([], () => r(require("@/pages/Mapping/DiagramItemGroup")), "chunkname1");
//const main = (r) => require.ensure([], () => r(require("@/pages/Mapping/Diagram")), "chunkname1");
//const templates = (r) => require.ensure([], () => r(require("@/pages/Mapping/DiagramTemplate")), "chunkname1");

// 全景展示（1）############################################################################################
const FullView = (r) => require.ensure([], () => r(require("@/pages/FullView")), "chunkname1");

// 配电监控（1）############################################################################################
const Monitoring = (r) => require.ensure([], () => r(require("@/pages/Monitoring")), "chunkname1");
const DCscreen = (r) => require.ensure([], () => r(require("@/pages/Monitoring/components/DCscreen")), "chunkname1");
const Transformer = (r) =>
    require.ensure([], () => r(require("@/pages/Monitoring/components/Transformer")), "chunkname1");
const SysMap10kv = (r) =>
    require.ensure([], () => r(require("@/pages/Monitoring/components/SysMap10kv")), "chunkname1");
const SysMap400v = (r) =>
    require.ensure([], () => r(require("@/pages/Monitoring/components/SysMap400v")), "chunkname1");
// const SysMapdCscreen = (r) =>
// require.ensure([], () => r(require("@/pages/Monitoring/components/SysMapdCscreen")), "chunkname1");
const BlankPage = (r) => require.ensure([], () => r(require("@/pages/Monitoring/components/BlankPage")), "chunkname1");
const Report = (r) => require.ensure([], () => r(require("@/components/Report")), "chunkname1");
// 运维检修（1）############################################################################################
const Repair = (r) => require.ensure([], () => r(require("@/pages/Repair")), "chunkname1");
// 工单管理(2)
const OrderManagement = (r) => require.ensure([], () => r(require("@/pages/Repair/OrderManagement")), "chunkname1");
const OrderWaitForDistribution = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderWaitForDistribution")), "chunkname1");
const OrderOutOfTime = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderOutOfTime")), "chunkname1");
const OrderInHand = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderInHand")), "chunkname1");
const OrderFinished = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderFinished")), "chunkname1");
const OrderPause = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderPause")), "chunkname1");
const OrderAll = (r) => require.ensure([], () => r(require("@/pages/Repair/OrderManagement/OrderAll")), "chunkname1");

// // 我的工单(2)
const OrderManagementMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagementMine")), "chunkname1");
const OrderOutOfTimeMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagementMine/OrderOutOfTimeMine")), "chunkname1");
const OrderPauseMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagementMine/OrderPauseMine")), "chunkname1");
const OrderInHandMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagementMine/OrderInHandMine")), "chunkname1");
const OrderFinishedMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/OrderManagementMine/OrderFinishedMine")), "chunkname1");

const PatrolPlan = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolPlan")), "chunkname1");
// 巡检任务(2)
const PatrolTask = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask")), "chunkname1");
const WaitForPatrol = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolTask/WaitForPatrol")), "chunkname1");
const OutOfTime = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask/OutOfTime")), "chunkname1");
const OnPatrol = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask/OnPatrol")), "chunkname1");
const Finished = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask/Finished")), "chunkname1");
const Banned = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask/Banned")), "chunkname1");
const All = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTask/All")), "chunkname1");
// 我的巡检(2)
const PatrolTaskMine = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolTaskMine")), "chunkname1");
const WaitForPatrolMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolTaskMine/WaitForPatrolMine")), "chunkname1");
const OutOfTimeMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolTaskMine/OutOfTimeMine")), "chunkname1");
const OnPatrolMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolTaskMine/OnPatrolMine")), "chunkname1");
const FinishedMine = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolTaskMine/FinishedMine")), "chunkname1");

// 工单设置(2)
const OrderSetting = (r) => require.ensure([], () => r(require("@/pages/Repair/OrderSetting")), "chunkname1");

// 巡检设置(2)
const PatrolSetting = (r) => require.ensure([], () => r(require("@/pages/Repair/PatrolSetting")), "chunkname1");
const TimeSetting = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolSetting/TimeSetting")), "chunkname1");
const WorkStandard = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolSetting/WorkStandard")), "chunkname1");
const PatrolGroupSetting = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolSetting/PatrolGroupSetting")), "chunkname1");
const TypeSetting = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/PatrolSetting/TypeSetting")), "chunkname1");
// 运维统计(2)
const Statistics = (r) => require.ensure([], () => r(require("@/pages/Repair/Statistics")), "chunkname1");
const Situation = (r) => require.ensure([], () => r(require("@/pages/Repair/Statistics/Situation")), "chunkname1");
const Engineer = (r) => require.ensure([], () => r(require("@/pages/Repair/Statistics/Engineer")), "chunkname1");
const Restime = (r) => require.ensure([], () => r(require("@/pages/Repair/Statistics/Restime")), "chunkname1");
const PerformanceS = (r) =>
    require.ensure([], () => r(require("@/pages/Repair/Statistics/PerformanceS")), "chunkname1");

// 统计报表（已删除）
const StatisticsReport = (r) => require.ensure([], () => r(require("@/pages/StatisticsReport")), "chunkname1");
// 工作台（1）############################################################################################
const WorkBench = (r) => require.ensure([], () => r(require("@/pages/WorkBench")), "chunkname1");
const WorkBench1 = (r) => require.ensure([], () => r(require("@/pages/WorkBench/NomalBench")), "chunkname1");
const WorkBench2 = (r) => require.ensure([], () => r(require("@/pages/WorkBench/ManagerBench")), "chunkname1");
const WorkBench3 = (r) => require.ensure([], () => r(require("@/pages/WorkBench/EngineerBench")), "chunkname1");

const FireControl = (r) => require.ensure([], () => r(require("@/pages/FireControl")), "chunkname1");
const FireMain = (r) => require.ensure([], () => r(require("@/pages/FireControl/components/MainPage")), "chunkname1");

// 发电管理（1）############################################################################################
const ElecGenerate = (r) => require.ensure([], () => r(require("@/pages/ElecGenerate")), "chunkname1");
//  告警
const AlarmWaring = (r) => require.ensure([], () => r(require("@/pages/ElecGenerate/AlarmWaring")), "chunkname1");
const MyWaring = (r) => require.ensure([], () => r(require("@/pages/ElecGenerate/alarmWaring/myWaring")), "chunkname1");
const CurrentWaring = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/alarmWaring/currentWaring")), "chunkname1");

// 发电监控（2）
const MonitoringGenerate = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/MonitoringGenerate")), "chunkname1");
const MonEnerState = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/MonitoringGenerate/monEnerState")), "chunkname1");
const MonVerterState = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/MonitoringGenerate/monVerterState")), "chunkname1");
const OverViewGenerate = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/OverViewGenerate")), "chunkname1");
const RankAnalyzeGenerate = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/RankAnalyzeGenerate")), "chunkname1");
const ReportStatistical = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/ReportStatistical")), "chunkname1");
const GeneralPower = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/reportStatistical/generalPower")), "chunkname1");
const Inverter = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/reportStatistical/inverter")), "chunkname1");
const Storage = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/reportStatistical/storage")), "chunkname1");
const SettingGenerate = (r) =>
    require.ensure([], () => r(require("@/pages/ElecGenerate/SettingGenerate")), "chunkname1");

// 能源管理（1）############################################################################################
const ElecManagement = (r) => require.ensure([], () => r(require("@/pages/ElecManagement")), "chunkname1");
const EnergySituation = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/EnergySituation")), "chunkname1");
// 能耗统计（2）
const EnergyStatistical = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/EnergyStatistical")), "chunkname1");
const EnergyMap = (r) => require.ensure([], () => r(require("@/pages/ElecManagement/EnergyMap")), "chunkname1");
const AreaElectric = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/EnergyStatistical/AreaElectric")), "chunkname1");
const StepElectric = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/EnergyStatistical/StepElectric")), "chunkname1");
const TimeAnalyze = (r) => require.ensure([], () => r(require("@/pages/ElecManagement/TimeAnalyze")), "chunkname1");
const ComparisonAnalyze = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/ComparisonAnalyze")), "chunkname1");
const RankAnalyze = (r) => require.ensure([], () => r(require("@/pages/ElecManagement/RankAnalyze")), "chunkname1");
const EnergySetting = (r) => require.ensure([], () => r(require("@/pages/ElecManagement/EnergySetting")), "chunkname1");
const StatisticalReport = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/StatisticalReport")), "chunkname1");
const RegionEnergy = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/StatisticalReport/regionEnergy")), "chunkname1");
const SubitemEnergy = (r) =>
    require.ensure([], () => r(require("@/pages/ElecManagement/StatisticalReport/subitemEnergy")), "chunkname1");
const PowerDistributionEnergy = (r) =>
    require.ensure(
        [],
        () => r(require("@/pages/ElecManagement/StatisticalReport/powerDistributionEnergy")),
        "chunkname1"
    );

// 充电运营（1）##############################################################################################
const ElecCharge = (r) => require.ensure([], () => r(require("@/pages/ElecCharge")), "chunkname1");
const ChargeAll = (r) => require.ensure([], () => r(require("@/pages/ElecCharge/ChargeAll")), "chunkname1");
const ChargeMonitoring = (r) =>
    require.ensure([], () => r(require("@/pages/ElecCharge/ChargeMonitoring")), "chunkname1");
const ChargeStatistics = (r) =>
    require.ensure([], () => r(require("@/pages/ElecCharge/ChargeStatistics")), "chunkname1");
const EquipmentStatistics = (r) =>
    require.ensure([], () => r(require("@/pages/ElecCharge/EquipmentStatistics")), "chunkname1");
const OrderStatistics = (r) => require.ensure([], () => r(require("@/pages/ElecCharge/OrderStatistics")), "chunkname1");

const Login = (r) => require.ensure([], () => r(require("@/pages/Login")), "chunkname1");

const router = new VueRouter({
    routes: [
        // 设备管理#########################################################################
        {
            path: "/equipment",
            component: Equipment,
            children: [
                {
                    path: "equipDistribution",
                    component: EquipDistribution,
                },
                {
                    path: "equipGenerate",
                    component: EquipGenerate,
                },
                {
                    path: "equipMeters",
                    component: EquipMeters,
                },
                {
                    path: "powerManagement",
                    component: powerManagement,
                },
                {
                    path: "/",
                    component: EquipDistribution,
                },
            ],
        },
        // 系统管理#######################################################################
        {
            path: "/system",
            component: System,
            children: [
                {
                    path: "user",
                    component: User,
                },
                {
                    path: "department",
                    component: Department,
                },
                {
                    path: "character",
                    component: Character,
                },
                {
                    path: "dictionary",
                    component: Dictionary,
                },
                {
                    path: "position",
                    component: Position,
                },
                {
                    path: "powerPosition",
                    component: powerPosition,
                },
                {
                    path: "communication",
                    component: Communication,
                },
                {
                    path: "alarm",
                    component: Alarm,
                    children: [
                        {
                            path: "alarmSetting",
                            component: AlarmSetting,
                        },
                        {
                            path: "alarmManagement",
                            component: AlarmManagement,
                        },
                        {
                            path: "alarmGroup",
                            component: AlarmGroup,
                        },
                        {
                            path: "/",
                            component: AlarmManagement,
                        },
                    ],
                },
                {
                    path: "log",
                    component: log,
                },
                {
                    path: "/",
                    component: User,
                },
            ],
        },

        {
            path: "/customer",
            component: Customer,
        },
        {
            path: "/fullView",
            component: FullView,
        },
        // 全景展示##############################################
        {
            path: "/mapping",
            component: Mapping,
            children: [
                {
                    path: "main",
                    component: main,
                },
                {
                    path: "diagram-edit",
                    component: DiagramEdit,
                },
                {
                    path: "group",
                    component: group,
                },
                {
                    path: "component",
                    component: component,
                },
                {
                    path: "templates",
                    component: templates,
                },
                {
                    path: "/",
                    component: main,
                },
            ],
        },

        // 配电监控######################################################################
        {
            path: "/monitoring",
            component: Monitoring,
            children: [
                // 直流屏和400V接线图合在一起了
                // {
                //   path: "dCscreen",
                //   component: DCscreen,
                // },
                {
                    path: "transformer",
                    component: Transformer,
                },
                {
                    path: "/sysMap10kv",
                    component: SysMap10kv,
                },
                {
                    path: "/sysMap400v",
                    component: SysMap400v,
                },
                {
                    path: "/sysMapdCscreen",
                    component: SysMap400v,
                },
                {
                    path: "blankPage",
                    component: BlankPage,
                },
                {
                    path: "/",
                    component: SysMap10kv,
                },
                {
                    path: "report",
                    component: Report,
                }
            ],
        },

        // 运维检修######################################################################
        {
            path: "/repair",
            component: Repair,
            children: [
                // 工单管理（2级）和三级路由们
                {
                    path: "orderManagement",
                    component: OrderManagement,
                    children: [
                        {
                            path: "orderWaitForDistribution",
                            component: OrderWaitForDistribution,
                        },
                        {
                            path: "orderOutOfTime",
                            component: OrderOutOfTime,
                        },
                        {
                            path: "orderInHand",
                            component: OrderInHand,
                        },
                        {
                            path: "orderFinished",
                            component: OrderFinished,
                        },
                        {
                            path: "orderPause",
                            component: OrderPause,
                        },
                        {
                            path: "orderAll",
                            component: OrderAll,
                        },
                        {
                            path: "/",
                            component: OrderWaitForDistribution,
                        },
                    ],
                },
                // 我的工单（2级）和三级路由们
                {
                    path: "orderManagementMine",
                    component: OrderManagementMine,
                    children: [
                        {
                            path: "orderOutOfTimeMine",
                            component: OrderOutOfTimeMine,
                        },
                        {
                            path: "orderInHandMine",
                            component: OrderInHandMine,
                        },
                        {
                            path: "orderFinishedMine",
                            component: OrderFinishedMine,
                        },
                        {
                            path: "orderPauseMine",
                            component: OrderPauseMine,
                        },
                        {
                            path: "/",
                            component: OrderOutOfTimeMine,
                        },
                    ],
                },
                {
                    path: "patrolPlan",
                    component: PatrolPlan,
                },
                // 巡检任务（2级）和3级路由们
                {
                    path: "patrolTask",
                    component: PatrolTask,
                    children: [
                        {
                            path: "waitForPatrol",
                            component: WaitForPatrol,
                        },
                        {
                            path: "outOfTime",
                            component: OutOfTime,
                        },
                        {
                            path: "onPatrol",
                            component: OnPatrol,
                        },
                        {
                            path: "finished",
                            component: Finished,
                        },
                        {
                            path: "banned",
                            component: Banned,
                        },
                        {
                            path: "all",
                            component: All,
                        },
                        {
                            path: "/",
                            component: WaitForPatrol,
                        },
                    ],
                },
                // 我的巡检（2级）和3级路由们
                {
                    path: "patrolTaskMine",
                    component: PatrolTaskMine,
                    children: [
                        {
                            path: "waitForPatrolMine",
                            component: WaitForPatrolMine,
                        },
                        {
                            path: "outOfTimeMine",
                            component: OutOfTimeMine,
                        },
                        {
                            path: "onPatrolMine",
                            component: OnPatrolMine,
                        },
                        {
                            path: "finishedMine",
                            component: FinishedMine,
                        },

                        {
                            path: "/",
                            component: WaitForPatrolMine,
                        },
                    ],
                },
                // 工单设置（2级）和三级路由们
                {
                    path: "orderSetting",
                    component: OrderSetting,
                },
                // 巡检设置（2级）和3级路由们
                {
                    path: "patrolSetting",
                    component: PatrolSetting,
                    children: [
                        {
                            path: "timeSetting",
                            component: TimeSetting,
                        },
                        {
                            path: "workStandard",
                            component: WorkStandard,
                        },
                        {
                            path: "patrolGroupSetting",
                            component: PatrolGroupSetting,
                        },
                        {
                            path: "typeSetting",
                            component: TypeSetting,
                        },
                        {
                            path: "/",
                            component: TimeSetting,
                        },
                    ],
                },
                // 运维统计(2级) 和3级路由们
                {
                    path: "statistics",
                    component: Statistics,
                    children: [
                        {
                            path: "situation",
                            component: Situation,
                        },
                        {
                            path: "engineer",
                            component: Engineer,
                        },
                        {
                            path: "restime",
                            component: Restime,
                        },
                        {
                            path: "performanceS",
                            component: PerformanceS,
                        },
                        {
                            path: "/",
                            component: Situation,
                        },
                    ],
                },
                {
                    path: "/",
                    component: PatrolPlan,
                },
            ],
        },

        {
            path: "/statisticsReport",
            component: StatisticsReport,
        },
        {
            path: "/fireControl",
            component: FireControl,
            children: [
                {
                    path: "/",
                    component: FireMain,
                },
                {
                    path: "report",
                    component: Report,
                }
            ]
        },
        // 发电管理#############################################################################
        {
            path: "/elecGenerate",
            component: ElecGenerate,
            children: [
                {
                    path: "alarmWaring",
                    component: AlarmWaring,
                    children: [
                        {
                            path: "myWaring",
                            component: MyWaring,
                        },
                        {
                            path: "currentWaring",
                            component: CurrentWaring,
                        },
                        {
                            path: "/",
                            component: MyWaring,
                        },
                    ],
                },
                // 发电监控
                {
                    path: "monitoringGenerate",
                    component: MonitoringGenerate,
                    children: [
                        {
                            path: "monEnerState",
                            component: MonEnerState,
                        },
                        {
                            path: "monVerterState",
                            component: MonVerterState,
                        },
                        {
                            path: "/",
                            component: MonEnerState,
                        },
                    ],
                },

                {
                    path: "overViewGenerate",
                    component: OverViewGenerate,
                },
                {
                    path: "rankAnalyzeGenerate",
                    component: RankAnalyzeGenerate,
                },
                {
                    path: "reportStatistical",
                    component: ReportStatistical,
                    children: [
                        {
                            path: "generalPower",
                            component: GeneralPower,
                        },
                        {
                            path: "inverter",
                            component: Inverter,
                        },
                        {
                            path: "storage",
                            component: Storage,
                        },
                        {
                            path: "/",
                            component: GeneralPower,
                        },
                    ],
                },
                {
                    path: "settingGenerate",
                    component: SettingGenerate,
                },
                {
                    path: "/",
                    component: OverViewGenerate,
                },
            ],
        },

        // 充电运营############################################################################
        {
            path: "/elecCharge",
            component: ElecCharge,
            children: [
                {
                    path: "chargeAll",
                    component: ChargeAll,
                },
                {
                    path: "chargeMonitoring",
                    component: ChargeMonitoring,
                },
                {
                    path: "chargeStatistics",
                    component: ChargeStatistics,
                },
                {
                    path: "equipmentStatistics",
                    component: EquipmentStatistics,
                },
                {
                    path: "orderStatistics",
                    component: OrderStatistics,
                },
                {
                    path: "/",
                    component: ChargeAll,
                },
            ],
        },

        // 能源管理#############################################################################
        {
            path: "/elecManagement",
            component: ElecManagement,
            children: [
                {
                    path: "energySituation",
                    component: EnergySituation,
                },
                {
                    path: "energyStatistical",
                    component: EnergyStatistical,
                    children: [
                        {
                            path: "areaElectric",
                            component: AreaElectric,
                        },
                        {
                            path: "stepElectric",
                            component: StepElectric,
                        },
                        {
                            path: "/",
                            component: AreaElectric,
                        },
                    ],
                },
                {
                    path: "energyMap",
                    component: EnergyMap,
                },
                {
                    path: "timeAnalyze",
                    component: TimeAnalyze,
                },
                {
                    path: "comparisonAnalyze",
                    component: ComparisonAnalyze,
                },
                {
                    path: "rankAnalyze",
                    component: RankAnalyze,
                },
                {
                    path: "energySetting",
                    component: EnergySetting,
                },
                {
                    path: "statisticalReport",
                    component: StatisticalReport,
                    children: [
                        {
                            path: "regionEnergy",
                            component: RegionEnergy,
                        },
                        {
                            path: "subitemEnergy",
                            component: SubitemEnergy,
                        },
                        {
                            path: "powerDistributionEnergy",
                            component: PowerDistributionEnergy,
                        },
                        {
                            path: "/",
                            component: RegionEnergy,
                        },
                    ],
                },
                {
                    path: "/",
                    component: EnergySituation,
                },
            ],
        },

        // 工作台#################################################################################
        {
            path: "/workBench",
            component: WorkBench,
            // children: [
            //     {
            //         path: '/',
            //         component: WorkBench1
            //     },
            // ]
        },
        {
            path: "/WorkBench1",
            component: WorkBench1,
        },
        {
            path: "/WorkBench2",
            component: WorkBench2,
        },
        {
            path: "/WorkBench3",
            component: WorkBench3,
        },
        {
            path: "/login",
            component: Login,
        },

        // 刚加载页面跳转到的页面
        {
            path: "/",
            redirect: "/login",
        },
    ],
});



// 前置路由守卫
router.beforeEach((to, from, next) => {




    // 这里只做没有token的拦截，打回login页面
    const token = getCookie("token");

    if (to.path === "/login") {
        next();
    }
    else if (to.path === '/elecGenerate') {
        next();
    }
    else if (token) {
        next();
    } else {
        Vue.prototype.$message.error("请先登录！");
        next("./login");
    }



});

export default router;
