export const tableBg = {
  data() {
    return {
      headerClass: {
        background: "#133f68",
        color: "#fff",
        fontSize: "1.4rem",
      },
      headerClass1: {
        background: "#133f68",
        color: "#fff",
        fontSize: "1.2rem",
      },
      headerClass2: {
        background: "#123e67",
        color: "#fff",
        fontSize: "1.2rem",
        height: "3.1rem",
        lineHeight: "3.1rem",
      },
    };
  },
  methods: {
    cellStyle() {
      return "background-color:#0C375E;\
      color:#dbe0e5;\
      font-family:PingFang SC;\
      font-size:1.4rem;\
      line-height:5.2rem;\
      height:5.2rem;\
      ";
    },
    cellStyle1() {
      return "color:rgba(255,255,255,0.85);\
      font-family:PingFang SC;\
      font-size:1.4rem;\
      line-height:3.15rem;\
      height:3.15rem;\
      ";
    },
  },
};
