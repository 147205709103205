import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import * as echarts from 'echarts';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/css/common.less';
import './assets/css/public.css';
import { setCookie, getCookie, delCookie } from './utils/util.js';
import 'babel-polyfill';
import 'event-source-polyfill';
import dialogDrag from './utils/drag';


import Print from './mixin/print';
Vue.use(Print)

Vue.config.productionTip = false;
Vue.use(ElementUI);

// 弹层拖拽
Vue.directive('dialogDrag', dialogDrag);

Vue.prototype.$echarts = echarts

new Vue({
    render: h => h(App),
    router,
    store,
    // 这里挂载全局事件总线
    beforeCreate() {
        Vue.prototype.$bus = this
    },
    // 在这里挂载cookie的三个方法
    beforeMount() {
        Vue.prototype.$cookieStore = {
            setCookie,
            getCookie,
            delCookie
        }
    }
}).$mount('#app')


// 使用rem以及屏幕分辨率自适应  分为四档：3840/2560/1920/1366 以1080P分辨率为基础
const setFontSize = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
    htmlDom.style.fontSize = `${htmlWidth / 192}px`
};
window.onresize = setFontSize;
setFontSize();