const state = {
    multiData: [],
    roomData: [],
    customerId: "",
    customerData: {},
    localtionId: "",
    buildId: "",
    customerPicture: "",
    buildingName: ""
};
const getters = {
    multiData: state => {
        return state.multiData;
    },
    roomData: state => {
        return state.roomData;
    },
    customerId: state => {
        return state.customerId;
    },
    customerData: state => {
        return state.customerData;
    },
    localtionId: state => {
        return state.localtionId;
    },
    buildId: state => {
        return state.buildId;
    },
};
const mutations = {
    setMultiData(state, multiData) {
        state.multiData = multiData;
    },
    setRoomData(state, roomData) {
        state.roomData = roomData;
    },
    setCustomerId(state, customerId) {
        state.customerId = customerId;
    },
    setCustomerData(state, customerData) {
        state.customerData = customerData;
    },
    setLocaltionId(state, localtionId) {
        state.localtionId = localtionId;
    },
    setBuildId(state, buildId) {
        state.buildId = buildId;
    },
    setCustomerPicture(state, customerPicture) {
        state.customerPicture = customerPicture;
    },
    setBuildingName(state, buildingName) {
        state.buildingName = buildingName;
    },
};
const actions = {
    setMultiData(context, multiData) {
        context.commit("setMultiData", multiData);
    },
    setRoomData(context, roomData) {
        context.commit("setRoomData", roomData);
    },
    setCustomerId(context, customerId) {
        context.commit("setCustomerId", customerId);
    },
    setCustomerData(context, customerData) {
        context.commit("setCustomerData", customerData);
    },
    setLocaltionId(context, localtionId) {
        context.commit("setLocaltionId", localtionId);
    },
    setBuildId(context, buildId) {
        context.commit("setBuildId", buildId);
    },
    setCustomerPicture(context, customerPicture) {
        context.commit("setCustomerPicture", customerPicture);
    },
    setBuildingName(context, buildingName) {
        context.commit("setBuildingName", buildingName);
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};