import request from '../../utils/request.js'
import config from '../../config/index.js'
// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中

const state = {
  equipmentPageData: {},
  ifSuccess: {},
  ifDeleted: {},
  ifExport: {},
  customerTree: {},
  customerTreeAllNode: {},
  equipmentOwnData: {},
  ifSuccessEdit: {},
  ownRoomData: {},
  equipmentBuildings: {},
  equipmentFloor: {},
  equipmentDialogTree: {},
  dialogTreeChoosenDataForMyFather: { data: '', time: 0 },
  customerTreePeiDian: {},
  formDetail: {},
  customerTreeFaDian: {},
  getInverters: {},
  getPowerStations: {},
  getPowerStationsAll: {},
  repairRecords: {},
  preventabilityRecords: {},
}
const mutations = {
  GETEQUIPMENTPAGEDATA(state, equipmentPageData) {
    state.equipmentPageData = equipmentPageData
  },
  POSTDIALOGDATA(state, ifSuccess) {
    state.ifSuccess = ifSuccess
  },
  DELETEITEMINTABLE(state, ifDeleted) {
    state.ifDeleted = ifDeleted
  },
  GETEXPORTFILE(state, ifExport) {
    state.ifExport = ifExport
  },
  ExportGeneratorFile(state, ifExport) {
    state.ifExport = ifExport
  },
  ExportMeterFile(state, ifExport) {
    state.ifExport = ifExport
  },
  GETCUSTOMERTREE(state, customerTree) {
    state.customerTree = customerTree
  },
  GETCUSTOMERTREEALLNODE(state, customerTreeAllNode) {
    state.customerTreeAllNode = customerTreeAllNode
  },
  EQUIPMENTOWNDATA(state, equipmentOwnData) {
    state.equipmentOwnData = equipmentOwnData
  },
  PUTDIALOGDATA(state, ifSuccessEdit) {
    state.ifSuccessEdit = ifSuccessEdit
  },
  GETOWNROOMDATA(state, ownRoomData) {
    state.ownRoomData = ownRoomData
  },
  GETEQUIPMENTBUILDINGS(state, equipmentBuildings) {
    state.equipmentBuildings = equipmentBuildings
  },
  GETEQUIPMENTFLOOR(state, equipmentFloor) {
    state.equipmentFloor = equipmentFloor
  },
  GETEQUIPMENTDIALOGTREE(state, equipmentDialogTree) {
    state.equipmentDialogTree = equipmentDialogTree
  },
  GETCUSTOMERTREEPEIDIAN(state, customerTreePeiDian) {
    state.customerTreePeiDian = customerTreePeiDian
  },
  GETEQUIPMENTFORMDETAIL(state, formDetail) {
    state.formDetail = formDetail
  },
  GETCUSTOMERTREEFADIAN(state, customerTreeFaDian) {
    state.customerTreeFaDian = customerTreeFaDian
  },
  GETINVERTERS(state, getInverters) {
    state.getInverters = getInverters
  },
  GETPOWERSTATIONS(state, getPowerStations) {
    state.getPowerStations = getPowerStations
  },
  GETPOWERSTATIONSALL(state, getPowerStationsAll) {
    state.getPowerStationsAll = getPowerStationsAll
  },
  GETEQUIPMENTMOREREPAIRRECORDS(state, repairRecords) {
    state.repairRecords = repairRecords
  },
  GETEQUIPMENTPREVENTABILITYREPAIRRECORDS(state, preventabilityRecords) {
    state.preventabilityRecords = preventabilityRecords
  },
}
const actions = {
  // 获取设备管理初始页面(参数val={page:当前页码,numPerPage:每页项目数量})
  async getEquipmentPageData({ commit }, val) {
    const equipmentPageData = await request.get(
      `/device/page/${val.page}/${val.numPerPage}?keyword=${val.search}&ownerId=${val.ownerId}`
    )
    commit('GETEQUIPMENTPAGEDATA', equipmentPageData)
  },

  // 提交表单，增加登记项(val为请求体参数data,返回值ifSuccess仅用于提示添加是否成功)
  async postDialogData({ commit }, val) {
    const ifSuccess = await request.post(`/device`, val)
    commit('POSTDIALOGDATA', ifSuccess)
  },

  // 删除表单中的某一项（参数为ID）
  async deleteItemInTable({ commit }, val) {
    const ifDeleted = await request.delete(`/device/${val}`)
    commit('DELETEITEMINTABLE', ifDeleted)
  },

  // 导出表单（参数val={keyword:搜索内容,onwerId:客户ID}）
  async getExportFile({ commit }, val) {
    commit('GETEXPORTFILE', 1)
    window.location.href =
      config.baseURL +
      `device/export?keyword=${val.keyword}&ownerId=${val.ownerId}`
  },

  // 导出发电设备表单（参数val={keyword:搜索内容,onwerId:客户ID}）
  async exportGeneratorFile({ commit }, val) {
    commit('ExportGeneratorFile', 1)
    window.location.href =
      config.baseURL +
      `generator/export?keyword=${val.keyword}&ownerId=${val.ownerId}&type=${val.type}`
  },

  // 导出仪表管理表单（参数val={keyword:搜索内容,onwerId:客户ID}）
  async exportMeterFile({ commit }, val) {
    commit('ExportMeterFile', 1)
    window.location.href =
      config.baseURL +
      `energy/meter/export?keyword=${val.keyword}&ownerId=${val.ownerId}&type=${val.type}`
  },

  // 导出配电用能（参数val={keyword:搜索内容,onwerId:客户ID}）
  async exportMeterPowerFile({ commit }, val) {
    commit('ExportMeterFile', 1)
    window.location.href =
      config.baseURL +
      `pedian/meter/export?keyword=${val.keyword}&ownerId=${val.ownerId}`
    console.log(
      'href',
      config.baseURL +
        `pedian/meter/export?keyword=${val.keyword}&ownerId=${val.ownerId}`
    )
  },

  // 发送请求，获取客户树
  async getCustomerTree({ commit }) {
    const customerTree = await request.get(`/tree/customer`)
    commit('GETCUSTOMERTREE', customerTree)
  },

  // 发送请求，获取客户树
  async getCustomerTreeByCustomerId({ commit }, val) {
    const customerTreeAllNode = await request.get(`/tree/locationAllChildByCustomId/${val}`)
    commit('GETCUSTOMERTREEALLNODE', customerTreeAllNode)
  },

  // 发送请求，获取配电树
  async getCustomerTreePeiDian({ commit }) {
    const customerTreePeiDian = await request.get(`/tree/customer/peidian`)
    commit('GETCUSTOMERTREEPEIDIAN', customerTreePeiDian)
  },

  // 获取发电树
  async getCustomerTreeFaDian({ commit }) {
    const customerTreeFaDian = await request.get(`/tree/customer/fadian`)
    commit('GETCUSTOMERTREEFADIAN', customerTreeFaDian)
  },

  // 发送请求，获取单个设备的详细数据val为设备的id
  async getEquipmentOwnData({ commit }, val) {
    const equipmentOwnData = await request.get(`/device/${val}`)
    commit('EQUIPMENTOWNDATA', equipmentOwnData)
  },

  // 编辑设备信息
  async putDialogData({ commit }, val) {
    const ifSuccessEdit = await request.put(`/device`, val)
    commit('PUTDIALOGDATA', ifSuccessEdit)
  },

  // 下载excel模板
  async getDownloadTemplate({ commit }, val) {
    commit('GETDOWNLOADTEMPLATE', 1)
    if (val === 'Equipment') {
      window.location.href = config.baseURL + `device/import/template`
    } else if (val.name === 'Customer') {
      window.location.href =
        config.baseURL + `customer/import/template?type=${val.type}`
    } else if (val.name === 'meter') {
      window.location.href =
        config.baseURL + `energy/meter/import/template?type=${val.type}`
    } else if (val.name === 'generator') {
      window.location.href =
        config.baseURL + `generator/import/template?type=${val.type}`
    } else if (val.name === '年发电量') {
      window.location.href =
        config.baseURL + `gen-management/curryearbar/export/${val.customerId}`
    } else if (val.name === '储能报表') {
      window.location.href =
        config.baseURL +
        `gen-management/report/battery/export/${val.customerId}/${val.datetype}/${val.date}?batteryIds=${val.batteryIds}`
    } else if (val.name === '逆变器报表') {
      window.location.href =
        config.baseURL +
        `gen-management/report/inverter/export/${val.customerId}/${val.datetype}/${val.date}?inverterIds=${val.inverterIds}`
    } else if (val.name === '电站报表') {
      window.location.href =
        config.baseURL +
        `gen-management/report/station/export/${val.customerId}/${val.tjmethod}/${val.datetype}/${val.date}?stationIds=${val.stationIds}`
    } else if (val.name === 'power') {
      window.location.href =
        config.baseURL + `pedian/meter/import/template?type=${val.type}`
    }
  },

  // 获取单个客户的配电房数据
  async getOwnRoomData({ commit }, val) {
    const ownRoomData = await request.get(`/place/${val}`)
    commit('GETOWNROOMDATA', ownRoomData)
  },

  // 进入弹层时获取大楼的列表(暂时弃用)
  async getEquipmentBuildings({ commit }, val) {
    const equipmentBuildings = await request.get(`/location/building/${val}`)
    commit('GETEQUIPMENTBUILDINGS', equipmentBuildings)
  },

  // 选择大楼时获取楼层的列表，以及选择楼层时获取房间列表（暂时弃用）
  async getEquipmentFloor({ commit }, val) {
    const equipmentFloor = await request.get(`/location/${val}/sub`)
    commit('GETEQUIPMENTFLOOR', equipmentFloor)
  },

  // 获取下属电表登记的地点
  async getEquipmentDialogTree({ commit }, val) {
    const equipmentDialogTree = await request.get(`/tree/location/${val}`)
    commit('GETEQUIPMENTDIALOGTREE', equipmentDialogTree)
  },

  // 获取单个用户的详细信息
  async getEquipmentFormDetail({ commit }, val) {
    const formDetail = await request.get(`/device/meter/${val}`)
    commit('GETEQUIPMENTFORMDETAIL', formDetail)
  },

  // 发电设备-登记弹窗：根据电站id获取逆变器列表
  async getInverters({ commit }, val) {
    const getInverters = await request.get(
      `/generator/bystation/${val.stationId}`
    )
    commit('GETINVERTERS', getInverters)
  },

  // 发电设备-登记弹窗：根据客户id获取电站列表
  async getPowerStations({ commit }, val) {
    const getPowerStations = await request.get(
      `/powerstation/${val.customerId}`
    )
    commit('GETPOWERSTATIONS', getPowerStations)
  },

  // 发电设备-登记弹窗：根据客户id获取电站列表
  async getPowerStationsAll({ commit }, val) {
    const getPowerStationsAll = await request.get(
      `/powerstation/all/${val.customerId}`
    )
    commit('GETPOWERSTATIONSALL', getPowerStationsAll)
  },

  // 配电设备-更多-全生命周期属性分页查询-检修记录
  async getEquipmentMoreRepairRecords({ commit }, val) {
    const repairRecords = await request.get(
      `/device/more/${val.pageNum}/${val.pageSize}?deviceId=${val.deviceId}`
    )
    commit('GETEQUIPMENTMOREREPAIRRECORDS', repairRecords)
  },

  // 配电设备-更多-全生命周期属性分页查询-预防性试验执行记录
  async getEquipmentpreventabilityRepairRecords({ commit }, val) {
    const preventabilityRecords = await request.get(
      `/device/preventability/${val.pageNum}/${val.pageSize}?deviceId=${val.deviceId}`
    )
    commit('GETEQUIPMENTPREVENTABILITYREPAIRRECORDS', preventabilityRecords)
  },

  // ###############################
}
const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
