import request from "../../utils/request.js";
import config from "../../config/index.js";

const state = {
  multiData: [],
  userPageData: {},
  userCustomerPageData: {},
  ifAddUserSuccess: {},
  ifDeleteUserSuccess: {},
  ifSuccessEdit: {},
  ifResetPassword: {},
  positionTree: {},
  positionPageData: {},
  departmentTree: {},
  departmentPageData: {},
  ifDepartmentAddSuccess: {},
  ifDepartmentEditSuccess: {},
  ifDepartmentDeleted: {},
  ifPositionAddSuccess: {},
  ifPositionEditSuccess: {},
  ifPositionDeleted: {},

  //#region 配电用能
  peidianTree: {},
  peidianPageData: {},
  ifPeidianAddSuccess: {},
  ifPeidianEditSuccess: {},
  ifPeidianDeleted: {},

  //#endregion
  ifQueryRoleList: {},
  ifPostAddRole: {},
  ifPostRoleEdit: {},
  ifDeleteRole: {},

  dictionaryPageData: {},
  ifDictionaryAdded: {},
  ifDictionaryEdit: {},
  ifDictionaryDeleted: {},
  ifDictionaryDepartment: {},
  dictionaryPosData: {},

  communicationPageData: {},
  ifDeleteCommunicationSuccess: {},
  ifSuccessEditPhoto: {},
  userRoleInfoData: {},
  menuList: {},

  // 告警管理
  postWarningConfig: {},
  putWarningConfig: {},
  getWarningIndexSelectList: {},
  getWarningNameSelectDtoList: {},
  getWarningConfigs: {},
  getWarningConfigById: {},
  delWarningConfigById: {},
  postWarningTeam: {},
  putWarningTeam: {},
  getWarningTeams: {},
  getWarningTeamByPage: {},
  getWarningTeamById: {},
  delWarningTeamById: {},
  putWarningTeamDetail: {},
  delWarningTeamDetail: {},
  getWarningTeamDetails: {},
  getWarningTeamDetailByPage: {},
  postWarningTeamDetail: {},
  addEquipmentConnect: {},
  delEquipmentConnect: {},
  getEquipmentLists: {},
  getEquipmentConnectList: {},
  getAllUserListPage: {},
  getWarningTeamEquipments: {},
  getWarningTeamEquipConn: {},
  addWarningTeamEquipment: {},
  delWarningTeamEquipment: {},
  getWarningInfoList: {},
  downloadWarningInfoList: {},
  operationMonitoringData: {},
  //#region 日志管理
  sysLogPageData: {}
  //#region 
};

const mutations = {
  GETUSERPAGEDATA(state, userPageData) {
    state.userPageData = userPageData;
  },
  GETUSERCUSTOMERPAGEDATA(state, userCustomerPageData) {
    state.userCustomerPageData = userCustomerPageData;
  },
  POSTSYSTEMUSERADDNEWUSER(state, ifAddUserSuccess) {
    state.ifAddUserSuccess = ifAddUserSuccess;
  },
  DELETESYSTEMUSER(state, ifDeleteUserSuccess) {
    state.ifDeleteUserSuccess = ifDeleteUserSuccess;
  },
  PUTSYSTEMUSEREDIT(state, ifSuccessEdit) {
    state.ifSuccessEdit = ifSuccessEdit;
  },
  GETSYSTEMUUSERRESETPASSWORD(state, ifResetPassword) {
    state.ifResetPassword = ifResetPassword;
  },
  GETSYSTEMPOSITIONTREE(state, positionTree) {
    state.positionTree = positionTree;
  },
  GETSYSTEMPOSITIONPAGEDATA(state, positionPageData) {
    state.positionPageData = positionPageData;
  },
  GETSYSTEMDEPARTMENTTREE(state, departmentTree) {
    state.departmentTree = departmentTree;
  },
  GETSYSTEMDEPARTMENTPAGEDATA(state, departmentPageData) {
    state.departmentPageData = departmentPageData;
  },
  POSTSYSTEMDEPARTMENTADDNEWUSER(state, ifDepartmentAddSuccess) {
    state.ifDepartmentAddSuccess = ifDepartmentAddSuccess;
  },
  POSTSYSTEMDEPARTMENTEDITNEWUSER(state, ifDepartmentEditSuccess) {
    state.ifDepartmentEditSuccess = ifDepartmentEditSuccess;
  },
  DELETESYSTEMDEPARTMENT(state, ifDepartmentDeleted) {
    state.ifDepartmentDeleted = ifDepartmentDeleted;
  },
  POSTSYSTEMPOSITIONADD(state, ifPositionAddSuccess) {
    state.ifPositionAddSuccess = ifPositionAddSuccess;
  },
  PUTSYSTEMPOSITIONEDIT(state, ifPositionEditSuccess) {
    state.ifPositionEditSuccess = ifPositionEditSuccess;
  },
  DELETESYSTEMPOSITION(state, ifPositionDeleted) {
    state.ifPositionDeleted = ifPositionDeleted;
  },
  //#region 配电用能
  GETTREEPEIDIAN(state, peidianTree) {
    state.peidianTree = peidianTree;
  },
  GETSYSTEMPEIDIANPAGEDATA(state, peidianPageData) {
    state.peidianPageData = peidianPageData;
  },
  POSTSYSTEMPEIDIANADD(state, ifPeidianAddSuccess) {
    state.ifPeidianAddSuccess = ifPeidianAddSuccess;
  },
  PUTSYSTEMPEIDIAEDIT(state, ifPeidianEditSuccess) {
    state.ifPeidianEditSuccess = ifPeidianEditSuccess;
  },
  DELETESYSTEMPEIDIAN(state, ifPeidianDeleted) {
    state.ifPeidianDeleted = ifPeidianDeleted;
  },

  //#endregion
  QUERYSYSTEMROLE(state, ifQueryRoleList) {
    state.ifQueryRoleList = ifQueryRoleList;
  },
  POSTSYSTEMADDNEWROLE(state, ifPostAddRole) {
    state.ifPostAddRole = ifPostAddRole;
  },
  POSTSYSTEMNEWROLEEdit(state, ifPostRoleEdit) {
    state.ifPostRoleEdit = ifPostRoleEdit;
  },
  POSTSYSTEMDELETEROLE(state, ifDeleteRole) {
    state.ifDeleteRole = ifDeleteRole;
  },

  GETDICTIONARYPAGEDATA(state, dictionaryPageData) {
    state.dictionaryPageData = dictionaryPageData;
  },
  GETDICTIONARYPOSDATA(state, dictionaryPosData) {
    state.dictionaryPosData = dictionaryPosData;
  },

  POSTSYSTEMDICTIONARYADDNEWUSER(state, ifDictionaryAdded) {
    state.ifDictionaryAdded = ifDictionaryAdded;
  },
  PUTSYSTEMDICTIONARYEDIT(state, ifDictionaryEdit) {
    state.ifDictionaryEdit = ifDictionaryEdit;
  },
  DELETESYSTEMDICTIONARY(state, ifDictionaryDeleted) {
    state.ifDictionaryDeleted = ifDictionaryDeleted;
  },
  GETDICTIONARYDEPARTMENT(state, ifDictionaryDepartment) {
    state.ifDictionaryDepartment = ifDictionaryDepartment;
  },
  GETCOMMUNICATIONPAGEDATA(state, communicationPageData) {
    state.communicationPageData = communicationPageData;
  },
  DELETECOMMUNICATION(state, ifDeleteCommunicationSuccess) {
    state.ifDeleteCommunicationSuccess = ifDeleteCommunicationSuccess;
  },
  PUTSYSTEMUSEREDITPHOTO(state, ifSuccessEditPhoto) {
    state.ifSuccessEditPhoto = ifSuccessEditPhoto;
  },
  GETUSERROLEINFO(state, userRoleInfoData) {
    state.userRoleInfoData = userRoleInfoData;
  },
  GETSYSTEMCHARACTERMENULIST(state, menuList) {
    state.menuList = menuList;
  },
  PostWarningConfig(state, postWarningConfig) {
    state.postWarningConfig = postWarningConfig;
  },
  PutWarningConfig(state, putWarningConfig) {
    state.putWarningConfig = putWarningConfig;
  },
  GetWarningIndexSelectList(state, getWarningIndexSelectList) {
    state.getWarningIndexSelectList = getWarningIndexSelectList;
  },
  GetWarningNameSelectDtoList(state, getWarningNameSelectDtoList) {
    state.getWarningNameSelectDtoList = getWarningNameSelectDtoList;
  },
  GetWarningConfigs(state, getWarningConfigs) {
    state.getWarningConfigs = getWarningConfigs;
  },
  GetWarningConfigById(state, getWarningConfigById) {
    state.getWarningConfigById = getWarningConfigById;
  },
  DelWarningConfigById(state, delWarningConfigById) {
    state.delWarningConfigById = delWarningConfigById;
  },
  PostWarningTeam(state, postWarningTeam) {
    state.postWarningTeam = postWarningTeam;
  },
  PutWarningTeam(state, putWarningTeam) {
    state.putWarningTeam = putWarningTeam;
  },
  GetWarningTeams(state, getWarningTeams) {
    state.getWarningTeams = getWarningTeams;
  },
  GetWarningTeamByPage(state, getWarningTeamByPage) {
    state.getWarningTeamByPage = getWarningTeamByPage;
  },
  GetWarningTeamById(state, getWarningTeamById) {
    state.getWarningTeamById = getWarningTeamById;
  },
  DelWarningTeamById(state, delWarningTeamById) {
    state.delWarningTeamById = delWarningTeamById;
  },
  PutWarningTeamDetail(state, putWarningTeamDetail) {
    state.putWarningTeamDetail = putWarningTeamDetail;
  },
  DelWarningTeamDetail(state, delWarningTeamDetail) {
    state.delWarningTeamDetail = delWarningTeamDetail;
  },
  GetWarningTeamDetails(state, getWarningTeamDetails) {
    state.getWarningTeamDetails = getWarningTeamDetails;
  },
  GetWarningTeamDetailByPage(state, getWarningTeamDetailByPage) {
    state.getWarningTeamDetailByPage = getWarningTeamDetailByPage;
  },
  PostWarningTeamDetail(state, postWarningTeamDetail) {
    state.postWarningTeamDetail = postWarningTeamDetail;
  },
  AddEquipmentConnect(state, addEquipmentConnect) {
    state.addEquipmentConnect = addEquipmentConnect;
  },
  DelEquipmentConnect(state, delEquipmentConnect) {
    state.delEquipmentConnect = delEquipmentConnect;
  },
  GetEquipmentLists(state, getEquipmentLists) {
    state.getEquipmentLists = getEquipmentLists;
  },
  GetEquipmentConnectList(state, getEquipmentConnectList) {
    state.getEquipmentConnectList = getEquipmentConnectList;
  },
  GetAllUserListPage(state, getAllUserListPage) {
    state.getAllUserListPage = getAllUserListPage;
  },
  GetWarningTeamEquipments(state, getWarningTeamEquipments) {
    state.getWarningTeamEquipments = getWarningTeamEquipments;
  },
  GetWarningTeamEquipConn(state, getWarningTeamEquipConn) {
    state.getWarningTeamEquipConn = getWarningTeamEquipConn;
  },
  AddWarningTeamEquipment(state, addWarningTeamEquipment) {
    state.addWarningTeamEquipment = addWarningTeamEquipment;
  },
  DelWarningTeamEquipment(state, delWarningTeamEquipment) {
    state.delWarningTeamEquipment = delWarningTeamEquipment;
  },
  GetWarningInfoList(state, getWarningInfoList) {
    state.getWarningInfoList = getWarningInfoList;
  },
  DownloadWarningInfoList(state, downloadWarningInfoList) {
    state.downloadWarningInfoList = downloadWarningInfoList;
  },
  OPERATIONMONITORINGDATA(state, operationMonitoringData) {
    state.operationMonitoringData = operationMonitoringData
  },
  //#region 日志管理
  GETSYSLOGPAGEDATA(state, sysLogPageData) {
    state.sysLogPageData = sysLogPageData;
  },
  //#endregion
};
const actions = {
  //#region 用户管理界面的请求########################################################################################################################
  // 获取用户管理界面的分页数据（用户管理界面）
  async getUserPageData({ commit }, val) {
    const userPageData = await request.get(
      `/sys/user/page?current=${val.current}&size=${val.size}&username=${val.username}&status=${val.status}`
    );
    commit("GETUSERPAGEDATA", userPageData);
  },
  async getUserCustomerPageData({ commit }, val) {
    const userCustomerPageData = await request.get(
      `/sys/user/customerPage?current=${val.current}&size=${val.size}&username=${val.username}&status=${val.status}`
    );
    commit("GETUSERCUSTOMERPAGEDATA", userCustomerPageData);
  },

  // 新增用户（用户管理界面）
  async postSystemUserAddNewUser({ commit }, val) {
    const ifAddUserSuccess = await request.post(`/sys/user`, val);
    commit("POSTSYSTEMUSERADDNEWUSER", ifAddUserSuccess);
  },
  // 编辑用户信息（用户管理界面）
  async putSystemUserEdit({ commit }, val) {
    const ifSuccessEdit = await request.put(`/sys/user`, val);
    commit("PUTSYSTEMUSEREDIT", ifSuccessEdit);
  },
  // 编辑用户头像（用户管理界面）
  async putSystemUserEditPhoto({ commit }, val) {
    const ifSuccessEditPhoto = await request.put(`/sys/user/photo`, val);
    commit("PUTSYSTEMUSEREDITPHOTO", ifSuccessEditPhoto);
  },

  //  删除用户（用户管理界面）
  async deleteSystemUser({ commit }, val) {
    const ifDeleteUserSuccess = await request.delete(`/sys/user/del?userId=${val}`);
    commit("DELETESYSTEMUSER", ifDeleteUserSuccess);
  },

  // 修改用户密码（用户管理界面）
  async getSystemUserResetPassword({ commit }, val) {
    const ifResetPassword = await request.get(`/sys/user/updatePassword?userId=${val}`);
    commit("GETSYSTEMUUSERRESETPASSWORD", ifResetPassword);
  },
  // 通讯管理机分页（通讯管理）
  async getCommunicationPageData({ commit }, val) {
    const communicationPageData = await request.get(
      `/comm-machine/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&customerId=${val.customerId}`
    );
    commit("GETCOMMUNICATIONPAGEDATA", communicationPageData);
  },
  // 删除通讯管理机（通讯管理）
  async deleteCommunication({ commit }, val) {
    const ifDeleteCommunicationSuccess = await request.delete(`/comm-machine/${val}`);
    commit("DELETECOMMUNICATION", ifDeleteCommunicationSuccess);
  },
  // 获取用户的角色详情
  async getUserRoleInfo({ commit }, val) {
    const userRoleInfoData = await request.get(`/sys/user/info/${val}`);
    commit("GETUSERROLEINFO", userRoleInfoData);
  },
  //#endregion

  //#region  位置管理界面的请求########################################################################################################################
  // 获取位置树（位置管理）
  async getSystemPositionTree({ commit }) {
    const positionTree = await request.get("/tree/location");
    commit("GETSYSTEMPOSITIONTREE", positionTree);
  },

  // 获取分页数据（位置管理）
  async getSystemPositionPageData({ commit }, val) {
    const positionPageData = await request.get(
      `/location/page/${val.pageNum}/${val.pageSize}/?keyword=${val.keyword}&nodeId=${val.nodeId}&nodeType=${val.nodeType}`
    );
    commit("GETSYSTEMPOSITIONPAGEDATA", positionPageData);
  },
  // 新增位置（位置管理）
  async postSystemPositionAdd({ commit }, val) {
    const ifPositionAddSuccess = await request.post(`/location`, val);
    commit("POSTSYSTEMPOSITIONADD", ifPositionAddSuccess);
  },
  // 编辑位置（位置管理）
  async putSystemPositionEdit({ commit }, val) {
    const ifPositionEditSuccess = await request.put(`/location`, val);
    commit("PUTSYSTEMPOSITIONEDIT", ifPositionEditSuccess);
  },
  // 删除位置（位置管理）
  async deleteSystemPosition({ commit }, val) {
    const ifPositionDeleted = await request.delete(`/location/${val}`);
    commit("DELETESYSTEMPOSITION", ifPositionDeleted);
  },
  //#endregion

  //#region 电维度管理界面的请求########################################################################################################################
  // 获取位置树（位置管理）
  async getTreePeidian({ commit }) {
    const peidianTree = await request.get("/tree/peidian");
    commit("GETTREEPEIDIAN", peidianTree);
  },

  // 获取分页数据
  async getSystemPeidianPageData({ commit }, val) {
    const peidianPageData = await request.get(
      `/peidian/page/${val.pageNum}/${val.pageSize}/?keyword=${val.keyword}&nodeId=${val.nodeId}&nodeType=${val.nodeType}`
    );
    commit("GETSYSTEMPEIDIANPAGEDATA", peidianPageData);
  },
  // 新增位置
  async postSystemPeidianAdd({ commit }, val) {
    const ifPeidianAddSuccess = await request.post(`/peidian`, val);
    commit("POSTSYSTEMPEIDIANADD", ifPeidianAddSuccess);
  },
  // 编辑位置
  async putSystemPeidianEdit({ commit }, val) {
    const ifPeidianEditSuccess = await request.put(`/peidian`, val);
    commit("PUTSYSTEMPEIDIAEDIT", ifPeidianEditSuccess);
  },
  // 删除位置
  async deleteSystemPeidian({ commit }, val) {
    const ifPeidianDeleted = await request.delete(`/peidian/${val}`);
    commit("DELETESYSTEMPEIDIAN", ifPeidianDeleted);
  },
  //#endregion

  //#region  部门管理界面的请求##############################################################################################################################
  // 获取部门树（部门管理）
  async getSystemDepartmentTree({ commit }) {
    const departmentTree = await request.get("/dept/tree");
    commit("GETSYSTEMDEPARTMENTTREE", departmentTree);
  },
  // 获取分页数据（部门管理）
  async getSystemDepartmentPageData({ commit }, val) {
    const departmentPageData = await request.get(
      `/dept/page/${val.pageNum}/${val.pageSize}/?keyword=${val.keyword}&id=${val.id}`
    );
    commit("GETSYSTEMDEPARTMENTPAGEDATA", departmentPageData);
  },
  // 新增用户（部门管理）
  async postSystemDepartmentAddNewUser({ commit }, val) {
    const ifDepartmentAddSuccess = await request.post(`/dept`, val);
    commit("POSTSYSTEMDEPARTMENTADDNEWUSER", ifDepartmentAddSuccess);
  },
  // 编辑用户（部门管理）
  async putSystemDepartmentEdit({ commit }, val) {
    const ifDepartmentEditSuccess = await request.put(`/dept`, val);
    commit("POSTSYSTEMDEPARTMENTEDITNEWUSER", ifDepartmentEditSuccess);
  },
  //删除用户（部门管理）
  async deleteSystemDepartment({ commit }, val) {
    const ifDepartmentDeleted = await request.delete(`/dept/?deptId=${val}`);
    commit("DELETESYSTEMDEPARTMENT", ifDepartmentDeleted);
  },
  //#endregion

  //#region 分页查询（角色管理）
  async querySystemrole({ commit }, val) {
    const ifQueryRoleList = await request.get(
      `/sys/role/page?current=${val.current}&size=${val.size}&roleName=${val.roleName}`
    );
    commit("QUERYSYSTEMROLE", ifQueryRoleList);
  },
  //新增角色（角色管理）
  async postSystemAddNewRole({ commit }, val) {
    const ifPostAddRole = await request.post(`/sys/role`, val);
    commit("POSTSYSTEMADDNEWROLE", ifPostAddRole);
  },
  //编辑角色（角色管理）
  async postSystemNewRoleEdit({ commit }, val) {
    const ifPostRoleEdit = await request.put(`/sys/role`, val);
    commit("POSTSYSTEMNEWROLEEdit", ifPostRoleEdit);
  },

  //删除（角色管理）
  async postSystemDeleteRole({ commit }, val) {
    const ifDeleteRole = await request.delete(`/sys/role/deleteId?roleId=${val}`);
    commit("POSTSYSTEMDELETEROLE", ifDeleteRole);
  },
  // 获取菜单列表
  async getSystemCharacterMenuList({ commit }, val) {
    const menuList = await request.get(`sys/role/info/${val}`);
    commit("GETSYSTEMCHARACTERMENULIST", menuList);
  },
  //#endregion

  //#region 字典管理界面####################################################################################################################################
  // 获取分页数据（字典管理）
  async getDictionaryPageData({ commit }, val) {
    const dictionaryPageData = await request.get(`/dict/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}`);
    commit("GETDICTIONARYPAGEDATA", dictionaryPageData);
  },
  // 获取职位数据（字典管理）
  async getDictionaryPosData({ commit }) {
    const dictionaryPosData = await request.get(`/dict/page/1/10?keyword=%E8%81%8C%E4%BD%8D`);
    commit("GETDICTIONARYPOSDATA", dictionaryPosData);
  },
  // 新增加字典（字典管理）
  async postSystemDictionaryAddNewUser({ commit }, val) {
    const ifDictionaryAdded = await request.post(`/dict`, val);
    commit("POSTSYSTEMDICTIONARYADDNEWUSER", ifDictionaryAdded);
  },
  // 编辑字典（字典管理）
  async putSystemDictionaryEdit({ commit }, val) {
    const ifDictionaryEdit = await request.put(`/dict`, val);
    commit("PUTSYSTEMDICTIONARYEDIT", ifDictionaryEdit);
  },
  // 删除字典（字典管理）
  async deleteSystemDictionary({ commit }, val) {
    const ifDictionaryDeleted = await request.delete(`/dict/${val}`);
    commit("DELETESYSTEMDICTIONARY", ifDictionaryDeleted);
  },
  //从字典里面拿到部门的数据
  async getDictionaryDepartment({ commit }, val) {
    const ifDictionaryDepartment = await request.get(`/dept/list`);
    commit("GETDICTIONARYDEPARTMENT", ifDictionaryDepartment);
  },
  //#endregion

  //#region 告警管理模块之告警配置######################################################################
  // 添加告警规则信息
  async postWarningConfig({ commit }, val) {
    const postWarningConfig = await request.post(`/warning-config/addWarningConfigList`, val);
    commit("PostWarningConfig", postWarningConfig);
  },

  // 修改告警规则信息
  async putWarningConfig({ commit }, val) {
    const putWarningConfig = await request.put(`/warning-config`, val);
    commit("PutWarningConfig", putWarningConfig);
  },

  // 获取告警指标下拉框
  async getWarningIndexSelectList({ commit }) {
    const getWarningIndexSelectList = await request.get(`/warning-config/getWarningIndexSelectList`);
    commit("GetWarningIndexSelectList", getWarningIndexSelectList);
  },

  // 获取告警名称下拉框
  async getWarningNameSelectDtoList({ commit }, indexName) {
    const getWarningNameSelectDtoList = await request.get(`/warning-config/getWarningNameSelectDtoList/${indexName}`);
    commit("GetWarningNameSelectDtoList", getWarningNameSelectDtoList);
  },

  // 分页获取所有告警规则列表
  async getWarningConfigs({ commit }, val) {
    const getWarningConfigs = await request.get(`/warning-config/page/${val.pageNum}/${val.pageSize}`);
    commit("GetWarningConfigs", getWarningConfigs);
  },

  // 获取单个告警规则信息
  async getWarningConfigById({ commit }, id) {
    const getWarningConfigById = await request.get(`/warning-config/${id}`);
    commit("GetWarningConfigById", getWarningConfigById);
  },

  // 删除单个告警规则信息
  async delWarningConfigById({ commit }, id) {
    const delWarningConfigById = await request.delete(`/warning-config/${id}`);
    commit("DelWarningConfigById", delWarningConfigById);
  },

  // 关联：添加设备关联
  async addEquipmentConnect({ commit }, val) {
    const addEquipmentConnect = await request.post(`/warning-config`, val);
    commit("AddEquipmentConnect", addEquipmentConnect);
  },

  // 关联：删除设备关联
  async delEquipmentConnect({ commit }, val) {
    const delEquipmentConnect = await request.delete(`/warning-config?configId=${val.configId}&placeId=${val.placeId}&meterId=${val.meterId}`);
    commit("DelEquipmentConnect", delEquipmentConnect);
  },

  // 关联：获取全部设备下拉框
  async getEquipmentLists({ commit }, val) {
    console.log(val)
    const getEquipmentLists = await request.get(`/warning-config/getAllDeviceSelectDtoList?configId=${val.configId}&placeId=${val.placeId}&keyword=${val.keyword}`);
    commit("GetEquipmentLists", getEquipmentLists);
  },

  // 关联：获取已关联设备列表
  async getEquipmentConnectList({ commit }, val) {
    const getEquipmentConnectList = await request.get(
      `/warning-config/getRelationDeviceSelectDtoList?configId=${val.configId}&placeId=${val.placeId}&keyword=${val.keyword}`
    );
    commit("GetEquipmentConnectList", getEquipmentConnectList);
  },
  //#endregion

  //#region 告警管理模块之告警小组######################################################################
  // 添加告警小组信息
  async postWarningTeam({ commit }, val) {
    const postWarningTeam = await request.post(`/warning-team`, val);
    commit("PostWarningTeam", postWarningTeam);
  },

  // 修改告警小组信息
  async putWarningTeam({ commit }, val) {
    const putWarningTeam = await request.put(`/warning-team`, val);
    commit("PutWarningTeam", putWarningTeam);
  },

  // 获取所有告警小组列表
  async getWarningTeams({ commit }) {
    const getWarningTeams = await request.get(`/warning-team/getAll`);
    commit("GetWarningTeams", getWarningTeams);
  },

  // 分页获取告警小组列表
  async getWarningTeamByPage({ commit }, val) {
    const getWarningTeamByPage = await request.get(
      `/warning-team/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}`
    );
    commit("GetWarningTeamByPage", getWarningTeamByPage);
  },

  // 获取单个告警小组信息
  async getWarningTeamById({ commit }, id) {
    const getWarningTeamById = await request.get(`/warning-team/${id}`);
    commit("GetWarningTeamById", getWarningTeamById);
  },

  // 删除告警小组信息
  async delWarningTeamById({ commit }, id) {
    const delWarningTeamById = await request.delete(`/warning-team/${id}`);
    commit("DelWarningTeamById", delWarningTeamById);
  },

  // 指派：获取全部设备下拉框
  async getWarningTeamEquipments({ commit }, val) {
    const getWarningTeamEquipments = await request.get(`/warning-team/getAllDeviceSelectDtoList?teamId=${val.teamId}&keyword=${val.keyword}`);
    commit("GetWarningTeamEquipments", getWarningTeamEquipments);
  },

  // 指派：获取已指派设备列表
  async getWarningTeamEquipConn({ commit }, val) {
    const getWarningTeamEquipConn = await request.get(
      `/warning-team/getRelationDeviceSelectDtoList?teamId=${val.teamId}&keyword=${val.keyword}`
    );
    commit("GetWarningTeamEquipConn", getWarningTeamEquipConn);
  },

  // 指派：添加设备指派
  async addWarningTeamEquipment({ commit }, val) {
    const addWarningTeamEquipment = await request.post(`/warning-team/addTeamDeviceRelation`, val);
    commit("AddWarningTeamEquipment", addWarningTeamEquipment);
  },

  // 删除告警小组信息
  async delWarningTeamEquipment({ commit }, val) {
    const delWarningTeamEquipment = await request.delete(`/warning-team?meterId=${val.meterId}&teamId=${val.teamId}`);
    commit("DelWarningTeamEquipment", delWarningTeamEquipment);
  },
  //#endregion

  //#region 告警管理模块之告警人######################################################################
  // 修改告警人
  async putWarningTeamDetail({ commit }, val) {
    const putWarningTeamDetail = await request.put(`/warning-team-detail`, val);
    commit("PutWarningTeamDetail", putWarningTeamDetail);
  },

  // 删除单个告警人
  async delWarningTeamDetail({ commit }, id) {
    const delWarningTeamDetail = await request.delete(`/warning-team-detail/${id}`);
    commit("DelWarningTeamDetail", delWarningTeamDetail);
  },

  // 获取所有告警人列表
  async getWarningTeamDetails({ commit }) {
    const getWarningTeamDetails = await request.get(`/warning-team-detail/getAll`);
    commit("GetWarningTeamDetails", getWarningTeamDetails);
  },

  // 分页获取所有告警人列表
  async getWarningTeamDetailByPage({ commit }, val) {
    const getWarningTeamDetailByPage = await request.get(
      `/warning-team-detail/page/${val.pageNum}/${val.pageSize}?id=${val.id}`
    );
    commit("GetWarningTeamDetailByPage", getWarningTeamDetailByPage);
  },

  // 新增告警人
  async postWarningTeamDetail({ commit }, val) {
    const postWarningTeamDetail = await request.post(`/warning-team-detail/insert`, val);
    commit("PostWarningTeamDetail", postWarningTeamDetail);
  },

  // 新增告警人时获取所有用户列表
  async getAllUserListPage({ commit }, val) {
    const getAllUserListPage = await request.get(
      `/warning-team-detail/getAllUserListPage/${val.pageNum}/${val.pageSize}?teamId=${val.teamId}&keyword=${val.keyword}`
    );
    commit("GetAllUserListPage", getAllUserListPage);
  },
  //#endregion

  // 分页获取所有告警信息列表
  async getWarningInfoList({ commit }, val) {
    const getWarningInfoList = await request.get(
      `/warning-info/page/${val.pageNum}/${val.pageSize}?warningType=${val.warningType}&warningLevel=${val.warningLevel}&warningStatus=${val.warningStatus}&beginTime=${val.beginTime}&endTime=${val.endTime}`
    );
    commit("GetWarningInfoList", getWarningInfoList);
  },

  // 导出告警信息列表
  async downloadWarningInfoList({ commit }, val) {
    // const downloadWarningInfoList = await request.get(
    //   `/warning-info/export?warningType=${val.warningType}&warningLevel=${val.warningLevel}&warningStatus=${val.warningStatus}&beginTime=${val.beginTime}&endTime=${val.endTime}`
    // );
    commit("DownloadWarningInfoList", 1);
    window.location.href = config.baseURL + `warning-info/export?warningType=${val.warningType}&warningLevel=${val.warningLevel}&warningStatus=${val.warningStatus}&beginTime=${val.beginTime}&endTime=${val.endTime}`
  },

  // 工作台运行监测数据
  async operationMonitoringData({ commit }, val) {
    const operationMonitoringData = await request.get(
      `/workDesk/page/${val.pageNum}/${val.pageSize}`
    );
    commit("OPERATIONMONITORINGDATA", operationMonitoringData);
  },
  //#region 日志管理页面接口
  // 分页获取操作日志列表数据
  async getSysLogPageData({ commit }, params) {
    const { pageNum, pageSize, keyword, type, startDate, endDate } = params;
    const sysLogPageData = await request.get(
      `/sysLog/page/${pageNum}/${pageSize}`, {
      params: {
        keyword,
        type,
        startDate,
        endDate,
      }
    }
    );
    commit("GETSYSLOGPAGEDATA", sysLogPageData);
  },
  //#endregion
};
const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
};
