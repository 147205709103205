import request from '../../utils/request.js'
import config from '../../config/index.js'

// console.log(reques)
export default {
  namepaced: true,
  state: {
    allMetrList: {}, //仪表管理列表
    deleteInfo: {}, //删除列表列表
    metrType: [], //用电类型
    meterList: [], // 配电仪表
    bigBuildingList: [], //大楼和楼层
    allLayerList: {}, // 根据位置id获取大楼列表
    editInfo: {}, //编辑仪表列表之后返回的信息
    allRommList: {}, //获取所有的房间
    postMeter: {}, // 登记仪表
    getOneMeter: {},
    peidianMeterPage: {}, // 配电能耗列表
    rootList: {}, // 根节点下拉列表
    equipmentList: {}, // 设备下拉列表
    loopList: {}, // 回路下拉列表
    loopList2: {}, // 二级回路下拉列表
    loopList3: {}, // 三级回路下拉列表
    loopList4: {}, // 四季回路下拉列表
    addMeterInfo: {}, // 登记配电用能
    meterDetail: {}, // 配电用能详情
    editMeterInfo: {}, // 编辑配电用能
    deleteMeterInfo: {}, // 删除配电用能
  },
  mutations: {
    // 仪表管理
    metrPage(state, val) {
      state.allMetrList = val
    },
    // 删除仪表 这个方法可以不用调用，万一删除失败，可以接收到 接口删除失败的结果
    DELETE(state, val) {
      state.deleteInfo = val
    },
    // 用电类型
    POWERTYPE(state, val) {
      state.metrType = val
    },
    // 配电仪表
    GETMETERLIST(state, val) {
      state.meterList = val
    },
    // locationBuilding
    LOCATIONBUILDING(state, val) {
      state.bigBuildingList = val
    },
    // locationSub 根据位置id获取下级列表
    LOCATIONSUB(state, val) {
      state.allLayerList = val
    },
    // locationSub 根据位置id获取下级列表
    GETROOMLIST(state, val) {
      state.allRommList = val
    },
    // 编辑仪表
    EDITLIST(state, val) {
      state.editInfo = val
    },
    postMeter(state, val) {
      state.postMeter = val
    },
    getOneMeter(state, val) {
      state.getOneMeter = val
    },
    PEIDIANMETERPAGE(state, val) {
      state.peidianMeterPage = val
    },
    ROOTLIST(state, val) {
      state.rootList = val
    },
    SUBLIST(state, val) {
      if (val.type == 'equipment') {
        state.equipmentList = val.getallList
      } else if (val.type == 'loop') {
        state.loopList = val.getallList
      } else if (val.type == 'loop2') {
        state.loopList2 = val.getallList
      } else if (val.type == 'loop3') {
        state.loopList3 = val.getallList
      } else if (val.type == 'loop4') {
        state.loopList4 = val.getallList
      }
    },
    ADDMETER(state, val) {
      state.addMeterInfo = val
    },
    METERDETAIL(state, val) {
      state.meterDetail = val
    },
    EDITMETERINFO(state, val) {
      state.editMeterInfo = val
    },
    DELETEMETERINFO(state, val) {
      state.deleteMeterInfo = val
    },
  },
  actions: {
    // 仪表管理
    async metrPage({ commit }, val) {
      const getallList = await request.get(
        `/energy/meter/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&type=${val.type}&ownerId=${val.ownerId}`
      )
      commit('metrPage', getallList)
    },
    // 删除仪表
    async delete({ commit }, val) {
      const deleteInfo = await request.delete(`/energy/meter/${val}`)
      commit('DELETE', deleteInfo)
    },
    // 电表用电类型
    async powertype({ commit }) {
      const metrTypeList = await request.get('/energy/meter/powertype')
      commit('POWERTYPE', metrTypeList)
    },
    // 配电仪表
    async getMeterList({ commit }, val) {
      const meterList = await request.get(
        `/energy/meter/meter/electricity/${val}`
      )
      commit('GETMETERLIST', meterList)
    },
    // 根据id获取客户大楼列表
    async locationBuilding({ commit }, val) {
      console.log(val)
      const allBuildingList = await request.get(`/location/building/${val}`)
      commit('LOCATIONBUILDING', allBuildingList)
    },
    // 根据位置id获取下级列表
    async locationSub({ commit }, val) {
      const layerList = await request.get(`/location/${val}/sub`)
      commit('LOCATIONSUB', layerList)
    },
    // 获取房间
    async getRoomList({ commit }, val) {
      const layerList = await request.get(`/location/${val}/sub`)
      commit('GETROOMLIST', layerList)
    },
    // 编辑仪表 /energy/meter
    async editMetr({ commit }, val) {
      const editList = await request.put(`/energy/meter`, val)
      // console.log(editList)
      commit('EDITLIST', editList)
    },
    // 登记表单 energy/meter post
    async eneryMeter({ commit }, val) {
      const eneryMenterList = await request.post('/energy/meter', val)
      commit('postMeter', eneryMenterList)
    },
    // 根据id获取单个仪表信息
    async getOneMeter({ commit }, val) {
      const getOneMeter = await request.get(`/energy/meter/${val.id}`)
      commit('getOneMeter', getOneMeter)
    },
    // 配电能耗列表
    async getPeidianMeterPage({ commit }, val) {
      const getpageList = await request.get(
        `/pedian/meter/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&ownerId=${val.ownerId}`
      )
      commit('PEIDIANMETERPAGE', getpageList)
    },
    // 根节点下拉列表
    async getRootList({ commit }, customerId) {
      const getallList = await request.get(`/peidian/building/${customerId}`)
      commit('ROOTLIST', getallList)
    },
    // 根据节点id获取其下级列表
    async getSubList({ commit }, val) {
      const getallList = await request.get(`/peidian/${val.id}/sub`)
      commit('SUBLIST', { getallList, type: val.type })
    },
    // 登记配电用能
    async addMeter({ commit }, val) {
      const res = await request.post(`/pedian/meter`, val)
      commit('ADDMETER', res)
    },
    // 获取配电用能详情
    async getMeterDetail({ commit }, id) {
      const res = await request.get(`/pedian/meter/${id}`)
      commit('METERDETAIL', res)
    },
    // 编辑并再次提交配电用能
    async editMeter({ commit }, val) {
      const res = await request.put(`/pedian/meter`, val)
      commit('EDITMETERINFO', res)
    },
    // 删除配电用能
    async deleteMeter({ commit }, id) {
      const res = await request.delete(`/pedian/meter/${id}`)
      commit('DELETEMETERINFO', res)
    },
  },
}
