// 左侧一级菜单栏组件（非路由组件）
<template>
  <div class="outSide" id="father">
    <el-menu
      @select="menuSelect"
      @open="menuOpen"
      @close="menuClose"
      :unique-opened="true"
      :default-active="defaultActive"
      :default-openeds="defaultOpeneds"
      ref="menu"
    >
      <template v-for="(item, index) in menuList">
        <!-- 有二级菜单 -->
        <el-submenu
          v-if="item.children"
          :class="submenuActiveClass(index)"
          :index="index + ''"
          :key="item.menuId"
          :ref="'submenuRef' + index"
        >
          <template slot="title">
            <img
              :src="selectIndex === index + '' ? item.iconSrc : item.iconSrc2"
              alt=""
            />
            <span>{{ item.name }}</span>
          </template>

          <el-menu-item-group
            v-for="(items, indexs) in item.children"
            :key="items.menuId"
            @click.native="routeTo(items)"
          >
            <el-menu-item
              :index="index + '-' + indexs"
              :class="menuGroupActiveClass(index, indexs)"
            >
              <!-- <img :src="items.iconSrc2" alt="" /> -->
              <span>{{ items.name }}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 没二级菜单 -->
        <el-menu-item
          v-else
          :class="menuActiveClass(index)"
          :index="index + ''"
          :key="item.menuId"
          @click="routeTo(item)"
        >
          <img
            :src="selectIndex === index + '' ? item.iconSrc : item.iconSrc2"
            alt=""
          />
          <span>{{ item.name }}</span>
        </el-menu-item>
      </template>
    </el-menu>
    <!-- 用于填充卡片下面的空白 -->
    <div
      v-for="(item, index) in times"
      :key="index + 'two'"
      style="
        height: 3.6rem;
        width: 16rem;
        background-color: rgba(0, 115, 222, 0.3);
      "
    ></div>
  </div>
</template>

<script>
import { getCookie } from '@/utils/util'
import { mapState } from 'vuex'
export default {
  name: 'MenuLevelOne',
  data() {
    return {
      // 选中数据的id
      choosenId: '',
      // 是否为选中了第一个儿子
      ifTheFirstOne: false,
      // 用于做数据准备的菜单列表
      menuListReady: [
        {
          name: '工作台',
          type: 1,
          status: 1,
          router: '/workBench',
          iconSrc: require('../assets/menuIcon/icon1Green.png'),
          iconSrc2: require('../assets/menuIcon/icon1.png'),
          menuId: 7,
        },
        {
          name: '全景展示',
          type: 1,
          status: 1,
          router: '/fullView',
          iconSrc: require('../assets/menuIcon/icon2Green.png'),
          iconSrc2: require('../assets/menuIcon/icon2.png'),
          menuId: 8,
        },
        {
          name: '配电监控',
          type: 1,
          status: 1,
          router: '/monitoring',
          iconSrc: require('../assets/menuIcon/icon3Green.png'),
          iconSrc2: require('../assets/menuIcon/icon3.png'),
          menuId: 9,
        },
        {
          name: '电气消防',
          type: 1,
          status: 1,
          router: '/fireControl',
          iconSrc: require('../assets/menuIcon/icon4Green.png'),
          iconSrc2: require('../assets/menuIcon/icon4.png'),
          menuId: 10,
        },
        {
          name: '发电管理',
          type: 1,
          status: 1,
          router: '/elecGenerate',
          iconSrc: require('../assets/menuIcon/icon5Green.png'),
          iconSrc2: require('../assets/menuIcon/icon5.png'),
          menuId: 11,
        },
        {
          name: '能源管理',
          type: 1,
          status: 1,
          router: '/elecManagement',
          iconSrc: require('../assets/menuIcon/icon6Green.png'),
          iconSrc2: require('../assets/menuIcon/icon6.png'),
          menuId: 12,
        },
        {
          name: '充电运营',
          type: 1,
          status: 1,
          router: '/elecCharge',
          iconSrc: require('../assets/menuIcon/icon7Green.png'),
          iconSrc2: require('../assets/menuIcon/icon7.png'),
          menuId: 13,
        },
        {
          name: '客户管理',
          type: 1,
          status: 1,
          router: '/customer',
          iconSrc: require('../assets/menuIcon/icon10Green.png'),
          iconSrc2: require('../assets/menuIcon/icon10.png'),
          menuId: 16,
        },
        {
          name: '运维检修',
          type: 'repair',
          status: 1,
          router: '/repair',
          iconSrc: require('../assets/menuIcon/icon8Green.png'),
          iconSrc2: require('../assets/menuIcon/icon8.png'),
          menuId: 14,
        },
        {
          name: '巡检计划',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/patrolPlan',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 23,
        },
        {
          name: '巡检任务',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/patrolTask',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 24,
        },
        {
          name: '我的巡检',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/patrolTaskMine',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 38,
        },
        {
          name: '工单管理',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/orderManagement',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 25,
        },
        {
          name: '我的工单',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/orderManagementMine',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 39,
        },
        {
          name: '巡检设置',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/patrolSetting',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 27,
        },
        {
          name: '运维统计',
          type: 'repairChildNotShow',
          status: 1,
          router: '/repair/statistics',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 41,
        },
        {
          name: '设备管理',
          type: 'equipment',
          status: 1,
          router: '/equipment',
          iconSrc: require('../assets/menuIcon/icon11Green.png'),
          iconSrc2: require('../assets/menuIcon/icon11.png'),
          menuId: 17,
        },
        {
          name: '配电设备',
          type: 'equipmentChildNotShow',
          status: 1,
          router: '/equipment/equipDistribution',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 35,
        },
        {
          name: '发电设备',
          type: 'equipmentChildNotShow',
          status: 1,
          router: '/equipment/equipGenerate',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 36,
        },
        {
          name: '仪表管理',
          type: 'equipmentChildNotShow',
          status: 1,
          router: '/equipment/equipMeters',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 37,
        },
        {
          name: '配电用能',
          type: 'equipmentChildNotShow',
          status: 1,
          router: '/equipment/powerManagement',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 45,
        },
        {
          name: '系统绘制',
          type: 'mapping',
          status: 1,
          router: '/mapping',
          iconSrc: require('../assets/menuIcon/icon12Green.png'),
          iconSrc2: require('../assets/menuIcon/icon12.png'),
          menuId: 18,
        },
        {
          name: '元件分组',
          type: 'mappingChildNotShow',
          status: 1,
          router: '/mapping/group',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 34,
        },
        {
          name: '元件管理',
          type: 'mappingChildNotShow',
          status: 1,
          router: '/mapping/component',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 33,
        },
        {
          name: '模板管理',
          type: 'mappingChildNotShow',
          status: 1,
          router: '/mapping/templates',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 31,
        },
        {
          name: '图形管理',
          type: 'mappingChildNotShow',
          status: 1,
          router: '/mapping/main',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 29,
        },
        {
          name: '系统管理',
          type: 'system',
          status: 1,
          router: '/system',
          iconSrc: require('../assets/menuIcon/icon13Green.png'),
          iconSrc2: require('../assets/menuIcon/icon13.png'),
          menuId: 1,
        },
        {
          name: '用户管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/user',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 2,
        },
        {
          name: '部门管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/department',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 3,
        },
        {
          name: '角色列表',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/character',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 4,
        },
        {
          name: '字典管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/dictionary',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 6,
        },
        {
          name: '位置管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/position',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 19,
        },
        {
          name: '配电维度',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/powerPosition',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 44,
        },
        {
          name: '通讯管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/communication',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 28,
        },
        {
          name: '告警管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/alarm',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 40,
        },
        {
          name: '日志管理',
          type: 'sysChildNotShow',
          status: 1,
          router: '/system/log',
          iconSrc: require('../assets/menuIcon/blank.png'),
          iconSrc2: require('../assets/menuIcon/blank.png'),
          menuId: 46,
        },
      ],
      // 用于数据绑定的菜单列表
      menuList: [],
      indexPath: [],
      menuOpenIndex: null,
      selectIndex: '',
      defaultActive: '',
      defaultOpeneds: [],
      // 空白底的个数
      times: 16,
    }
  },
  methods: {
    // 点击菜单跳转
    routeTo(item) {
      this.$router.push(item.router)
    },
    // 展开菜单
    menuOpen(index, indexPath) {
      this.menuOpenIndex = index
    },
    // 关闭菜单
    menuClose() {
      this.menuOpenIndex = null
    },
    // 选中菜单
    menuSelect(index, indexPath) {
      this.selectIndex = index
      this.indexPath = indexPath
    },
    // 一级菜单选中class名
    menuActiveClass(index) {
      if (this.selectIndex) {
        switch (this.selectIndex * 1) {
          case index + 1:
            return 'menu-active-up'
          case index:
            return 'menu-active'
          case index - 1:
            return 'menu-active-down'
        }
      }
      if (this.indexPath.length > 1) {
        // 判断 选中的菜单式 是不是 二级列表的最后一个
        if (
          this.menuOpenIndex &&
          this .menuOpenIndex == this .indexPath[0] &&
          this.indexPath[0] == index - 1 &&
          this.menuList[this.indexPath[0] * 1].children.length - 1 ==
            this.indexPath[1].split('-')[1]
        ) {
          return 'menu-active-down'
        }
      }
    },
    // 一级可展开菜单
    submenuActiveClass(index) {
      // 操作展开的菜单 是 选中菜单 的 上一个菜单
      if (this.menuOpenIndex) {
        // 展开菜单是 选中菜单的 上一个菜单
        if (this.indexPath.length == 1) {
          if (this.selectIndex - 1 == index && this.menuOpenIndex == index) {
            return ''
          }
        } else {
          if (
            index == this.indexPath[0] * 1 + 1 &&
            this.menuOpenIndex == index &&
            index != this.indexPath[0]
          ) {
            return ''
          }
        }
      } else {
        if (this.indexPath.length == 1) {
        } else {
          if (this.indexPath[0] == index) {
            return ''
          }
        }
      }
      switch (this.selectIndex * 1) {
        case index + 1:
          return 'submenu-active-up'
        case index - 1:
          return 'submenu-active-down'
      }
      if (this.indexPath.length > 1) {
        if (
          this.indexPath[1].split('-')[1] == '0' &&
          this.indexPath[0] == this.menuOpenIndex &&
          this.indexPath[0] == index
        ) {
          return 'submenu-active-up submenu-active'
        }

        if (this.indexPath[0] == index) {
          return 'submenu-active'
        }

        if (
          this.menuOpenIndex &&
          this .menuOpenIndex == this .indexPath[0] &&
          this.indexPath[1].split('-')[1] ==
            this.menuList[this.indexPath[0] * 1].children?.length - 1 &&
          index - 1 == this.indexPath[0]
        ) {
          return 'submenu-active-down'
        }
      }
    },
    // 二级菜单选中class名
    menuGroupActiveClass(index, indexs) {
      switch (this.selectIndex) {
        case index + '-' + (indexs + 1):
          return 'submenuItem-active-up'
        case index + '-' + indexs:
          return 'submenuItem-active'
        case index + '-' + (indexs - 1):
          return 'submenuItem-active-down'
      }
      if (this.menuOpenIndex == index) {
        if (
          this.indexPath.length == 1 &&
          this.indexPath[0] == index + 1 &&
          this.menuList[this.indexPath[0] * 1 - 1].children?.length - 1 == indexs
        ) {
          return 'submenuItem-active-up'
        }
      }
    },
    // 初始化数据
    initData() {
      const data = this.$store.getters.getterMenuList,
        newList = []
      data.forEach((item) => {
        if (this.menuListReady.find((a) => a.menuId == item.menuId)) {
          let obj = {
            ...this.menuListReady.find((a) => a.menuId == item.menuId),
          }
          if (item.list) {
            obj.children = []
            item.list.forEach((items) => {
              if (this.menuListReady.find((a) => a.menuId == items.menuId)) {
                obj.children.push({
                  ...this.menuListReady.find((a) => a.menuId == items.menuId),
                })
              }
            })
          }
          newList.push(obj)
        }
      })
      this.menuList = newList
    },
    dataHandle(val) {
      for (let i = 0; i < this.menuList.length; i++) {
        if (val.path == this.menuList[i].router) {
          this.selectIndex = i + ''
          this.indexPath = [i + '']
          return
        } else if (this.menuList[i].children) {
          let index = this.menuList[i].children.findIndex((a) =>
            val.path.includes(a.router),
          )
          if (index >= 0) {
            this.menuOpenIndex = i
            this.selectIndex = i + '-' + index
            this.indexPath = [i + '', i + '-' + index]
            return
          }
        }
      }
    },
  },

  mounted() {
    this.initData()

    // 挂载到全局事件总线，跳转到权限菜单的第一个
    this.$bus.$on('goToTheFirstMenu', () => {
      this.$router.push(this.menuList[0].router)
      this.selectIndex = null
    })
  },
  watch: {
    $route: {
      handler(val) {
        if (!this.selectIndex) {
          this.$nextTick(() => {
            this.$router.push(this.menuList[0].router)
            if (val.path == this.menuList[0].router) {
              this.dataHandle(val)
              this.defaultActive = this.menuOpenIndex + ''
              this.defaultOpeneds = [...this.indexPath]
            }
          })
        }
        else {
          if (!val.path.includes(this.menuList[this.indexPath[0]].router)) {
            this.dataHandle(val)
            if (this.indexPath.length > 1) {
              this.$refs.menu.open(this.indexPath[0])
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style lang="less" scoped>
::v-deep .el-menu {
  width: 16rem;
  background-color: transparent;

  // 字体样式 元素盒样式
  .el-menu-item,
  .el-submenu__title,
  .el-menu-item-group {
    height: 6rem;
    line-height: 6rem;
    font-size: 1.6rem;
    img {
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 1rem;
    }
  }
  .el-menu-item-group {
    span {
      color: #fff;
    }
  }

  .el-menu-item {
    color: #fff;
    &:hover {
      background-color: #1e5587;
    }
  }
  & > .el-menu-item {
    background-color: rgba(0, 115, 222, 0.3);
    padding-left: 2rem !important;
  }

  & > .el-submenu {
    .el-menu-item {
      min-width: 0px;
      padding: 0px 0rem 0rem 5.4rem !important;
      background-color: rgba(0, 115, 222, 0.3);
    }

    & > .el-submenu__title {
      color: #fff;
      padding-left: 2rem !important;
      background-color: rgba(0, 115, 222, 0.3);
      i {
        top: calc(50% + 0.2rem);
        right: 1.8rem;
      }
      &:hover {
        background-color: #1e5587;
      }
    }

    .el-menu-item-group {
      .el-menu-item-group__title {
        padding: 0;
      }
      ul {
        li {
          &:hover {
            background-color: #1e5587;
          }
        }
      }
    }
  }

  // 选中样式
  .submenuItem-active,
  .menu-active {
    background-color: #18191a !important;
    > span {
      color: #02ad96;
    }
    &:hover {
      background-color: transparent;
    }
  }

  .submenu-active-up .el-submenu__title,
  .menu-active-up,
  .submenuItem-active-up {
    border-radius: 0 0 2rem 0;
  }

  .submenu-active-down .el-submenu__title,
  .menu-active-down,
  .submenuItem-active-down {
    border-radius: 0 2rem 0 0;
  }

  .submenu-active,
  .is-active {
    .el-submenu__title {
      background-color: #1e5587 !important;
      span {
        color: #fff !important;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.outSide {
  width: 18rem;
  height: 100vh;
  background-color: #18191a;
  overflow: scroll;
  position: absolute;
  top: 0;
  left: 0;
  .menuCard {
    width: 16rem;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;
    background-color: rgba(0, 115, 222, 0.3);
    transition: height 0.5s;
    .routerPlace {
      width: 16rem;
      height: 6rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 1.8rem;
      left: 2rem;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 2.3rem;
      left: 12.3rem;
      transition: all 0.3s;
    }
    .imgBehind {
      display: none;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #fff;
      position: absolute;
      top: 1.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }

  // 点击后使用(被选中的样式)
  .menuCard2 {
    width: 16rem;
    height: 4rem;
    overflow: hidden;
    cursor: pointer;
    background-color: #18191a;
    transition: height 0.5s;
    .routerPlace {
      width: 16rem;
      height: 4rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    .imgFront {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 0.8rem;
      left: 2rem;
      opacity: 0;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 1.3rem;
      left: 12.3rem;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: all 0.3s;
    }
    .imgBehind {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 0.8rem;
      left: 2rem;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #02ad96;
      position: absolute;
      top: 0.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }

  // 点击后使用(展开时的样式)
  .menuCard2Angel {
    width: 16rem;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0 0 2rem 0;
    background-color: #1e5587;
    .routerPlace {
      width: 16rem;
      height: 6rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 1.8rem;
      left: 2rem;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 2.3rem;
      left: 12.3rem;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: all 0.3s;
    }
    .imgBehind {
      display: none;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #fff;
      position: absolute;
      top: 1.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }

  // 点击后使用(无角)
  .menuCard2NotAngel {
    width: 16rem;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;
    background-color: #1e5587;
    .routerPlace {
      width: 16rem;
      height: 6rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 1.8rem;
      left: 2rem;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 2.3rem;
      left: 12.3rem;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      transition: all 0.3s;
    }
    .imgBehind {
      display: none;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #fff;
      position: absolute;
      top: 1.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }

  // 被选中样式的上一个节点
  .menuCard3 {
    width: 16rem;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0 0 2rem 0;
    background-color: rgba(0, 115, 222, 0.3);
    .routerPlace {
      width: 16rem;
      height: 6rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 1.8rem;
      left: 2rem;
    }
    .imgBehind {
      display: none;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 2.3rem;
      left: 12.3rem;
      transition: all 0.3s;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #fff;
      position: absolute;
      top: 1.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }

  // 被选中样式的下一个节点
  .menuCard4 {
    width: 16rem;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;
    border-radius: 0 2rem 0 0;
    background-color: rgba(0, 115, 222, 0.3);
    .routerPlace {
      width: 16rem;
      height: 6rem;
      display: block;
      position: relative;
      top: 0;
      left: 0;
      align-items: center;
    }
    img {
      width: 2.4rem;
      height: 2.4rem;
      position: absolute;
      top: 1.8rem;
      left: 2rem;
    }
    .imgAngel {
      width: 1.4rem;
      height: 1.4rem;
      position: absolute;
      top: 2.3rem;
      left: 12.3rem;
      transition: all 0.3s;
    }
    .imgBehind {
      display: none;
    }
    .word {
      height: 2.4rem;
      width: 11.6rem;
      font-size: 1.6rem;
      color: #fff;
      position: absolute;
      top: 1.8rem;
      left: 5.4rem;
      font-family: PingFang SC;
      line-height: 2.4rem;
      opacity: 0.85;
    }
  }
  // hover的样式
  .menuCard:hover {
    background-color: #1e5587;
  }
  .menuCard3:hover {
    background-color: #1e5587;
  }
  .menuCard4:hover {
    background-color: #1e5587;
  }
}
</style>
