import request from '../../utils/request.js'

// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中
const state = {
    monitoringTree: {},
    deviceList: [],
    reportData: [],
    deviceSwitch: {},
    logTableData: {},
    reportExport: [],
}
const mutations = {
    GETMONITORINGTREE (state, monitoringTree) {
        state.monitoringTree = monitoringTree
    },
    GETDEVICELIST (state, deviceList) {
        state.deviceList = deviceList
    },
    GETREPORTDATA (state, reportData) {
        state.reportData = reportData
    },
    SETDEVICESWITCH (state, deviceSwitch) {
        state.deviceSwitch = deviceSwitch
    },
    GETLOGTABLEDATA (state, logTableData) {
        state.logTableData = logTableData
    },
    REPORTEXPORT (state, reportExport) {
        state.reportExport = reportExport
    }
}
const actions = {
    // 请求获取配电房树的完整信息
    async getMonitoringTree ({ commit }) {
        const monitoringTree = await request.get('/tree/distroom')
        commit('GETMONITORINGTREE', monitoringTree)
    },
    // 配电监控统计报表设备名称下拉项
    async getDeviceList ({ commit }, val) {
        const deviceList = await request.get(`/watch/device/list/${val.nodeId}`)
        commit('GETDEVICELIST', deviceList)
    },
    // 配电监控报表统计
    async getReportData ({ commit }, val) {
        const reportData = await request.get(`/watch/page/report/${val.pageNum}/${val.pageSize}?type=${val.type}&nodeId=${val.nodeId}&date=${val.date}&deviceIds=${val.deviceIds}&startTime=${val.startTime}&endTime=${val.endTime}`)
        commit('GETREPORTDATA', reportData)
    },
    // 设置监测设备开关状态
    async setDeviceSwitch ({ commit }, val) {
        const deviceSwitch = await request.get(`/watch/layer/device/setSwitch/${val.meterId}/${val.status}?password=${val.password}`)
        commit('SETDEVICESWITCH', deviceSwitch)
    },
    // 获取监测设备开关状态
    async getDeviceSwitch ({ commit }, val) {
        const deviceSwitch = await request.get(`/watch/layer/device/switch/${val.meterId}`)
        commit('SETDEVICESWITCH', deviceSwitch)
    },
    // 获取监测设备远控日志
    async getLogTableData ({ commit }, val) {
        const logTableData = await request.get(`/watch/layer/device/getRemotes/${val.pageSize}/${val.pageNum}/${val.meterId}/?name=${val.name}&startTime=${val.startTime}&status=${val.status}`)
        commit('GETLOGTABLEDATA', logTableData)
    },
    // 配电监控报表统计导出
    async exportReport ({ commit }, val) {
        // window.location.href=config.baseURL+`/watch/report/export?nodeId=${val.nodeId}&type=${val.type}&date=${val.date}&deviceIds=${val.deviceIds}&endTime=${val.endTime}&startTime=${val.startTime}`
        const response = await request.get(`/watch/report/export?nodeId=${val.nodeId}&type=${val.type}&date=${val.date}&deviceIds=${val.deviceIds}&endTime=${val.endTime}&startTime=${val.startTime}`)
        var blob = new Blob([response], { type: 'application/vnd.ms-excel,charset=utf-8' }); //创建一个blob对象
        var a = document.createElement("a"); //创建一个<a></a>标签
        a.href = URL.createObjectURL(blob); // response is a blob
        a.download = "报表统计.xls"; //文件名称
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        a.remove();
        commit('REPORTEXPORT', 1)
    }
}
const getters = {

}


export default ({
    state,
    mutations,
    actions,
    getters
})