import request from '../../utils/request.js'
import config from "../../config/index.js"
// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中


const state = {
    patrolPlanPageData: {},
    patrolPlanGroupAll: {},
    patrolPlanPersonAll: {},
    patrolPlanStandAll: {},
    ifPatrolPlanAddSuccess: {},
    ifSwitchSuccess: {},
    ifDeletePatrolPlanSuccess: {},
    repairPatrolTaskPageData: {},
    patrolTaskDetail: {},
    ifDeleteRepairPatrolTaskSuccess: {},
    repairPatrolTaskTabCount: {},
    repairWorkStandardPageData: {},
    repairWorkStandardTypePageData: {},
    repairPatrolGroupSettingLeftPageData: {},
    repairPatrolGroupSettingRightPageData: {},
    ifAddGroupSuccess: {},
    ifEditGroupSuccess: {},
    ifDeleteGroupSuccess: {},
    putRepairPatrolGroupSettingRightSuccess: {},
    ifAddMemberToGroupSuccess: {},
    ifDeleteMemberToGroupSuccess: {},
    repairPatrolSettingDict: {},
    ifRepairPatrolSettingDictSuccess: {},
    ifAaddPageDataTypeSuccess: {},
    ifEditPageDataTypeSuccess: {},
    ifDeletePageDataTypeSuccess: {},
    repairWorkStandardTypeAll: {},
    ifAaddPageDataSuccess: {},
    ifEditWorkStandardSuccess: {},
    ifDeleteWorkStandardSuccess: {},
    orderManagementTabCount: {},
    repairOrderManagementPageData: {},
    repairOrderSettingAssignPageData: {},
    ifAddRepairOrderSettingAssignPersonSuccess: {},
    ifDeleteRepairOrderSettingAssignPersonSuccess: {},
    ifpostRepairOrderManagementAddOrderSuccess: {},
    ifGetRepairAssignSuccess: {},
    ifDeleteRepairOrderManagementOrderSuccess: {},
    ifEditRepairOrderManagementOrderSuccess: {},
    repairPatrolTaskListFromPatrolPlan: {},
    repairPatrolTaskResult: {},
    repairStandardLeftPageData: {},
    repairStandardRightPageData: {},
    repairStandardRightChild: {},
    ifRepairStandardListAdd: {},
    ifdeleteRepairStandardLeftPageDataSuccess: {},
    ifRepairStandardDetailAddSuccess: {},
    ifRepairStandardDetailEditSuccess: {},
    ifdeleteRepairStandardRightPageDataSuccess: {},
    repairPatrolTaskTabCountMine: {},
    repairPatrolTaskPageDataMine: {},
    orderManagementTabCountMine: {},
    repairOrderManagementPageDataMine: {},
    ifPatrolTaskChangeSuccess: {},
    statisticsData: {},
    engineerData: {},
    performanceSData: {},
    restimeData: {},
    restimeTableData: {},
    generalSettingTableData: {},
}
const mutations = {
    GETREPAIRPARTROLPLANPAGEDATA (state, patrolPlanPageData) {
        state.patrolPlanPageData = patrolPlanPageData
    },
    GETREPAIRPATROLGROUPALL (state, patrolPlanGroupAll) {
        state.patrolPlanGroupAll = patrolPlanGroupAll
    },
    GETREPAIRPATROLPLANPERSONALL (state, patrolPlanPersonAll) {
        state.patrolPlanPersonAll = patrolPlanPersonAll
    },
    GETREPAIRPATROLPLANSTANDALL (state, patrolPlanStandAll) {
        state.patrolPlanStandAll = patrolPlanStandAll
    },
    POSTPATROLPLANADD (state, ifPatrolPlanAddSuccess) {
        state.ifPatrolPlanAddSuccess = ifPatrolPlanAddSuccess
    },
    GETREPAIRPATROLPLANSWITCHSTATUS (state, ifSwitchSuccess) {
        state.ifSwitchSuccess = ifSwitchSuccess
    },
    DELETEREPAIRPATROLPLAN (state, ifDeletePatrolPlanSuccess) {
        state.ifDeletePatrolPlanSuccess = ifDeletePatrolPlanSuccess
    },
    GETREPAIRPATROLTASKPAGEDATA (state, repairPatrolTaskPageData) {
        state.repairPatrolTaskPageData = repairPatrolTaskPageData
    },
    GETREPAIRPARTROLTASKDETAIL (state, patrolTaskDetail) {
        state.patrolTaskDetail = patrolTaskDetail
    },
    DELETEREPAIRPATROLTASK (state, ifDeleteRepairPatrolTaskSuccess) {
        state.ifDeleteRepairPatrolTaskSuccess = ifDeleteRepairPatrolTaskSuccess
    },
    GETREPAIRPATROLTASKTABCOUNT (state, repairPatrolTaskTabCount) {
        state.repairPatrolTaskTabCount = repairPatrolTaskTabCount
    },
    GETREPAIRPATROLTASKTABCOUNTMINE (state, repairPatrolTaskTabCountMine) {
        state.repairPatrolTaskTabCountMine = repairPatrolTaskTabCountMine
    },
    GETREPAIRWORKSTANDARDPAGEDATA (state, repairWorkStandardPageData) {
        state.repairWorkStandardPageData = repairWorkStandardPageData
    },
    GETREPAIRWORKSTANDARDTYPEPAGEDATA (state, repairWorkStandardTypePageData) {
        state.repairWorkStandardTypePageData = repairWorkStandardTypePageData
    },
    GETREPAIRPATROLGROUPSETTINGLEFTPAGEDATA (state, repairPatrolGroupSettingLeftPageData) {
        state.repairPatrolGroupSettingLeftPageData = repairPatrolGroupSettingLeftPageData
    },
    GETREPAIRPATROLGROUPSETTINGRIGHTPAGEDATA (state, repairPatrolGroupSettingRightPageData) {
        state.repairPatrolGroupSettingRightPageData = repairPatrolGroupSettingRightPageData
    },
    POSTREPAIRPATROLGROUPSETTINGLEFTADDGROUP (state, ifAddGroupSuccess) {
        state.ifAddGroupSuccess = ifAddGroupSuccess
    },
    PUTREPAIRPATROLGROUPSETTINGLEFTADDGROUP (state, ifEditGroupSuccess) {
        state.ifEditGroupSuccess = ifEditGroupSuccess
    },
    DELETEREPAIRPATROLGROUPSETTINGLEFTDELETEGROUP (state, ifDeleteGroupSuccess) {
        state.ifDeleteGroupSuccess = ifDeleteGroupSuccess
    },
    PUTREPAIRPATROLGROUPSETTINGRIGHT (state, putRepairPatrolGroupSettingRightSuccess) {
        state.putRepairPatrolGroupSettingRightSuccess = putRepairPatrolGroupSettingRightSuccess
    },
    POSTREPAIRPATROLSETTINGADDMEMBERTOGROUP (state, ifAddMemberToGroupSuccess) {
        state.ifAddMemberToGroupSuccess = ifAddMemberToGroupSuccess
    },
    DELETEREPAIRPATROLSETTINGADDMEMBEROUT (state, ifDeleteMemberToGroupSuccess) {
        state.ifDeleteMemberToGroupSuccess = ifDeleteMemberToGroupSuccess
    },
    GETREPAIRPATROLSETTINGDICT (state, repairPatrolSettingDict) {
        state.repairPatrolSettingDict = repairPatrolSettingDict
    },
    PUTREPAIRPATROLSETTINGDICT (state, ifRepairPatrolSettingDictSuccess) {
        state.ifRepairPatrolSettingDictSuccess = ifRepairPatrolSettingDictSuccess
    },
    POSTREPAIRWORKSTANDARDTYPEADD (state, ifAaddPageDataTypeSuccess) {
        state.ifAaddPageDataTypeSuccess = ifAaddPageDataTypeSuccess
    },
    PUTREPAIRWORKSTANDARDTYPEADD (state, ifEditPageDataTypeSuccess) {
        state.ifEditPageDataTypeSuccess = ifEditPageDataTypeSuccess
    },
    DELETEREPAIRWORKSTANDARDTYPEADD (state, ifDeletePageDataTypeSuccess) {
        state.ifDeletePageDataTypeSuccess = ifDeletePageDataTypeSuccess
    },
    GETREPAIRWORKSTANDARDTYPEALL (state, repairWorkStandardTypeAll) {
        state.repairWorkStandardTypeAll = repairWorkStandardTypeAll
    },
    POSTREPAIRWORKSTANDARDADD (state, ifAaddPageDataSuccess) {
        state.ifAaddPageDataSuccess = ifAaddPageDataSuccess
    },
    PUTREPAIRWORKSTANDARD (state, ifEditWorkStandardSuccess) {
        state.ifEditWorkStandardSuccess = ifEditWorkStandardSuccess
    },
    DELETEREPAIRWORKSTANDARDYO (state, ifDeleteWorkStandardSuccess) {
        state.ifDeleteWorkStandardSuccess = ifDeleteWorkStandardSuccess
    },
    GETORDERMANAGEMENTTABCOUNT (state, orderManagementTabCount) {
        state.orderManagementTabCount = orderManagementTabCount
    },
    GETREPAIRORDERMANAGEMENTPAGEDATA (state, repairOrderManagementPageData) {
        state.repairOrderManagementPageData = repairOrderManagementPageData
    },
    GETREPAIRORDERSETTINGASSIGNPAGEDATA (state, repairOrderSettingAssignPageData) {
        state.repairOrderSettingAssignPageData = repairOrderSettingAssignPageData
    },
    POSTREPAIRORDERSETTINGASSIGNPERSON (state, ifAddRepairOrderSettingAssignPersonSuccess) {
        state.ifAddRepairOrderSettingAssignPersonSuccess = ifAddRepairOrderSettingAssignPersonSuccess
    },
    DELETEREPAIRORDERSETTINGASSIGNPERSON (state, ifDeleteRepairOrderSettingAssignPersonSuccess) {
        state.ifDeleteRepairOrderSettingAssignPersonSuccess = ifDeleteRepairOrderSettingAssignPersonSuccess
    },
    POSTREPAIRORDERMANAGEMENTADDORDER (state, ifpostRepairOrderManagementAddOrderSuccess) {
        state.ifpostRepairOrderManagementAddOrderSuccess = ifpostRepairOrderManagementAddOrderSuccess
    },
    GETREPAIRASSIGN (state, ifGetRepairAssignSuccess) {
        state.ifGetRepairAssignSuccess = ifGetRepairAssignSuccess
    },
    DELETEREPAIRORDERMANAGEMENTORDER (state, ifDeleteRepairOrderManagementOrderSuccess) {
        state.ifDeleteRepairOrderManagementOrderSuccess = ifDeleteRepairOrderManagementOrderSuccess
    },
    PUTREPAIRORDERMANAGEMENTEDITORDER (state, ifEditRepairOrderManagementOrderSuccess) {
        state.ifEditRepairOrderManagementOrderSuccess = ifEditRepairOrderManagementOrderSuccess
    },
    GETREPAIRPATROLTASKLISTFROMPATROLPLAN (state, repairPatrolTaskListFromPatrolPlan) {
        state.repairPatrolTaskListFromPatrolPlan = repairPatrolTaskListFromPatrolPlan
    },
    GETREPAIRPATROLTASKRESULT (state, repairPatrolTaskResult) {
        state.repairPatrolTaskResult = repairPatrolTaskResult
    },
    GETREPAIRSTANDARDLEFTPAGEDATA (state, repairStandardLeftPageData) {
        state.repairStandardLeftPageData = repairStandardLeftPageData
    },
    GETREPAIRSTANDARDRIGHTPAGEDATA (state, repairStandardRightPageData) {
        state.repairStandardRightPageData = repairStandardRightPageData
    },
    GETREPAIRSTANDARDRIGHTCHILD (state, repairStandardRightChild) {
        state.repairStandardRightChild = repairStandardRightChild
    },
    POSTREPAIRSTANDARDLISTADD (state, ifRepairStandardListAdd) {
        state.ifRepairStandardListAdd = ifRepairStandardListAdd
    },
    DELETEREPAIRSTANDARDLEFTPAGEDATA (state, ifdeleteRepairStandardLeftPageDataSuccess) {
        state.ifdeleteRepairStandardLeftPageDataSuccess = ifdeleteRepairStandardLeftPageDataSuccess
    },
    POSTREPAIRSTANDARDDETAILADD (state, ifRepairStandardDetailAddSuccess) {
        state.ifRepairStandardDetailAddSuccess = ifRepairStandardDetailAddSuccess
    },
    POSTREPAIRSTANDARDDETAILEDIT (state, ifRepairStandardDetailEditSuccess) {
        state.ifRepairStandardDetailEditSuccess = ifRepairStandardDetailEditSuccess
    },
    DELETEREPAIRSTANDARDRIGHTPAGEDATA (state, ifdeleteRepairStandardRightPageDataSuccess) {
        state.ifdeleteRepairStandardRightPageDataSuccess = ifdeleteRepairStandardRightPageDataSuccess
    },
    GETREPAIRPATROLTASKPAGEDATAMINE (state, repairPatrolTaskPageDataMine) {
        state.repairPatrolTaskPageDataMine = repairPatrolTaskPageDataMine
    },
    GETORDERMANAGEMENTTABCOUNTMINE (state, orderManagementTabCountMine) {
        state.orderManagementTabCountMine = orderManagementTabCountMine
    },
    GETREPAIRORDERMANAGEMENTPAGEDATAMINE (state, repairOrderManagementPageDataMine) {
        state.repairOrderManagementPageDataMine = repairOrderManagementPageDataMine
    },
    PUTPATROLTASKCHANGE (state, ifPatrolTaskChangeSuccess) {
        state.ifPatrolTaskChangeSuccess = ifPatrolTaskChangeSuccess
    },
    GETSTATISTICSOVERVIEW (state, statisticsData) {
        state.statisticsData = statisticsData
    },
    GETSTATISTICSENGINEER (state, engineerData) {
        state.engineerData = engineerData
    },
    GETSTATISTICSPERFORMANCES (state, performanceSData) {
        state.performanceSData = performanceSData
    },
    GETSTATISTICSRESTIME (state, restimeData) {
        state.restimeData = restimeData
    },
    GETSTATISTICSRESTIMETABLE (state, restimeTableData) {
        state.restimeTableData = restimeTableData
    },
    GETGENERALSETTINGTABLE (state, generalSettingTableData) {
        state.generalSettingTableData = generalSettingTableData
    }

}
const actions = {

    // 巡检计划页面#################################################################################################################

    // 发送请求获取分页数据（巡检计划）
    async getRepairPatrolPlanPageData ({ commit }, val) {
        const patrolPlanPageData = await request.get(`/insplan/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&address=${val.address}&executorName=${val.executorName}&intervalType=${val.intervalType}&status=${val.status}`)
        commit('GETREPAIRPARTROLPLANPAGEDATA', patrolPlanPageData)
    },

    // 获取巡检组列表（巡检计划）
    async getRepairPatrolPlanGroupAll ({ commit }) {
        const patrolPlanGroupAll = await request.get(`/inspection-team/getAll`)
        commit('GETREPAIRPATROLGROUPALL', patrolPlanGroupAll)
    },
    // 获取巡检人列表（巡检计划）
    async getRepairPatrolPlanPersonAll ({ commit }) {
        const patrolPlanPersonAll = await request.get(`/inspection-team-detail/getAll`)
        commit('GETREPAIRPATROLPLANPERSONALL', patrolPlanPersonAll)
    },
    // 获取作业标准列表（巡检计划）
    async getRepairPatrolPlanStandAll ({ commit }) {
        const patrolPlanStandAll = await request.get(`inspection-standard/getAll`)
        commit('GETREPAIRPATROLPLANSTANDALL', patrolPlanStandAll)
    },
    // 新增巡检计划（巡检计划）
    async postPatrolPlanAdd ({ commit }, val) {
        const ifPatrolPlanAddSuccess = await request.post(`/insplan`, val)
        commit('POSTPATROLPLANADD', ifPatrolPlanAddSuccess)
    },
    // 切换启用状态(巡检计划)
    async getRepairPatrolPlanSwitchStatus ({ commit }, val) {
        const ifSwitchSuccess = await request.get(`insplan/status?id=${val.id}&status=${val.status}`)
        commit('GETREPAIRPATROLPLANSWITCHSTATUS', ifSwitchSuccess)
    },
    // 删除巡检计划（巡检计划）
    async deleteRepairPatrolPlan ({ commit }, val) {
        const ifDeletePatrolPlanSuccess = await request.delete(`/insplan/${val}`)
        commit('DELETEREPAIRPATROLPLAN', ifDeletePatrolPlanSuccess)
    },
    // 根据巡检计划获取巡检任务列表（巡检计划）
    async getRepairPatrolTaskListFromPatrolPlan ({ commit }, val) {
        const repairPatrolTaskListFromPatrolPlan = await request.get(`/instask/InspectionTaskLists/${val.pageNum}/${val.pageSize}?planId=${val.planId}&keyword=${val.keyword}`)
        commit('GETREPAIRPATROLTASKLISTFROMPATROLPLAN', repairPatrolTaskListFromPatrolPlan)
    },
    // 根据巡检任务获取巡检结果
    async getRepairPatrolTaskResult ({ commit }, val) {
        const repairPatrolTaskResult = await request.get(`/instask/InspectionTaskResult?taskId=${val}`)
        commit('GETREPAIRPATROLTASKRESULT', repairPatrolTaskResult)
    },

    // 巡检任务页面##########################################################################################################################
    // 获取巡检任务分页数据（巡检任务）
    async getRepairPatrolTaskPageData ({ commit }, val) {
        const repairPatrolTaskPageData = await request.get(`/instask/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&status=${val.status}&address=${val.address}&executorName=${val.executorName}&exception=${val.exception}&intervalType=${val.intervalType}`)
        commit('GETREPAIRPATROLTASKPAGEDATA', repairPatrolTaskPageData)
    },
    // 获取巡检任务分页数据（我的巡检）
    async getRepairPatrolTaskPageDataMine ({ commit }, val) {
        const repairPatrolTaskPageDataMine = await request.get(`/instask/planQuery/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&status=${val.status}&address=${val.address}&exception=${val.exception}&intervalType=${val.intervalType}`)
        commit('GETREPAIRPATROLTASKPAGEDATAMINE', repairPatrolTaskPageDataMine)
    },
    // 获取巡检任务的详情信息(巡检任务)
    async getRepairPatrolTaskDetail ({ commit }, val) {
        const patrolTaskDetail = await request.get(`/instask/InspectionTaskDetail?taskId=${val}`)
        commit('GETREPAIRPARTROLTASKDETAIL', patrolTaskDetail)
    },
    // 删除巡检任务（巡检任务）
    async deleteRepairPatrolTask ({ commit }, val) {
        console.log(val)
        const ifDeleteRepairPatrolTaskSuccess = await request.delete(`/instask?id=${val.id}`)
        commit('DELETEREPAIRPATROLTASK', ifDeleteRepairPatrolTaskSuccess)
    },
    // 获取tab栏数量（巡检任务）
    async getRepairPatrolTaskTabCount ({ commit }, val) {
        const repairPatrolTaskTabCount = await request.get(`/instask/tabsCount?keyword=${val.keyword}&address=${val.address}&executorName=${val.executorName}&exception=${val.exception}&intervalType=${val.intervalType}`)
        commit('GETREPAIRPATROLTASKTABCOUNT', repairPatrolTaskTabCount)
    },
    // 获取tab栏数量（巡检任务）
    async getRepairPatrolTaskTabCountMine ({ commit }, val) {
        const repairPatrolTaskTabCountMine = await request.get(`/instask/plan?keyword=${val.keyword}&status=${val.status}&address=${val.address}&exception=${val.exception}&intervalType=${val.intervalType}`)
        commit('GETREPAIRPATROLTASKTABCOUNTMINE', repairPatrolTaskTabCountMine)
    },
    // 编辑巡检任务(巡检任务)
    async putPatrolTaskChange ({ commit }, val) {
        const ifPatrolTaskChangeSuccess = await request.put(`/instask/updateTask`, val)
        commit('PUTPATROLTASKCHANGE', ifPatrolTaskChangeSuccess)
    },


    // 巡检设置界面###################################################################################################################
    // 获取分页数据（巡检设置-作业标准）
    async getRepairWorkStandardPageData ({ commit }, val) {
        const repairWorkStandardPageData = await request.get(`/inspection-standard/page/${val.pageNum}/${val.pageSize}`)
        commit('GETREPAIRWORKSTANDARDPAGEDATA', repairWorkStandardPageData)
    },
    // 获取全部的作业标准类型作为下拉（巡检设置-作业标准）
    async getRepairWorkStandardTypeAll ({ commit }) {
        const repairWorkStandardTypeAll = await request.get(`/inspection-standard-type/getAll`)
        commit('GETREPAIRWORKSTANDARDTYPEALL', repairWorkStandardTypeAll)
    },
    // 获取作业标准条目（巡检设置-作业标准-穿梭框左侧）
    async getRepairStandardLeftPageData ({ commit }, val) {
        const repairStandardLeftPageData = await request.get(`/inspection-standard-item/page/${val.pageNum}/${val.pageSize}?id=${val.id}`)
        commit('GETREPAIRSTANDARDLEFTPAGEDATA', repairStandardLeftPageData)
    },
    // 获取作业标准明细（巡检设置-作业标准-穿梭框右侧）
    async getRepairStandardRightPageData ({ commit }, val) {
        const repairStandardRightPageData = await request.get(`/inspection-standard-item-detail/page/${val.pageNum}/${val.pageSize}?id=${val.id}`)
        commit('GETREPAIRSTANDARDRIGHTPAGEDATA', repairStandardRightPageData)
    },
    // 获取作业标准明细子节点（巡检设置-作业标准-穿梭框右侧）
    async getRepairStandardRightChild ({ commit }, val) {
        const repairStandardRightChild = await request.get(`/inspection-standard-item-detail/child/${val.id}`)
        commit('GETREPAIRSTANDARDRIGHTCHILD', repairStandardRightChild)
    },
    // 新增作业标准条目（巡检设置-作业标准-穿梭框左侧）
    async postRepairStandardListAdd ({ commit }, val) {
        const ifRepairStandardListAdd = await request.post(`/inspection-standard-item`, val)
        commit('POSTREPAIRSTANDARDLISTADD', ifRepairStandardListAdd)
    },
    // 删除作业标准条目（巡检设置-作业标准-穿梭框左侧）
    async deleteRepairStandardLeftPageData ({ commit }, val) {
        const ifdeleteRepairStandardLeftPageDataSuccess = await request.delete(`/inspection-standard-item?id=${val}`)
        commit('DELETEREPAIRSTANDARDLEFTPAGEDATA', ifdeleteRepairStandardLeftPageDataSuccess)
    },
    // 新增作业明细（巡检设置-作业标准-穿梭框右侧）
    async postRepairStandardDetailAdd ({ commit }, val) {
        const ifRepairStandardDetailAddSuccess = await request.post(`/inspection-standard-item-detail`, val)
        commit('POSTREPAIRSTANDARDDETAILADD', ifRepairStandardDetailAddSuccess)
    },
    // 修改作业明细（巡检设置-作业标准-穿梭框右侧）
    async postRepairStandardDetailEdit ({ commit }, val) {
        const ifRepairStandardDetailEditSuccess = await request.post(`/inspection-standard-item-detail/edit`, val)
        commit('POSTREPAIRSTANDARDDETAILEDIT', ifRepairStandardDetailEditSuccess)
    },
    // 删除作业标准明细（巡检设置-作业标准-穿梭框右侧）
    async deleteRepairStandardRightPageData ({ commit }, val) {
        const ifdeleteRepairStandardRightPageDataSuccess = await request.delete(`/inspection-standard-item-detail?id=${val}`)
        commit('DELETEREPAIRSTANDARDRIGHTPAGEDATA', ifdeleteRepairStandardRightPageDataSuccess)
    },
    // 新增作业标准（巡检设置-作业标准设置）
    async postRepairWorkStandardAdd ({ commit }, val) {
        const ifAaddPageDataSuccess = await request.post(`/inspection-standard`, val)
        commit('POSTREPAIRWORKSTANDARDADD', ifAaddPageDataSuccess)
    },
    // 编辑作业标准（巡检设置-作业标准设置）
    async putRepairWorkStandard ({ commit }, val) {
        const ifEditWorkStandardSuccess = await request.put(`/inspection-standard`, val)
        commit('PUTREPAIRWORKSTANDARD', ifEditWorkStandardSuccess)
    },
    // 删除作业标准（巡检设置-作业标准设置）
    async deleteRepairWorkStandard ({ commit }, val) {
        const ifDeleteWorkStandardSuccess = await request.delete(`/inspection-standard?id=${val}`)
        commit('DELETEREPAIRWORKSTANDARDYO', ifDeleteWorkStandardSuccess)
    },
    // 获取分页数据（巡检设置-作业标准类型设置）
    async getRepairWorkStandardTypePageData ({ commit }, val) {
        const repairWorkStandardTypePageData = await request.get(`/inspection-standard-type/page/${val.pageNum}/${val.pageSize}`)
        commit('GETREPAIRWORKSTANDARDTYPEPAGEDATA', repairWorkStandardTypePageData)
    },
    // 新增作业标准类型（巡检设置-作业标准类型设置）
    async postRepairWorkStandardTypeAdd ({ commit }, val) {
        const ifAaddPageDataTypeSuccess = await request.post(`/inspection-standard-type`, val)
        commit('POSTREPAIRWORKSTANDARDTYPEADD', ifAaddPageDataTypeSuccess)
    },
    // 编辑作业标准类型（巡检设置-作业标准类型设置）
    async putRepairWorkStandardTypeAdd ({ commit }, val) {
        const ifEditPageDataTypeSuccess = await request.put(`/inspection-standard-type`, val)
        commit('PUTREPAIRWORKSTANDARDTYPEADD', ifEditPageDataTypeSuccess)
    },
    // 删除作业标准类型（巡检设置-作业标准类型设置）
    async deleteRepairWorkStandardType ({ commit }, val) {
        const ifDeletePageDataTypeSuccess = await request.delete(`/inspection-standard-type?id=${val}`)
        commit('DELETEREPAIRWORKSTANDARDTYPEADD', ifDeletePageDataTypeSuccess)
    },
    // 获取分页数据（巡检设置-巡检小组管理-穿梭框左侧数据）
    async getRepairPatrolGroupSettingLeftPageData ({ commit }, val) {
        const repairPatrolGroupSettingLeftPageData = await request.get(`inspection-team/page/${val.pageNum}/${val.pageSize}`)
        commit('GETREPAIRPATROLGROUPSETTINGLEFTPAGEDATA', repairPatrolGroupSettingLeftPageData)
    },
    // 获取分页数据（巡检设置-巡检小组管理-穿梭框右侧数据）
    async getRepairPatrolGroupSettingRightPageData ({ commit }, val) {
        const repairPatrolGroupSettingRightPageData = await request.get(`inspection-team-detail/page/${val.pageNum}/${val.pageSize}?id=${val.id}`)
        commit('GETREPAIRPATROLGROUPSETTINGRIGHTPAGEDATA', repairPatrolGroupSettingRightPageData)
    },
    // 新增小组（巡检设置-巡检小组设置-穿梭框左侧）
    async postRepairPatrolGroupSettingLeftAddGroup ({ commit }, val) {
        const ifAddGroupSuccess = await request.post(`/inspection-team`, val)
        commit('POSTREPAIRPATROLGROUPSETTINGLEFTADDGROUP', ifAddGroupSuccess)
    },
    // 编辑小组（巡检设置-巡检小组设置-穿梭框左侧）
    async putRepairPatrolGroupSettingLeftAddGroup ({ commit }, val) {
        const ifEditGroupSuccess = await request.put(`/inspection-team`, val)
        commit('PUTREPAIRPATROLGROUPSETTINGLEFTADDGROUP', ifEditGroupSuccess)
    },
    // 删除小组（巡检设置-巡检小组设置-穿梭框左侧）
    async deleteRepairPatrolGroupSettingLeftDeleteGroup ({ commit }, val) {
        const ifDeleteGroupSuccess = await request.delete(`/inspection-team/${val}`)
        commit('DELETEREPAIRPATROLGROUPSETTINGLEFTDELETEGROUP', ifDeleteGroupSuccess)
    },
    // 编辑巡检人是否为组长（巡检设置-巡检小组管理-穿梭框右侧数据）
    async putRepairPatrolGroupSettingRight ({ commit }, val) {
        const putRepairPatrolGroupSettingRightSuccess = await request.put(`/inspection-team-detail`, val)
        commit('PUTREPAIRPATROLGROUPSETTINGRIGHT', putRepairPatrolGroupSettingRightSuccess)
    },
    // 添加成员到小组（巡检设置-巡检小组管理-穿梭框右侧数据）
    async postRepairPatrolSettingAddMemberToGroup ({ commit }, val) {
        const ifAddMemberToGroupSuccess = await request.post(`/inspection-team-detail/insert`, val)
        commit('POSTREPAIRPATROLSETTINGADDMEMBERTOGROUP', ifAddMemberToGroupSuccess)
    },
    // 将成员移出小组（巡检设置-巡检小组管理-穿梭框右侧数据）
    async deleteRepairPatrolSettingMemberOut ({ commit }, val) {
        const ifDeleteMemberToGroupSuccess = await request.delete(`/inspection-team-detail?teamDetailId=${val}`)
        commit('DELETEREPAIRPATROLSETTINGADDMEMBEROUT', ifDeleteMemberToGroupSuccess)
    },
    // 获取生成巡检任务时间（巡检设置-巡检任务分发时间设置）
    async getRepairPatrolSettingDict ({ commit }, val) {
        const repairPatrolSettingDict = await request.get(`/generalsetting/${val}`)
        commit('GETREPAIRPATROLSETTINGDICT', repairPatrolSettingDict)
    },
    // 修改生成巡检任务时间（巡检设置-巡检任务分发时间设置）


    async putRepairPatrolSettingDict ({ commit }, val) {
        // const myId = await request.get(`/generalsetting/SYSTEM_CONFIG_PLAN_TIME`)
        const ifRepairPatrolSettingDictSuccess = await request.put(`/generalsetting/${val.id}`, { value: val.dictValue })
        commit('PUTREPAIRPATROLSETTINGDICT', ifRepairPatrolSettingDictSuccess)


    },
    // 获取巡检设置时间列表
    async getGeneralSettingTable ({ commit }) {
        const generalSettingTableData = await request.get(`/generalsetting/insplan`)
        commit('GETGENERALSETTINGTABLE', generalSettingTableData)
    },



    // 工单管理页面###################################################################################################################
    // 获取工单管理的tab统计数（工单管理）
    async getOrderManagementTabCount ({ commit }, val) {
        const orderManagementTabCount = await request.get(`so/tabsCount?keyword=${val.keyword}&orderNo=${val.orderNo}&source=${val.source}&handler=${val.handler}&processingType=${val.processingType}`)
        commit('GETORDERMANAGEMENTTABCOUNT', orderManagementTabCount)
    },
    // 获取工单管理的tab统计数（我的工单）
    async getOrderManagementTabCountMine ({ commit }, val) {
        const orderManagementTabCountMine = await request.get(`/so/so?keyword=${val.keyword}&orderNo=${val.orderNo}&source=${val.source}&handler=${val.handler}&processingType=${val.processingType}`)
        commit('GETORDERMANAGEMENTTABCOUNTMINE', orderManagementTabCountMine)
    },
    // 获取工单管理的分页数据（工单管理）
    async getRepairOrderManagementPageData ({ commit }, val) {
        const repairOrderManagementPageData = await request.get(`so/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&status=${val.status}&orderNo=${val.orderNo}&handler=${val.handler}&source=${val.source}&processingType=${val.processingType}`)
        commit('GETREPAIRORDERMANAGEMENTPAGEDATA', repairOrderManagementPageData)
    },
    // 获取工单管理的分页数据（我的工单）
    async getRepairOrderManagementPageDataMine ({ commit }, val) {
        const repairOrderManagementPageDataMine = await request.get(`/so/soQuery/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&status=${val.status}&orderNo=${val.orderNo}&handler=${val.handler}&source=${val.source}&processingType=${val.processingType}`)
        commit('GETREPAIRORDERMANAGEMENTPAGEDATAMINE', repairOrderManagementPageDataMine)
    },
    // 新增工单（工单管理）
    async postRepairOrderManagementAddOrder ({ commit }, val) {
        const ifpostRepairOrderManagementAddOrderSuccess = await request.post(`/so`, val)
        commit('POSTREPAIRORDERMANAGEMENTADDORDER', ifpostRepairOrderManagementAddOrderSuccess)
    },
    // 工单的指派和重派（工单管理）
    async getRepairAssign ({ commit }, val) {
        const ifGetRepairAssignSuccess = await request.get(`/so/depend?executorId=${val.executorId}&soId=${val.soId}&type=${val.type}`)
        commit('GETREPAIRASSIGN', ifGetRepairAssignSuccess)
    },
    // 删除工单（工单管理）
    async deleteRepairOrderManagementOrder ({ commit }, val) {
        const ifDeleteRepairOrderManagementOrderSuccess = await request.delete(`/so/${val}`)
        commit('DELETEREPAIRORDERMANAGEMENTORDER', ifDeleteRepairOrderManagementOrderSuccess)
    },
    // 编辑工单（工单管理）
    async putRepairOrderManagementEditOrder ({ commit }, val) {
        const ifEditRepairOrderManagementOrderSuccess = await request.put(`/so/updateTask`, val)
        commit('PUTREPAIRORDERMANAGEMENTEDITORDER', ifEditRepairOrderManagementOrderSuccess)
    },

    // 工单设置页面#################################################################################################################
    // 获取指派人的分页数据(工单设置)
    async getRepairOrderSettingAssignPageData ({ commit }, val) {
        const repairOrderSettingAssignPageData = await request.get(`/so/designated-person/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}`)
        commit('GETREPAIRORDERSETTINGASSIGNPAGEDATA', repairOrderSettingAssignPageData)
    },
    // 新增指派人（工单设置）
    async postRepairOrderSettingAssignPerson ({ commit }, val) {
        const ifAddRepairOrderSettingAssignPersonSuccess = await request.post(`/so/designated-person`, val)
        commit('POSTREPAIRORDERSETTINGASSIGNPERSON', ifAddRepairOrderSettingAssignPersonSuccess)
    },
    // 移除指派人（工单设置）
    async deleteRepairOrderSettingAssignPerson ({ commit }, val) {
        const ifDeleteRepairOrderSettingAssignPersonSuccess = await request.delete(`/so/designated-person/${val}`)
        commit('DELETEREPAIRORDERSETTINGASSIGNPERSON', ifDeleteRepairOrderSettingAssignPersonSuccess)
    },


    // 运维统计页面###########################################################################################################
    // 统计概况（运维统计）
    async getStatisticsOverview ({ commit }, val) {
        const statisticsData = await request.get(`/statistics/overview?startTime=${val.startTime}&endTime=${val.endTime}&type=${val.type}`)
        commit('GETSTATISTICSOVERVIEW', statisticsData)
    },
    // 工程师统计（运维统计）
    async getStatisticsEngineer ({ commit }, val) {
        const engineerData = await request.get(`/statistics/engineer?startTime=${val.startTime}&endTime=${val.endTime}`)
        commit('GETSTATISTICSENGINEER', engineerData)
    },
    // 绩效统计（运维统计）
    async getStatisticsPerformanceS ({ commit }, val) {
        const performanceSData = await request.get(`/statistics/performance?startTime=${val.startTime}&endTime=${val.endTime}`)
        commit('GETSTATISTICSPERFORMANCES', performanceSData)
    },
    // 响应时长统计（运维统计）
    async getStatisticsRestime ({ commit }, val) {
        const restimeData = await request.get(`/statistics/resTime?startTime=${val.startTime}&endTime=${val.endTime}`)
        commit('GETSTATISTICSRESTIME', restimeData)
    },
    // 响应时长统计表格（运维统计）
    async getStatisticsRestimeTable ({ commit }, val) {
        const restimeTableData = await request.get(`/statistics/resTimePage?startTime=${val.startTime}&endTime=${val.endTime}&pageNum=${val.pageNum}&pageSize=${val.pageSize}`)
        commit('GETSTATISTICSRESTIMETABLE', restimeTableData)
    },
    // 统计概况（运维统计）导出
    async exportStatisticsOverview ({ commit }, val) {

        commit('EXPORTSTATISTICSOVERVIEW', 1)
    },


    // 所有的导出请求
    async getExportFileRepair ({ commit }, val) {
        if (val.myPage == 'PatrolPlan') {
            window.location.href = config.baseURL + `insplan/export?address=${val.address}&executorName=${val.executorName}&intervalType=${val.intervalType}&keyword=${val.keyword}&status=${val.status}`
        } else if (val.myPage == 'PatrolTask') {
            window.location.href = config.baseURL + `instask/export?address=${val.address}&executorName=${val.executorName}&intervalType=${val.intervalType}&keyword=${val.keyword}&status=${val.status}`
        } else if (val.myPage == 'OrderManagement') {
            window.location.href = config.baseURL + `so/export?handler=${val.handler}&keyword=${val.keyword}&orderNo=${val.orderNo}&processingType=${val.processingType}&source=${val.source}&status=${val.status}`
        } else if (val.myPage == '工单概况') {
            window.location.href = config.baseURL + `/statistics/overview/order/export?startTime=${val.startTime}&endTime=${val.endTime}&type=${val.type}`
        } else if (val.myPage == '巡检概况') {
            window.location.href = config.baseURL + `/statistics/overview/inspection/export?startTime=${val.startTime}&endTime=${val.endTime}&type=${val.type}`
        } else if (val.myPage == '工程师工单统计') {
            window.location.href = config.baseURL + `/statistics/engineer/order/export?startTime=${val.startTime}&endTime=${val.endTime}`
        } else if (val.myPage == '工程师巡检统计') {
            window.location.href = config.baseURL + `/statistics/engineer/inspection/export?startTime=${val.startTime}&endTime=${val.endTime}`
        } else if (val.myPage == '绩效统计') {
            window.location.href = config.baseURL + `statistics/performance/export?startTime=${val.startTime}&endTime=${val.endTime}`
        }

        commit('GETEXPORTFILECUSTOMER', 1)
    },

}
const getters = {

}


export default ({
    state,
    mutations,
    actions,
    getters
})