import request from "../../utils/request.js";
import config from "../../config/index.js";

export default {
  namepaced: true,
  state: {
    allFromInfo: {},
    allUserInfo: [],
    inverterList: [], //GET根据客户id获取逆变器选择框
    allGeneratorPage: [],
    ediTgenerator: {}, //编辑设备
    allDeleteList: {},
    alldownlist: {}, // 根据id获取单个发电设备信息
    downInfo: {},
  },
  mutations: {
    // 登记信息传from表单
    GENERATOR(state, val) {
      state.allFromInfo = val;
    },
    // 根据用户id获取电站列表
    POWERSTATION(state, val) {
      state.allUserInfo = val;
    },
    // 根据客户id获取逆变器选择框 对接完
    NIBIANQI(state, val) {
      state.inverterList = val;
    },
    // 电站列表
    generatorPage(state, val) {
      state.allGeneratorPage = val;
    },
    // 编辑发电设备
    EDITGENERATOR(state, val) {
      state.ediTgenerator = val;
    },
    // 删除
    DELETE(state, val) {
      state.allDeleteList = val;
    },
    // 下属储能
    // downkaiyuanGenerator
    DOWMKAIYUANGENERTOR(state, val) {
      state.alldownlist = val;
    },
    // 删除
    DOWNDELETE(state, val) {
      state.downInfo = val;
    },
  },
  actions: {
    // 登记信息传from表单
    async generator({ commit }, val) {
      // console.log(val)
      const allFromInfo = await request.post("/generator", val);
      commit("GENERATOR", allFromInfo);
    },
    // // 根据用户id获取电站列表
    async powerstation({ commit }, val) {
      const allUserInfo = await request.get(`/powerstation/${val}`);
      commit("POWERSTATION", allUserInfo);
    },
    // 根据客户id获取逆变器选择框 对接完
    async getInverters({ commit }, val) {
      const getInverters = await request.get(`/generator/bystation/${val}`);
      commit("NIBIANQI", getInverters);
    },
    // 电站列表
    async generatorPage({ commit }, val) {
      const list = await request.get(
        `/generator/page/${val.pageNum}/${val.pageSize}?keyword=${val.keyword}&ownerId=${val.ownerId}&type=${val.type}`
      );
      commit("generatorPage", list);
    },
    //编辑发电设备
    async ediTgenerator({ commit }, val) {
      const equipment = await request.put("/generator", val);
      commit("EDITGENERATOR", equipment);
    },
    //删除发电设备
    async delete({ commit }, val) {
      console.log(val);
      const delList = await request.delete(`/generator/${val}`);
      commit("DELETE", delList);
    },
    // 下属储能 /kaiyuan/generator/{id}
    async downkaiyuanGenerator({ commit }, val) {
      const downList = await request.get(`/generator/${val}`);
      commit("DOWMKAIYUANGENERTOR", downList);
    },
    // down删除
    async downDelete({ commit }, val) {
      const downDeleteList = await request.delete(`/generator/${val}`);
      commit("DOWNDELETE", downDeleteList);
    },
  },
  getters: {},
};
