import request from '../../utils/request.js'
import requestNoToken from '../../utils/requestNoToken.js'
import requestLv from "../../utils/requestLv.js"
// import config from "../../config/index.js"
// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中
const state = {
    tokenData: {},
    name: "",
    personalData: null,
    unreadMessageCount: 0,
    unreadMessageGroupCount: {},
    ifChangePasswordSuccess: {},
    iflogout: {},
    ifclose: {},
    messageData: {},
    ifReadSuccess: {},
    menuList: {},
    workBenchData: {},
}
const mutations = {
    POSTLOGIN(state, tokenData) {
        state.tokenData = tokenData
    },
    GETPERSONALDATA(state, personalData) {
        state.personalData = personalData
    },
    GETUNREADMESSAGECOUNT(state, unreadMessageCount) {
        state.unreadMessageCount = unreadMessageCount
    },
    GETUNREADMESSAGEGROUPCOUNT(state, unreadMessageGroupCount) {
        state.unreadMessageGroupCount = unreadMessageGroupCount
    },
    POSTCHANGEPASSWORD(state, ifChangePasswordSuccess) {
        state.ifChangePasswordSuccess = ifChangePasswordSuccess
    },
    POSTLOGOUT(state, iflogout) {
        state.iflogout = iflogout
    },
    CLOSEEVENTSOURCE(state, ifclose) {
        state.ifclose = ifclose
    },
    GETMESSAGEDATA(state, messageData) {
        state.messageData = messageData
    },
    GETIHAVEREADTHEMESSAGE(state, ifReadSuccess) {
        state.ifReadSuccess = ifReadSuccess
    },
    GETPERSONALMENULIST(state, menuList) {
        state.menuList = menuList
    },
    POSTLOGINLV() { },
    GETWORKBENCHDATA(state, workBenchData) {
        state.workBenchData = workBenchData
    }
}

const actions = {
    // 提交登录信息获取token
    async postLogin({ commit }, val) {
        const tokenData = await requestNoToken.post(`/login`, val)
        commit('POSTLOGIN', tokenData)
    },
    // 获取个人详细资料
    async getPersonalData({ commit }) {
        const personalData = await request.get(`/message/info`)
        sessionStorage.setItem('personalData', JSON.stringify(personalData.data))
        commit('GETPERSONALDATA', personalData.data)
    },
    // 获取未读消息数量
    async getUnreadMessageCount({ commit }) {
        const unreadMessageCount = await request.get(`/message/count`)
        commit('GETUNREADMESSAGECOUNT', unreadMessageCount)
    },
    // 获取分组未读消息数量
    async getUnreadMessageGroupCount({ commit }) {
        const unreadMessageGroupCount = await request.get(`/message/type/count?isRead=0`)
        commit('GETUNREADMESSAGEGROUPCOUNT', unreadMessageGroupCount)
    },
    // 修改密码
    async postChangePassword({ commit }, val) {
        const ifChangePasswordSuccess = await request.post(`/sys/user/password`, val)
        commit('POSTCHANGEPASSWORD', ifChangePasswordSuccess)
    },
    // 退出登录
    async postLogOut({ commit }) {
        const iflogout = await request.post(`/sys/logout`)
        commit('POSTLOGOUT', iflogout)
    },
    // 断开长连接
    async closeEventSource({ commit }, val) {
        const ifclose = await request.get(`/sse/close/${val}`)
        commit('CLOSEEVENTSOURCE', ifclose)
    },

    // 分页查询消息
    async getMessageData({ commit }, val) {
        const messageData = await request.get(`/message/page?pageNum=${val.pageNum}&pageSize=${val.pageSize}`)
        commit('GETMESSAGEDATA', messageData)
    },
    // 分页查询消息（已读的消息）
    async getMessageDataRead({ commit }, val) {
        const messageData = await request.get(`/message/page?pageNum=${val.pageNum}&pageSize=${val.pageSize}&isRead=${val.isRead}&msgType=${val.msgType}`)
        commit('GETMESSAGEDATA', messageData)
    },

    // 读消息
    async getIHaveReadTheMessage({ commit }, val) {
        const ifReadSuccess = await request.get(`/message/flag?id=${val}`)
        commit('GETIHAVEREADTHEMESSAGE', ifReadSuccess)
    },

    // 获取动态菜单列表
    async getPersonalMenuList({ commit }) {
        const menuList = await request.get(`/sys/menu/nav`)
        sessionStorage.setItem('menuList', JSON.stringify(menuList))
        commit('GETPERSONALMENULIST', menuList)
    },
    // 登录驴葱葱
    async postLoginLv({ commit }, val) {
        const params = new URLSearchParams();
        params.append("userName", '18971359290')
        params.append("password", 'WHwsd2022@#')
        params.append("verifyCode", val)
        params.append("rememberMe", true)
        const xxxxxxx = await requestLv.post(`https://www.lvcchong.com/login`, params)
        const wwwwww = await requestLv.post(`https://www.lvcchong.com/CodeVerifyImage`)

        commit('POSTLOGINLV', 1)

    },

    // 获取工作台信息
    async getWorkBenchData({ commit }) {
        const workBenchData = await request.get(`/workDesk`)
        commit('GETWORKBENCHDATA', workBenchData)
    },


}

const getters = {
    getterMenuList: (state) => {
        if (state.menuList.length) {
            return state.menuList.data.menuList
        } else {
            return JSON.parse(sessionStorage.getItem('menuList'))?.data.menuList || []
        }
    },
    getterPersonalData: (state) => {
        if (state.personalData) {
            return state.personalData
        } else {
            return JSON.parse(sessionStorage.getItem('personalData')) || null
        }
    },
}

export default ({
    state,
    mutations,
    actions,
    getters
})