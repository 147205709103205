import Vue from "vue";
import Vuex from "vuex";
import equipment from "./modules/equipment";
import customer from "./modules/customer";
import monitoring from "./modules/monitoring";
import system from "./modules/system";
import storage from "./modules/storage";
import elecManagement from "./modules/elecManagement";
import genManagement from "./modules/genManagement";
import repair from "./modules/repair";
import login from "./modules/login";
import electricity from "./modules/electricity";
import metrAdministration from "./modules/metrAdministration";
import elecCharge from "./modules/elecCharge";
import fullView from "./modules/fullView";
import diagram from './modules/diagram';
import global from "@/global/global.js"; //绘图全局变量

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    diagram,
    equipment,
    customer,
    monitoring,
    system,
    storage,
    elecManagement,
    genManagement,
    repair,
    login,
    electricity,
    metrAdministration,
    elecCharge,
    fullView
  },
  state:{
    global:global
  },
  getters: {
    groupIds: state => state.diagram.groupIds,
  }
});

export default store;
