/**
 * 光谷信息外网访问地址  dist2外网地址
 * baseURL: 'http://113.57.119.233:28080/kaiyuan/'
 * baseURL: 'http://iot.whkyxn.com:8088/kaiyuan/'
 * 盘龙城内网地址  dist3内网地址
 * baseURL:'http://172.16.20.243:8299/panlongcheng/'
 * 盘龙城外网地址  dist3外网地址
 * baseURL: 'http://113.57.119.233:28080/panlongcheng/'
 */

export default {

    // my-dev
    // baseURL: 'http://192.168.22.202:8299/kaiyuan/'
    // baseURL: 'http://localhost:8299/kaiyuan/'

    // 发布生产
    baseURL: '/kaiyuan/'
    // 本地连生产环境
    // baseURL: 'https://www.kyxn.net/kaiyuan/'
    
    // build: {
    //   // Template for index.html
    //   index: path.resolve(__dirname, '../dist/index.html'),

    //   // Paths
    //   assetsRoot: path.resolve(__dirname, '../dist'),
    //   assetsSubDirectory: 'static',//可以把static改为其他名字，其实也没必要
    //   assetsPublicPath: '/',//
    // },

}