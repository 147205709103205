<template>
  <div class="blockOneOutSide">
    <div class="blockOne">
      <img class="logo" :src="logoUrl.url1" alt="" />
      <div class="word">凯源电力数字化运营监测平台</div>
      <div class="rightPlace">
        <img @click="logOut(false)" :src="logoUrl.url6" alt="" class="logo5" />
        <img @click.stop="ifSelectShow = !ifSelectShow" :src="logoUrl.url5" alt="" class="logo4"/>
        <div @click.stop="ifSelectShow = !ifSelectShow" class="name">
          {{ nickName }}，欢迎你！
        </div>
        <img @click="changePhoto" class="photo" :src="myImg" alt="" />
        <div @click="messageShow" class="logo6">
          <el-badge :value="count" :hidden="!count" :max="99" class="item">
            <img class="logo2" :src="logoUrl.url2" alt=""/>
          </el-badge>
          <!-- <img v-show="ifRedPointShow" class="logo3 " :src="logoUrl.url3" alt=""> -->
        </div>
        <!-- 下拉框位置 -->
        <div v-if="ifSelectShow" class="select">
          <div @click="PersonalPage" class="singelOne">个人中心</div>
          <div @click="newPassword" class="singelOne">修改密码</div>
          <div @click="logOut('select')" class="singelOne">退出</div>
        </div>
      </div>
    </div>

    <div class="DrawerForDetail">
      <el-drawer
        :visible.sync="drawer"
        :with-header="false"
        direction="ltr"
        size="26.25%"
      >
        <div class="part1">
          <img @click="changePhoto" class="img1" :src="userInfo.img" alt="" />
          <div class="img2">
            <div class="namePlace">{{ userInfo.name }}</div>
            <div class="jobPlace">{{ userInfo.positionName }}</div>
          </div>
        </div>

        <div class="part2">
          <div class="jobPlace">手机</div>
          <div class="namePlace">{{ userInfo.mobile }}</div>
        </div>

        <div class="part3">
          <div class="jobPlace">部门</div>
          <div class="namePlace">{{ userInfo.deptName }}</div>
        </div>

        <div class="part4">
          <div class="jobPlace">部门电话</div>
          <div class="namePlace">{{ userInfo.deptMobile }}</div>
        </div>
      </el-drawer>
    </div>

    <DialogChangePhoto ref="PhotoRef" />
    <DialogChangePassWord ref="PassWord" />

    <!-- 通知消息抽屉 -->
    <DrawerForMessage ref="MessageRef" />
  </div>
</template>

<script>
import { getCookie, delCookie } from '@/utils/util'
import DialogChangePhoto from './TopPlace/DialogChangePhoto'
import DialogChangePassWord from './TopPlace/DialogChangePassWord'
import DrawerForMessage from './TopPlace/DrawerForMessage'
import config from '@/config/index.js'
import { mapState } from 'vuex'
import { EventSourcePolyfill } from 'event-source-polyfill'
export default {
  name: 'TopPlace',
  components: { DialogChangePhoto, DialogChangePassWord, DrawerForMessage },
  watch:{
    'groupCount.criticalAlertCount'(newVal, oldVal){
      if(oldVal > 0){
        new Audio(require('@/assets/audio/serious.mp3')).play();
      }
    },
    'groupCount.emergencyAlertCount'(newVal, oldVal){
      if(oldVal > 0){
        new Audio(require('@/assets/audio/urgent.mp3')).play();
      }
    }
  },
  computed: {
    ...mapState({
      // messageData: state => { return state.login.messageData.data.records; },
      // messageTotal: state => { return state.login.messageData.data.total; },
      unreadMessageCount: (state) => {
        return state.login.unreadMessageCount.data
      },
      unreadMessageGroupCount: (state) => {
        return state.login.unreadMessageGroupCount.data
      },
    }),
  },
  data() {
    return {
      logoUrl: {
        url1: require('@/assets/menuIcon/logo.png'),
        url2: require('@/assets/menuIcon/bell.png'),
        url3: require('@/assets/menuIcon/bellRed.png'),
        url4: require('@/assets/menuIcon/photo.jpg'),
        url5: require('@/assets/menuIcon/pullAngel.png'),
        url6: require('@/assets/menuIcon/logOut.png'),
      },

      userInfo: {},
      // 林克样式
      linkType: 'link',
      // 是否展示下拉框
      ifSelectShow: false,
      // 用户的昵称
      nickName: '',
      //名片抽屉是否展开
      drawer: false,
      // 用户的职位
      job: '',
      // 手机
      mobile: '',
      // 部门
      deptName: '',
      // 部门电话
      deptMobile: '',
      // 头像
      myImg: '@/assets/menuIcon/photo.jpg',
      // 是否显示红点
      ifRedPointShow: false,
      count: 0,
      groupCount: {},
      sourc: null,
      timer: null
    }
  },
  created() {
    this.$bus.$off('getNickName')
    this.$bus.$off('goToLogin')
  },

  mounted() {
    // 挂载到全局事件总线,渲染昵称
    this.$bus.$on('getNickName', (val) => {
      this.nickName = val
      this.refreshMyData()
      // this.getMenuList()
    })

    this.$bus.$on('goToLogin', () => {
      this.$router.push('/login')
      // location.reload(true)
    })

    //如果强制刷新，也可以获得用户的名字
    if (getCookie('name')) {
      this.myImg = this.$store.getters.getterPersonalData.img
      this.nickName = unescape(getCookie('name'))
      this.refreshMyData()
      // this.getMenuList()
    }

    // 发送请求，如果有消息，就出现红点
    // this.$store.dispatch('getMessageDataRead', { pageNum: 1, pageSize: 7, isRead: '0' }).then(() => {
    //     if (this.messageTotal !== 0) { this.ifRedPointShow = true }
    //     else (this.ifRedPointShow = false)
    // })

    // 发送请求，获取未读消息数量
    if (getCookie('token')) {
      this.getCount()
      this.getGroupCount()
    }

    // 保持vpn的连接不断
    setInterval(() => {
      this.$store.dispatch('getKeepVpn')
    }, 300000)
    if (getCookie('token')) {
      // 监听后台消息数据变化
      const that = this
      if (typeof EventSource !== 'undefined') {
        // const sourc = new EventSource(`${config.baseURL}sse/connect/${getCookie('id')}`) // 后端接口，要配置允许跨域属性
        this.sourc = new EventSourcePolyfill(
          `${config.baseURL}sse/connect/${getCookie('id')}`,
          {
            headers: {
              Authorization: `Bearer ${getCookie('token')}`,
            },
            heartbeatTimeout: 50000,
          },
        )
        this.sourc.addEventListener(
          'open',
          function (e) {
            console.log('source.open', e)
          },
          false,
        )
        this.sourc.addEventListener('message', function (e) {
          // sourc.onmessage = function (e) {
          console.log('source.message', e.data)
          const data = JSON.parse(e.data)
          that.count = data.count
          this.timer = window.setTimeout(() => {
            that.$notify({
              title: data.title,
              // duration: 0, //不会自动关闭
              position: 'bottom-right',
              dangerouslyUseHTMLString: true,
              message: `<div class="notify_content"><i class="el-icon-warning color${data.level}"></i><div class="info">${data.content}</div></div>`,
              onClick: function () {
                let path = ''
                let query = {}
                let menuId = ''
                if (data.type == 0 || data.type == 3) {
                  path = '/repair/orderManagement'
                  menuId = 25
                } else if (data.type == 1 || data.type == 2) {
                  path = '/repair/patrolTask'
                  menuId = 24
                } else if (data.type == 4) {
                  if (data.warningType == 1) {
                    // 配电监控
                    menuId = 9
                    query.id = data.skip
                    const type = data.skip.substr(-1)
                    if (type == 'A') {
                      path = '/sysMap10kv'
                    } else if (type == 'B') {
                      path = '/sysMap400v'
                    } else if (type == 'C') {
                      path = '/sysMapdCscreen'
                    } else if (type == 'D') {
                      path = '/monitoring/transformer'
                    }
                  } else if (data.warningType == 2) {
                    path = '/elecGenerate'
                    menuId = 11
                  } else if (data.warningType == 3) {
                    path = '/elecManagement'
                    menuId = 12
                  } else if (data.warningType == 4) {
                    path = '/fireControl'
                    menuId = 10
                  } else if (data.warningType == 5) {
                    path = '/elecCharge'
                    menuId = 13
                  }
                }
                if (path) {
                  this.sourc.close()
                  that.$bus.$emit('goToTheMenu', menuId)
                  that.routerTo(path, query)
                  that.$notify.close()
                  this.sourc.onopen()
                }
              },
            })
          },0)

          if (!getCookie('id')) {
            // 退出登录
            this.sourc.close()
          }
        })
        this.sourc.addEventListener(
          'error',
          function (e) {
            console.log('sourc.onerror', e)
            // sourc.close()
            // sourc.onopen()
          },
          false,
        )
      } else {
        that.$message.error('当前浏览器不支持服务器发送的事件')
      }
    }
  },

  methods: {
    routerTo(path, query) {
      this.$router.push({
        path,
        query,
      })
    },
    getCount() {
      this.$store.dispatch('getUnreadMessageCount').then(() => {
        this.count = this.unreadMessageCount
      })
    },
    getGroupCount() {
      this.$store.dispatch('getUnreadMessageGroupCount').then(() => {
        this.groupCount = this.unreadMessageGroupCount
      })
    },
    // 展示个人名片页面
    PersonalPage() {
      this.ifSelectShow = false
      this.drawer = true
    },
    // 更换头像
    changePhoto() {
      this.$refs.PhotoRef.show()
    },

    // 刷新个人资料
    refreshMyData() {
      Object.assign(this.userInfo, {...this.$store.getters.getterPersonalData})
    },

    // 退出登录
    logOut(val) {
      if (val === 'select') {
        this.ifSelectShow = false
      }
      this.$confirm('确认退出账号？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('postLogOut').then(() => {
          this.sourc.close()
          this.sourc = null
          this.$store.dispatch('closeEventSource', getCookie('id'))
          for(let k in this.$store.state) {
            if (k != 'login') {
              if (typeof this.$store.state[k] == 'boolean') {
                this.$store.state[k] = false
              } else if (typeof this.$store.state[k] == 'string') {
                this.$store.state[k] = ''
              } else if (typeof this.$store.state[k] == 'number') {
                this.$store.state[k] = 0
              } else if (typeof this.$store.state[k] == 'object') {
                this.$store.state[k] = {}
              }
            }
          }
          this.$store.commit('GETPERSONALDATA', '')
          sessionStorage.clear()
          delCookie('token')
          delCookie('name')
          delCookie('id')
          delCookie('whoAmI')
          this.$router.push('/login')
        })
      })
    },

    // 修改密码
    newPassword() {
      this.ifSelectShow = false
      this.$refs.PassWord.show()
    },

    // 展示消息页面
    messageShow() {
      this.$refs.MessageRef.show()
    },

    // 关闭下拉框
    closePulling() {
      if (this.ifSelectShow === true) {
        this.ifSelectShow = false
      }
    },
  },


}
</script>

<style lang="less" scoped>
// 动画
.link {
  height: 4.08rem;
  width: 5rem;
  animation: move 250s linear;
  -webkit-animation: move 250s linear;
  background: url('../assets/login/link.gif') no-repeat center;
  position: absolute;
  left: -96rem;
  top: 2.3rem;
  cursor: pointer;
  background-size: 5rem 4.08rem;
}

.link2 {
  height: 4.08rem;
  width: 5rem;
  animation: move 5s linear;
  -webkit-animation: move 5s linear;
  background: url('../assets/login/link.gif') no-repeat center;
  background-size: 5rem 4.08rem;
  position: absolute;
  left: -96rem;
  top: 2.3rem;
}

@keyframes move {
  0% {
    transform: translate(0rem, 0rem);
  }
  100% {
    transform: translate(384rem, 0rem);
  }
}
// 下拉框的样式
.select {
  width: 12rem;
  height: 10rem;
  position: absolute;
  top: 4.5rem;
  //  left: 14rem;
  right: 6rem;
  background-color: #0b3c69;
  z-index: 1000;
  .singelOne {
    width: 12rem;
    height: 3.2rem;
    background-color: #0b3c69;
    text-indent: 1.2rem;
    color: #fff;
    font-size: 1.4rem;
    line-height: 3.2rem;
    cursor: pointer;
    &:hover {
      background-color: #1e5587;
    }
  }
}

// 抽屉的样式
.DrawerForDetail {
  position: relative;
  background-color: #0c375d;
  .part1 {
    height: 16.5rem;
    width: 44rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    border-bottom: 0.2rem solid #0e4271;
    position: absolute;
    left: 0;
    top: 0;
    .img1 {
      height: 8rem;
      width: 8rem;
      border-radius: 50%;
      margin-top: 5rem;
      position: absolute;
      left: 0;
      top: 0;
      &:hover {
        opacity: 0.5;
        cursor: pointer;
      }
    }
    .img2 {
      height: 8rem;
      width: 35rem;
      margin-top: 5rem;

      display: inline-block;
      position: absolute;
      left: 10.4rem;
      top: 0;
      .namePlace {
        height: 4rem;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 2.4rem;
        line-height: 4rem;
        color: #fff;
      }
      .jobPlace {
        height: 2.8rem;
        position: absolute;
        top: 4.6rem;
        left: 0;
        font-size: 1.6rem;
        line-height: 4rem;
        color: #fff;
        border-radius: 0.2rem;
        background-color: #064887;
        color: #42bfff;
        line-height: 2.8rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .part2 {
    height: 16.5rem;
    width: 44rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    position: absolute;
    left: 0;
    top: 19rem;

    .namePlace {
      height: 4rem;
      position: absolute;
      top: 4rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      opacity: 0.85;
    }
    .jobPlace {
      height: 2.8rem;
      position: absolute;
      top: 0.6rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      border-radius: 0.2rem;
      background-color: #064887;
      color: #42bfff;
      line-height: 2.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .part3 {
    height: 16.5rem;
    width: 44rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    position: absolute;
    left: 0;
    top: 30rem;

    .namePlace {
      height: 4rem;
      position: absolute;
      top: 4rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      opacity: 0.85;
    }
    .jobPlace {
      height: 2.8rem;
      position: absolute;
      top: 0.6rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      border-radius: 0.2rem;
      background-color: #064887;
      color: #42bfff;
      line-height: 2.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .part4 {
    height: 16.5rem;
    width: 44rem;
    margin-left: 3.2rem;
    margin-right: 3.2rem;
    position: absolute;
    left: 0;
    top: 41rem;

    .namePlace {
      height: 4rem;
      position: absolute;
      top: 4rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      opacity: 0.85;
    }
    .jobPlace {
      height: 2.8rem;
      position: absolute;
      top: 0.6rem;
      left: 0;
      font-size: 1.6rem;
      line-height: 4rem;
      color: #fff;
      border-radius: 0.2rem;
      background-color: #064887;
      color: #42bfff;
      line-height: 2.8rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

//给底色
.blockOneOutSide {
  height: 6.4rem;
  width: 192rem;
  background-color: #0f2437;
}
.blockOne {
  height: 6.4rem;
  width: 192rem;
  background: linear-gradient(
    183deg,
    rgba(15, 36, 55, 0.0001) 0.03%,
    rgba(0, 115, 222, 0.7) 99.9%
  );
  border-bottom: 0.1rem solid rgba(0, 155, 222, 0.4);
  // 边框渐变色适配--低级浏览器会展示不渐变的边框
  border-image: -webkit-linear-gradient(
      rgba(0, 155, 222, 0.2),
      rgba(0, 155, 222, 0.4)
    )
    20 20;
  border-image: -moz-linear-gradient(
      rgba(0, 155, 222, 0.2),
      rgba(0, 155, 222, 0.4)
    )
    20 20;
  border-image: -o-linear-gradient(
      rgba(0, 155, 222, 0.2),
      rgba(0, 155, 222, 0.4)
    )
    20 20;
  border-image: linear-gradient(rgba(0, 155, 222, 0.2), rgba(0, 155, 222, 0.4))
    20 20;
  box-sizing: border-box;
  .logo {
    height: 2.4rem;
    width: 4.3rem;
    position: absolute;
    top: 2rem;
    left: 1.6rem;
  }
  .word {
    font-size: 2rem;
    color: #fff;
    font-family: PingFang SC;
    letter-spacing: 0.084375rem;
    text-shadow: 0 0 0.8rem rgba(255, 255, 255, 0.45);
    line-height: 2.8rem;
    height: 2.8rem;
    position: absolute;
    top: 1.8rem;
    left: 6.7rem;
  }
  .rightPlace {
    height: 6.4rem;
    width: 40rem;
    position: absolute;
    right: 0;
    top: 0;
    padding-right: 3.6rem;
    .logo6 {
      height: 1.375rem;
      width: 1.05rem;
      float: right;
      margin-top: 2.591rem;
      cursor: pointer;
      position: relative;
      margin-right: 1.35rem;
      /deep/.el-badge {
        display: block;
        .el-badge__content {
          height: 14px;
          line-height: 14px;
          padding: 0 4px;
        }
      }

      .logo2 {
        height: 2rem;
        width: 1.5rem;
        position: absolute;
        top: -0.3rem;
        right: 0rem;
        cursor: pointer;
      }
      .logo3 {
        height: 0.7rem;
        width: 0.7rem;
        position: absolute;
        top: -0.2rem;
        right: -0.2rem;
      }
    }

    .photo {
      height: 2.4rem;
      width: 2.4rem;
      float: right;
      margin-top: 2rem;
      margin-right: 0.6rem;
      border-radius: 50%;
      cursor: pointer;
      &:hover {
        opacity: 0.2;
      }
    }
    .name {
      height: 2.2rem;
      float: right;
      margin-top: 2.1rem;
      color: #fff;
      font-size: 1.2rem;
      line-height: 2.2rem;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 0rem;
      cursor: pointer;
    }
    .logo4 {
      height: 1.6rem;
      width: 1.6rem;
      float: right;
      margin-top: 2.4rem;
      margin-right: 1.035rem;
      cursor: pointer;
    }
    .logo5 {
      height: 1.6rem;
      width: 1.6rem;
      float: right;
      margin-top: 2.4rem;
      cursor: pointer;
    }
  }
}
</style>

<style lang="less">
.blockOneOutSide {
  ::-webkit-scrollbar-thumb,
  .el-select-dropdown::-webkit-scrollbar-thumb {
    background-color: #0c375d;
    opacity: 0;
  }
}
.notify_content {
  display: flex;
  align-items: center;
  .el-icon-warning {
    margin-right: 1rem;
    font-size: 2.4rem;
    &.color1 {
      color: #909399;
    }
    &.color2 {
      color: #67c23a;
    }
    &.color1 {
      color: #e6a23c;
    }
    &.color1 {
      color: #f56c6c;
    }
  }
}
</style>
