import { handleAddDiagram, exportDiagram, getDiagramById, updateDiagram, uploadSave } from "@/api/diagram/main";

const state = {
    // diagram对象，包括scale
    diagram: {},
    // 当前画布上的graph数据
    graphJsonObj: {
        cells: [],
    },
    groupIds: [],
};

const actions = {
    async getDiagramById(context, diagramId){
        const res = await getDiagramById(diagramId);
        context.commit('SET_DIAGRAM', res.data);
    },
    setGroupIds({ commit }, groupIds) {
        commit('SET_GROUPIDS', groupIds)
    }
};

const mutations = {
    SET_DIAGRAM(state, diagram){
        state.diagram = diagram;
        state.graphJsonObj = JSON.parse(diagram.diagramJson);
    },
    ADD_COMPONENT_TO_DIAGRAM(state, data){
        state.graphJsonObj.cells.push(data);
    },
    REMOVE_COMPONENT(state, val){
        //todo: 这个val如何识别？
    },
    SET_GROUPIDS: (state, groupIds) => {
        state.groupIds = groupIds
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}