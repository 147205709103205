<template>
    <div @click="handleClick">
        <!-- 顶部栏 -->
        <div v-if="ifRefresh">
            <TopPlace class="topPlace" ref="topRef" />
        </div>

        <div class="flexPlace">
            <!-- 左侧一级菜单 -->
            <div v-if="ifRefresh" class="menuLevelOne">
                <MenuLevelOne />
            </div>

            <!-- 中间路由组件 style="background-color:red" -->
            <div class="routerPlace">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import MenuLevelOne from "./components/MenuLevelOne.vue";
import TopPlace from "./components/TopPlace.vue";
export default {
    components: {
        MenuLevelOne,
        TopPlace,
    },
    provide () {
        return {
            reload: this.reload,
        };
    },

    data () {
        return {
            ifRefresh: true,
        };
    },

    created () {
        this.$bus.$off("refreshTheComponents");
    },
    mounted () {
        this.$bus.$on("refreshTheComponents", () => {
            this.ifRefresh = false;
            setTimeout(() => {
                this.ifRefresh = true;
            }, 20);
        });
    },

    methods: {
        // 点击任意地方关闭上方的下拉框
        handleClick () {
            this.$refs.topRef.closePulling();
        },
    },
};
</script>

<style lang="less">
.topPlace {
    position: relative;
    top: 0;
    left: 0;
    height: 6.4rem;
    width: 192rem;
}
.flexPlace {
    position: absolute;
    .menuLevelOne {
        width: 16rem;
        height: 101.6rem;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
    .routerPlace {
        width: 171.2rem;
        height: 96.8rem;
        position: absolute;
        top: 2.4rem;
        left: 18.4rem;
    }
}
.time-select-item:hover {
    background-color: #86b9ec !important;
}

.add-edit-dialog{
    .el-dialog__header{
        height: 5.4rem;
        line-height: 5.4rem;
        padding-left: 2.4rem !important;
        font-size: 1.6rem;
        background: linear-gradient(225deg, rgba(15, 36, 55, 0.0001) 0.03%, rgba(0, 115, 222, 0.7) 99.9%);
        .el-dialog__title{
            color: white;
        }
    }

    .el-form{
        label{
            color: #b6c2ce;
        }
        input.el-input__inner::placeholder,
        textarea::placeholder{
            color: #4e6a86;
        }
        textarea{
            background-color: #0c375e;
            border: 1px solid #094377;
            color: #ced7df;
            font-family: "Microsoft YaHei";
        }
        .el-input-number__decrease,.el-input-number__increase{
            background-color: #094377;
            border: none;
        }
    }

    .dialog-footer{
        display: flex;
        flex-direction: row-reverse;
        padding-right: 2rem;

        button{
            margin-left: 1rem;
            color: white;
            border: none;
            border-radius: 2px;

            &:first-child{
                background-color: #027969;
            }
            &:not(:first-child){
                background-color: #0b3a64;
                border: 1px solid #094377;
            }
        }

    }
}

</style>
