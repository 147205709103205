import request from '../../utils/request.js'
import config from "../../config/index.js"

// 充电运营模块所有请求数据
const state = {
  orderStatisticsPageList: {},
  chargeStatisticsPageList: {},
  equipmentStatisticsPageList: {},
  chargeMonitoringPageList: {},
  chargeMonitoringPageData: {},
  chargeAllData: {},
  chargeAllChartData: {},
  chargeAllDate: {},
  stationList:{},
  unitList: [],
  customerTree: {},
}

const mutations = {
  ORDERSTATISTICSPAGELIST(state, orderStatisticsPageList){
    state.orderStatisticsPageList = orderStatisticsPageList
  },
  CHARGESTATISTICSPAGELIST(state, chargeStatisticsPageList){
    state.chargeStatisticsPageList = chargeStatisticsPageList
  },
  EQUIPMENTSTATISTICSPAGELIST(state, equipmentStatisticsPageList){
    state.equipmentStatisticsPageList = equipmentStatisticsPageList
  },
  CHARGEMONITORINGPAGELIST(state, chargeMonitoringPageList){
    state.chargeMonitoringPageList = chargeMonitoringPageList
  },
  CHARGEMONITORINGPAGEDATA(state, chargeMonitoringPageData){
    state.chargeMonitoringPageData = chargeMonitoringPageData
  },
  CHARGEALLDATA(state, chargeAllData){
    state.chargeAllData = chargeAllData
  },
  CHARGEALLCHARTDATA(state, chargeAllChartData){
    state.chargeAllChartData = chargeAllChartData
  },
  CHARGEALLDATE(state, chargeAllDate){
    state.chargeAllDate = chargeAllDate
  },
  GETELECCHARGESTATIONLIST(state,stationList){
    state.stationList = stationList
  },
  GETUNITLISTTREE(state,unitList){
    state.unitList = unitList
  },
  GETEXPORTFILECUSTOMER(){},
  GETCUSTOMERTREE(state,customerTree){
    state.customerTree = customerTree
  },
}

const actions = {
  // 订单统计 列表渲染及查询
  async getOrderStatisticsPageList({commit}, val){
    const orderStatisticsPageList = await request.post(`/charging/order/${val.pageNum}/${val.pageSize}?deviceId=${val.deviceId}&orderNum=${val.orderNum}&payChannel=${val.payChannel}&payType=${val.payType}&status=${val.status}`,{chargeToken:val.chargeToken})
    commit('ORDERSTATISTICSPAGELIST', orderStatisticsPageList)
  },
  // 发送请求，获取客户树
  async getBatteryCustomerTree({ commit }) {
    const customerTree = await request.get(`/tree/batteryChargeCustomer`)
    commit('GETCUSTOMERTREE', customerTree)
  },
  // 充电统计 列表渲染及查询
  async getChargeStatisticsPageList({commit}, val){
    const chargeStatisticsPageList = await request.post(`/charging/charge/${val.pageNum}/${val.pageSize}?deviceName=${val.deviceName}`,{chargeToken:val.chargeToken})
    commit('CHARGESTATISTICSPAGELIST', chargeStatisticsPageList)
  },
  // 设备统计 列表渲染及查询
  async getEquipmentStatisticsPageList({commit}, val){
    const equipmentStatisticsPageList = await request.post(`/charging/page/${val.pageNum}/${val.pageSize}?deviceFamily=${val.deviceFamily}&id=${val.id}&online=${val.online}&status=${val.status}`,{chargeToken:val.chargeToken})
    commit('EQUIPMENTSTATISTICSPAGELIST', equipmentStatisticsPageList)
  },
  // 充电监测 列表渲染及查询
  async getChargeMonitoringPageList({commit}, val){
    const chargeMonitoringPageList = await request.post(`/charging/monitorForm/${val.pageNum}/${val.pageSize}?deviceId=${val.deviceId}&online=${val.online}&deviceFamily=${val.deviceFamily}`,{chargeToken:val.chargeToken})
    commit('CHARGEMONITORINGPAGELIST', chargeMonitoringPageList)
  },
  // 充电监测 充电桩卡片渲染
  async getChargeMonitoringPageData({commit},val){
    const chargeMonitoringPageData = await request.post(`/charging/monitor?`,{chargeToken:val.chargeToken})
    commit('CHARGEMONITORINGPAGEDATA', chargeMonitoringPageData)
  },
  // 运营总览 卡片渲染
  async getChargeAllData({commit},val){
    const chargeAllData = await request.post(`/charging/operatingTheOverview`,{chargeToken:val.chargeToken})
    commit('CHARGEALLDATA', chargeAllData)
  },
  // 运营总览收入总览 卡片渲染
  async getChargeAllChartData({commit},val){
    const chargeAllChartData = await request.post(`/charging/operatingChartOverview?beginTime=${val.beginTime}&endTime=${val.endTime}`,{chargeToken:val.chargeToken})
    commit('CHARGEALLCHARTDATA', chargeAllChartData)
  },
  // 运营总览 卡片渲染(折线图时间搜索)
  async getChargeAllDate({commit}, val){
    const chargeAllDate = await request.post(`/charging/operatingTheOverview?beginTime=${val.beginTime}&endTime=${val.endTime}`,{chargeToken:val.chargeToken})
    commit('CHARGEALLDATE', chargeAllDate)
  },
  // 充电监测 获取电站列表
  async getElecChargeStationList({commit}){
    const stationList = await request.get(`/charging/getEstate`)
    commit('GETELECCHARGESTATIONLIST',stationList)
  },
  // 上级单位树
  async getUnitListTree({commit}){
    const unitList = await request.get(`/customer/dropDown`)
    commit('GETUNITLISTTREE',unitList)
  },
  // 导出当前页
  async getExportFileCharge({commit},val){
    if(val.myPage == 'EquipmentStatistics'){
       window.location.href=config.baseURL+`charging/device/export?deviceFamily=${val.deviceFamily}&id=${val.id}&online=${val.online}&status=${val.status}&ids=${val.chargeToken}`
    }else if(val.myPage == 'ChargeStatistics'){
      window.location.href=config.baseURL+`charging/charge/export?deviceName=${val.deviceName}&ids=${val.chargeToken}`
    }else if(val.myPage == 'OrderStatistics'){
      window.location.href=config.baseURL+`charging/order/export?ids=${val.chargeToken}&deviceId=${val.deviceId}&orderNum=${val.orderNum}&payChannel=${val.payChannel}&payType=${val.payType}&status=${val.status}`
    }
    commit('GETEXPORTFILECUSTOMER',1)
  },
}

const getters = {

}

export default({
  state,
  mutations,
  actions,
  getters
})