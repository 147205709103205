<template>
  <div class="DialogChangePassWord">
    <el-dialog 
    @close="closeDialog('ruleForm')"
    :visible.sync="dialogFormVisible" width="20%" :close-on-click-modal="false">
      <div slot="title">
        <div  class="equip_title">修改密码</div>
      </div>
    
      <div style="max-height:45rem;overflow-y:auto;">
        <el-form :model="form" class="elForm" ref="ruleName" :rules="rules">
          <el-form-item label="原密码" prop="oldPassword"  style="width:98%">
            <el-input v-model="form.oldPassword" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="newPassword"  style="width:98%">
            <el-input v-model="form.newPassword" placeholder="请输入" size="small" clearable></el-input>
          </el-form-item>                
        </el-form>
      </div>
      <el-form>
        
       </el-form>
      <div slot="footer" class="dialog-footer">
        <button class="btn_cancle" @click="handleCancle('ruleName')">取 消</button>
        <button class="btn_save" @click="postDialogData('ruleName')">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { tableBg } from '@/mixin/tablebg'
import { validateInput } from "@/utils/validate"
export default {
    name:"DialogChangePassWord",
    mixins: [tableBg],
     data() {
      return {
    
        //表单中placehoder的数据
        parentNamePlaceHoder:'',
      
        // 展示判断
        dialogFormVisible: false,
        // 表单数据存放处
        form:{oldPassword:"",newPassword:""},
        rules: {
          oldPassword: [validateInput],
          newPassword: [validateInput], 
        },
      };
    },
    methods: {      
        // 关闭dialog的回调，el自带的
        closeDialog(ruleForm){
          this.formInit()
          this.$refs[ruleForm].resetFields()
        }, 
        handleCancle() {
        
          this.dialogFormVisible = false
          this.formInit()
        },
         // from的归零
        formInit(){
          this.form ={oldPassword:"",newPassword:""}
        },

        //父组件点击触发新增
        show(){
          this.dialogFormVisible = true
        },
        
     
        // 提交发送请求（点击《确定》）
        postDialogData(ruleName){
          this.$refs[ruleName].validate(valid => {
            if (valid) {
              // 发送登记请求
                this.$store.dispatch('postChangePassword',this.form).then(()=>{
              if(this.ifChangePasswordSuccess===0){
                this.$message({message: '修改成功！',type: 'success'});
                this.$refs[ruleName].resetFields()
                this.dialogFormVisible = false;
                this.$parent.refreshThePage()
              }else if(this.ifChangePasswordSuccess===-1){
                this.$message({message:this.ifChangePasswordError ,type: 'warning'});
                }
              })           
              }else {
              this.$message.error('有必填项未填')
            }
          })

       }
    },

    //处理state数据
    computed:{
    ...mapState({
      ifChangePasswordSuccess:(state)=>{return state.login.ifChangePasswordSuccess.errorCode},
      ifChangePasswordError:(state)=>{return state.login.ifChangePasswordSuccess.message},
    })
  },
}
</script>

<style lang="less" scoped>
.contact_title {
  color: rgba(255, 255, 255, 0.85);
  font-size: 1.4rem;
  width: 100%;
}


// 表格添加一项的按钮
.btn_add {
  border: 0.1rem dashed #2D79BE;
  height: 4.2rem;
  line-height: 4.2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.45);
  width: 100%;
  cursor: pointer;
  margin-bottom: 2rem;
  &:hover {
    border-color: #fff;
  }
}
</style>

<style lang="less">
.DialogChangePassWord{
    .no_line_height {
    .el-form-item__content {
      line-height: unset;
    }
  }
// abb7c2
.el-checkbox__label {
    display: inline-block;
    padding-left: 1rem;
    line-height: 1.9rem;
    font-size: 1.4rem; 
}
.el-checkbox{
 color: #abb7c2;
}
.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #027969;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #027969;
    border-color: #027969;
}
  .el-input.is-disabled .el-input__inner {
    background-color: #0b3d6b;
    border-color: #004579;
    color: #C0C4CC;
    cursor: not-allowed;
  }

}


</style>


