import request from '../../utils/request.js'

// 注意，上传excel的请求写在DialogImportFile这个公共组建的vue文件中


const state ={
    provinceData:{},
    electricalLoadData:{},//用电负载
    electricalStatData:{},//用电统计
    provinceChargeData: {},
    provinceGeneratorData: {},
    provinceStatisticsData: {},
    cityCustomerInfo: {},
    allCityData: {},
    cityData:{},
    cityElecLoadData:{},//用电负载
    cityElecStatData:{},//用电统计
    cityEnergyData: {},
    cityGeneratorData: {},
    cityGeneratorInverterData: {},
    moreCityEnergyData: {},
    kaiyuanCityData:{},
    eastLakeCityData:{},
    schoolCityData:{},
}
const mutations = {
    GETFULLVIEWPANORAMAPROVINCE(state,provinceData){
        state.provinceData = provinceData
    },
    GETFULLVIEWPANORAMAELECTRICALLOAD(state,electricalLoadData){
        state.electricalLoadData = electricalLoadData
    },
    GETFULLVIEWPANORAMAELECTRICALSTAT(state,electricalStatData){
        state.electricalStatData = electricalStatData
    },
    GETFULLVIEWPANORAMAPROVINCECHARGE(state, provinceChargeData) {
        state.provinceChargeData = provinceChargeData
    },
    GETFULLVIEWPANORAMAPROVINCEGENERATOR(state, provinceGeneratorData) {
        state.provinceGeneratorData = provinceGeneratorData
    },
    GETFULLVIEWPANORAMAPROVINCESTATISTICS(state, provinceStatisticsData) {
        state.provinceStatisticsData = provinceStatisticsData
    },
    GETFULLVIEWCITYALL(state,cityData){
        state.cityData = cityData
    },
    GETFULLVIEWCITYALLENERGY(state,cityEnergyData){
        state.cityEnergyData = cityEnergyData
    },
    GETGENERATORVIEWCITYALL(state,cityGeneratorData) {
        state.cityGeneratorData = cityGeneratorData
    },
    GETGENERATORINVERTERVIEWCITYALL(state,cityGeneratorInverterData) {
        state.cityGeneratorInverterData = cityGeneratorInverterData
    },
    GETFULLVIEWCITY(state,val){
        state.cityData = val
        // if(val[1]=='kaiyuan') state.kaiyuanCityData = val[0]
        // else if(val[1]=='eastLake') state.eastLakeCityData = val[0]
        // else if(val[1]=='school') state.schoolCityData = val[0]
    },
    GETFULLVIEWCITYELECLOAD(state,val){
        state.cityElecLoadData = val
    },
    GETFULLVIEWCITYELECSTAT(state,val){
        state.cityElecStatData = val
    },
    GETFULLVIEWCITYENERGY(state,moreCityEnergyData){
        state.moreCityEnergyData = moreCityEnergyData
    },
    GETFULLVIEWPANORAMACITYCUSTOMERINFO(state,cityCustomerInfo) {
        state.cityCustomerInfo = cityCustomerInfo
    },
    GETFULLVIEWPANORAMACITY(state,allCityData) {
        state.allCityData = allCityData
    }
}
const actions = {
  // 湖北省数据
  async getFullViewPanoramaProvince({commit}, val){
      const provinceData = await request.get(`/panorama/province?cityName=${val}`)
      commit('GETFULLVIEWPANORAMAPROVINCE',provinceData)
  },
  // 湖北省数据 用电负荷
  async getFullViewPanoramaElectricalLoad({commit}, val){
      const electricalLoadData = await request.get(`/panorama/province/electricalLoad?cityName=${val}`)
      commit('GETFULLVIEWPANORAMAELECTRICALLOAD',electricalLoadData)
  },
  // 湖北省数据 用电统计
  async getFullViewPanoramaElectricalStat({commit}, val){
      const electricalStatData = await request.get(`/panorama/province/electricalStat?cityName=${val}`)
      commit('GETFULLVIEWPANORAMAELECTRICALSTAT',electricalStatData)
  },
  // 湖北省充电类数据
  async getFullViewPanoramaProvinceCharge({commit}, val){
      const provinceChargeData = await request.get(`/panorama/province/charge?cityName=${val}`)
      commit('GETFULLVIEWPANORAMAPROVINCECHARGE',provinceChargeData)
  },
  // 湖北省发电类数据
  async getFullViewPanoramaProvinceGenerator({commit}, val){
      const provinceGeneratorData = await request.get(`/panorama/province/generator?cityName=${val}`)
      commit('GETFULLVIEWPANORAMAPROVINCEGENERATOR',provinceGeneratorData)
  },

  // 湖北省发电类数据统计
  async getFullViewPanoramaProvinceStatistics({commit}, val){
    const provinceStatisticsData = await request.get(`/panorama/province/inverter/statistics?cityName=${val}`)
    commit('GETFULLVIEWPANORAMAPROVINCESTATISTICS',provinceStatisticsData)
},

  // 获取城市客户单位信息
  async getFullViewPanoramaCityCustomerInfo({commit}){
      const cityCustomerInfo = await request.get(`/panorama/cityCustomerInfo`)
      commit('GETFULLVIEWPANORAMACITYCUSTOMERINFO',cityCustomerInfo)
  },

  // 城市级全景展示
  async getFullViewPanoramaCity({commit}, val){
      const allCityData = await request.get(`/panorama/getProvinceByCity?cityName=${val}`)
    //   commit('GETFULLVIEWPANORAMAALLCITY',allCityData)
      commit('GETFULLVIEWPANORAMACITY',allCityData)
  },

   // 配电和发电类数据
   async getFullViewCityAll({commit},val){
    const cityData = await request.get(`panorama/city/${val}`)
    commit('GETFULLVIEWCITYALL',cityData)
   },
   // 配电和发电类数据 用电负荷
   async getFullViewCityAllElecLoad({commit},val){
    const cityElecLoadData = await request.get(`panorama/city/electricalLoad/${val}`)
    commit('GETFULLVIEWCITYELECLOAD',cityElecLoadData)
   },
   // 配电和发电类数据 用电统计
   async getFullViewCityAllElecStat({commit},val){
    const cityElecStatData = await request.get(`panorama/city/electricalStat/${val}`)
    commit('GETFULLVIEWCITYELECSTAT',cityElecStatData)
   },

   // 配电和发电类能源数据
   async getFullViewCityAllEnergy({commit},val){
    const cityEnergyData = await request.get(`panorama/city/energy/${val}`)
    commit('GETFULLVIEWCITYALLENERGY',cityEnergyData)
   },

   // 配电和发电类数据(发电)
   async getGeneratorViewCityAll({commit},val){
    const cityGeneratorData = await request.get(`panorama/city/fd/${val}`)
    commit('GETGENERATORVIEWCITYALL',cityGeneratorData)
   },

   // 配电和发电类数据(发电)逆变器
   async getGeneratorViewCityAllInverter({commit},val){
    const cityGeneratorInverterData = await request.get(`panorama/city/inverter/${val}`)
    commit('GETGENERATORINVERTERVIEWCITYALL',cityGeneratorInverterData)
   },

   // 配电类数据
   async getFullViewCity({commit},val){
    const moreCityData = await request.get(`panorama/match/${val}`)
    // commit('GETFULLVIEWCITY',[moreCityData,val.name])
    commit('GETFULLVIEWCITY',moreCityData)
   },
   // 配电类数据 用电负荷
   async getFullViewCityElecLoad({commit},val){
    const moreCityElecLoadData = await request.get(`panorama/match/electricalLoad/${val}`)
    commit('GETFULLVIEWCITYELECLOAD',moreCityElecLoadData)
   },
   // 配电类数据 用电统计
   async getFullViewCityElecStat({commit},val){
    const moreCityElecStatData = await request.get(`panorama/match/electricalStat/${val}`)
    commit('GETFULLVIEWCITYELECSTAT',moreCityElecStatData)
   },

   // 配电类能源数据
   async getFullViewCityEnergy({commit},val){
    const moreCityEnergyData = await request.get(`panorama/match/energy/${val}`)
    // commit('GETFULLVIEWCITY',[moreCityData,val.name])
    commit('GETFULLVIEWCITYENERGY',moreCityEnergyData)
   },
}
const getters = {
    
}


export default({
    state,
    mutations,
    actions,
    getters
})