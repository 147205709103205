//当前选中的左侧工具栏参数类型
let CurrentType = '';
//当前选中的左侧工具栏参数类型名称
let CurrentTypeName = '';
//当前选中的左侧工具栏参数标题
let CurrentTitle = '';
//当前选中的左侧工具栏参数颜色
let CurrentStroke = '';
//当前选中的左侧工具栏参数高度
let CurrentHeight = 0;
//当前选中的左侧工具栏参数字号
let CurrentFontSize = 0;
//当前选中的左侧工具栏文字
let CurrentText = '';
//当前选中的左侧工具栏参数宽度
let CurrentStrokeWidth = 0;
//当前选中的左侧工具栏参数宽度
let CurrentStrokeDasharray = 0;
//当前选中的左侧工具栏参数宽度
let CurrentStrokeLinecap = 'square';
//当前选中的左侧工具栏参数角度
let CurrentAngle = 0;
//当前选中的x坐标
let CurrentPositionX = 0;
//当前选中的Y坐标
let CurrentPositionY = 0;

//是否能删除
let enableDelete = false;
//是否能复制
let enableCopy = false;
//是否能预览
let enablePreview = false;
//是否能配置属性
let enableConfig = false;
//是否能对齐
let enableAlign = false;
//是否能等间距
let enableCenter = false;
//是否能改色
let enableColor = false;
//是否能编组
let enableGroup = false;
//是否能取消编组
let enableCancelGroup = false;
//是否能解绑
let enableUnbind = true;

//当前默认元件颜色
let SVG_COLOR = '#000000';
//当前左侧元件路径Map
let SVG_GROUP = new Map([
    ['components', '#000000'],
    ['components_2', '#966100'],
    ['components_3', '#780000'],
    ['components_4', '#FFFF95'],
    ['components_5', '#FF0000'],
    // ['components_6', '#ffffff'],
    ['components_6', '#000000'],
]);


export default {
    SVG_COLOR,
    SVG_GROUP,
    CurrentType,
    CurrentTypeName,
    CurrentTitle,
    CurrentStroke,
    CurrentHeight,
    CurrentFontSize,
    CurrentText,
    CurrentStrokeWidth,
    CurrentStrokeDasharray,
    CurrentStrokeLinecap,
    CurrentAngle,
    CurrentPositionX,
    CurrentPositionY,
    enableDelete,
    enableCopy,
    enablePreview,
    enableConfig,
    enableAlign,
    enableCenter,
    enableColor,
    enableGroup,
    enableCancelGroup,
    enableUnbind
}
