import request from '@/utils/request';

// 查询大屏可视化项目列表
export function pageDiagram(query) {
  return request({
    url: '/diagram/main/page',
    method: 'get',
    params: query
  })
}

export function getDiagramsByScenarioCode (params){
  return request({
    url: '/diagram/main/listByCode',
    method: 'GET',
    params
  })
}

// 查询大屏可视化项目详细
export function getDiagramById(diagramid) {
  return request({
    url: '/diagram/main/' + diagramid,
    method: 'get'
  })
}

// 新增大屏可视化项目
export function addDiagram(data) {
  return request({
    url: '/diagram/main',
    method: 'post',
    data: data
  })
}

// 修改大屏可视化项目
export function updateDiagram(data) {
  return request({
    url: '/diagram/main',
    method: 'put',
    data: data
  })
}

// 解绑
export function unbindDiagram(diagramId) {
  return request({
    url: `/diagram/main/unbind/${diagramId}`,
    method: 'put'
  })
}

// 删除大屏可视化项目
export function delDiagram(diagramid) {
  return request({
    url: '/diagram/main/' + diagramid,
    method: 'delete'
  })
}

// 导出大屏可视化项目
export function exportDiagram(diagramid) {
  return request({
    url: '/diagram/main/export/'+ diagramid,
    method: 'get'
  })
}

export function addTemplate(diagramid) {
  var formData = new FormData();
  formData.set("diagramids",diagramid);
  return request({
    url: '/diagram/main/addTemplate/',
    method: 'post',
    data:formData
  })
}

//左侧元件器导航栏
export function uploadSave(data,diagramid) {
  return request({
    url: '/diagram/main/upload',
    method: 'post',
    data: '{file:"' + data.toDataURL("image/png") + '",diagramid:"'+diagramid+'"}'
  })
}
