import config from "../config/index.js"
import axios from 'axios';
import {
    Message
} from 'element-ui'



const service = axios.create({
    baseURL: config.baseURL,
    timeout: 20000,
    dataType: "jsonp",
    headers: {
        'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // 'authority': 'www.lvcchong.com',
        // 'cookie': 'JSESSIONID=1CDF4DC199E5D890BBAE3A9C1B64D504; jinjian=true; SERVERID=d3c77df08dd964c496008a48a032b66e|1639708966|1639708444',
    }
});






service.interceptors.request.use(
    (config) => {
        return config
    },

);

service.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        // alert('请求超时，请检查网络或联系后台人员！')
        Message({
            message: '请求超时，请检查网络或联系后台人员！',
            type: 'error',
            duration: 5000
        })
        return new Promise(() => { })
    }
)



export default service