// 输入框校验信息
export const validateInput = {
        required: true,
        message: '不能为空',
        trigger: 'blur'
    }
    // 下拉框校验信息
export const validateSelect = {
        required: true,
        message: '不能为空',
        trigger: 'change'
    }
    // 选择日期
export const validateDate = {
    // type: 'date',
    required: true,
    message: '不能为空',
    trigger: 'change'
}

//校验手机号
export const validatePhone ={
    pattern: /^1[3-9]\d{9}$|^(\d{3,4}-)?\d{7,8}$/,
    required: true,
    message:'格式不正确（手机：数字11位  座机：区号-数字7位或8位）',
    trigger:'blur',
}


//校验整数
export const validateInteger ={
    pattern: /^[1-9]\d*$/,
    required: true,
    message:'请输入整数',
    trigger:'blur',
}


//校验数字
export const validateNumber ={
    pattern: /^\d+(\.\d+)?$/,
    required: true,
    message:'请输入数字',
    trigger:'blur',
}